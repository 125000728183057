@import url(../hideHostElements.css);

.LandingPage {
  position: relative;
  background: var(--color-page-subtle, #f2f4f8);
  min-height: calc(100vh - 3.5rem);
  margin-top: 3.5rem;
  overflow: hidden;
}

.LandingPage-header {
  position: fixed;
  top: 0;
}

.LandingPage-layout {
  display: flex;
  background: var(--color-page-subtle, #f2f4f8);
}

.LandingPage-content {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  background: var(--color-page-subtle, #f2f4f8);
  width: 100%;
  height: calc(100vh - 3.5rem);
  overflow-x: hidden;
}

.LandingPage-content--withSidePanel {
  height: calc(100vh - 3.5rem);
  width: calc(100% - 27.125rem);
  overflow-x: hidden;
}

.LandingPage-content--withSidePanel::-webkit-scrollbar {
  width: 0;
}

.LandingPage-content--withSidePanel {
  -ms-overflow-style: none;
}

.LandingPage-content--withSidePanel {
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
}

.LandingPage-titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  padding: 2rem 3rem 0 3rem;
}

.LandingPage-titleContainer--withSidePanel {
  margin-right: 1.5rem;
}

.LandingPage-title {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-heading-1);
}

.LandingPage-description {
  color: var(--color-foreground-neutral-medium, #4a4c59);
  font: var(--typography-body-2);
}

.LandingPage-barPillsAndPage {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem 3rem;
}

.LandingPage-content--withSidePanel .LandingPage-barPillsAndPage {
  padding-right: 1.5rem;
}

.LandingPage-barAndPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LandingPage-searchBarContainer {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  max-width: 50rem;
  padding-right: 1.5rem;
}

.LandingPage-searchBar {
  width: 100%;
  background: var(--color-page-subtle, #f2f4f8);
}

.LandingPage-filterButton {
  display: flex;
  height: 3rem;
  align-items: center;
}
/* Override styles for the landingPage toggle (design requested) */
.LandingPage-filterButton .ButtonSelect--rectangle--isOpen {
  background: var(--color-background-neutral-subtlest, #fff);
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}
.LandingPage-filterButton .ButtonSelect--rectangle--isOpen:hover {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: var(--color-background-secondary-subtle, #f2f4f8);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

/* Pagination */
.LandingPage-paginationCondensed {
  padding-right: 0;
}
.LandingPage-paginationGroup {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding: 0.5rem 3rem 2rem 3rem;
}

.LandingPage-filterPills {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  z-index: var(--z-index-dropdown);
}
.LandingPage-filterPills--isClosed {
  display: none;
}

.LandingPage-listRows {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 3rem 3rem 3rem;
  z-index: var(--z-index-landingpage-listrows);
}
.LandingPage-listRows--withSidePanel {
  padding: 0 0 3rem 3rem;
}

.LandingPage-listRowContainer {
  padding-right: 0;
}

.LandingPage-listRows--withSidePanel .LandingPage-listRowContainer {
  padding-right: 1.5rem;
}

.LandingPage-listRowContainer--selected {
  position: static;
  padding: 0;
  width: calc(100% + 2px);
}

.LandingPage-listRow--selected {
  position: relative;
  border-radius: 0.25rem 0 0 0.25rem;
  border: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
  border-right: transparent;
  box-shadow: none;
  z-index: var(--z-index-landingpage-listrows);
}
.LandingPage-listRowContainer--selected,
.LandingPage-listRow--selected {
  pointer-events: none;
}

.LandingPage-listRowContainer--selected .ListRow-container .ListRow-hover-span {
  position: absolute;
  border-radius: 0.25rem 0 0 0.25rem;
  z-index: var(--z-index-landingpage-listrows);
  box-shadow: var(--box-shadow-pretoken-container-interaction);
}

.LandingPage-sidePanelContainer {
  position: fixed;
  width: 27.1875rem;
  top: 3.5rem;
  right: 0;
  height: calc(100vh - 3.5rem);
  box-shadow: var(--box-shadow-pretoken-container-interaction);
}

.LandingPage-sidePanel {
  position: absolute;
  top: 0;
  right: 0;
  width: 27.1875rem;
}

.LandingPage-listrow-section-title {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-heading-3);
  padding: 0.25rem 0px;
}

.LandingPage-listrow-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 1rem;
}

.LandingPage-listrow-section-title-startLine {
  width: 1rem;
  border-bottom: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
}

.LandingPage-listrow-section-title-endLine {
  flex: 1;
  border-bottom: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
}

.LandingPage-listrow-section-title-label {
  margin: 0 0.25rem;
}

.LandingPage-hideSessions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.InlineMessage .LandingPage-noMatchingSearchRecords {
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  gap: 0.25rem;
  width: 100%;
  border-radius: var(--border-radius-sm, 0.25rem);
  background: var(--color-background-neutral-subtlest, #fff);
}
.LandingPage-noMatchingSearchRecords-title {
  color: var(--color-foreground-neutral-bold, #212328);
}

.LandingPage-alertBannerContainer {
  display: flex;
  position: fixed;
  top: 4.25rem;
  left: 6.55rem;
  right: 3.9rem;
  flex-direction: column;
  gap: 0.5rem;
  z-index: var(--z-index-alertbanners);
}
