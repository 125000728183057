.MpaAccountSelection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
}

.MpaAccountSelection-cts {
  gap: 1.5rem;
}

.MpaAccountSelection-createNewAccount,
.MpaAccountSelection-selectCurrentAccount {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
.Grid.MpaAccountSelection-radioButtonGroup {
  min-width: unset;
  max-width: unset;
}

.Grid.MpaAccountSelection-radioButtonGroup,
.MpaAccountSelection-radioButton .RadioButton {
  width: 100%;
  height: 100%;
}

.MpaAccountSelection-jitDatabaseLabelGroup {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}
.MpaAccountSelection-subheading {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.MpaAccountSelection-jitButton.Button {
  max-width: 10rem;
}

.MpaAccountSelection-jitDatabaseAccountWrapper {
  display: flex;
  width: 100%;
  padding: 1rem;
  border-radius: var(--border-radius-sm, 4px);
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: var(--color-background-neutral-subtlest, #fff);
}

.MpaAccountSelection-jitDatabaseAccountInfo {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.MpaAccountSelection-entitlements {
  color: var(--color-foreground-secondary-bold, #00245b);
}

.MpaAccountSelection .Checkbox-container .Checkbox {
  height: 8.125rem;
}

/* noRecords */
.InlineMessage .MpaAccountSelection-noRecords {
  display: flex;
  padding: 1.25rem;
  align-items: flex-start;
  gap: 0.25rem;
  width: 100%;
  border-radius: var(--border-radius-sm, 0.25rem);
  background: var(--color-background-neutral-subtlest, #fff);
}
.MpaAccountSelection-noRecords-title {
  color: var(--color-foreground-secondary-bold, #00245B);
}
