.welcome-container {
  border-radius: 0px 0px 24px 24px;
  background: #f7f9fc;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  padding: 33px 35px 33px 68px;
  display: flex;
  justify-content: space-between;
  .welcome-tile-fetch-error {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid rgba(204, 28, 16, 1);
    border-radius: 24px;
    padding: 10px;
    background: rgba(253, 245, 244, 1);
    .welcome-tile-alert-icon {
      display: flex;
    }
    .welcome-tile-error-text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
    .welcome-tile-error-description {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
    span.ModalIsland-alertContent-icon {
      min-height: 40px;
      min-width: 40px;
    }
  }
}
.welcome-text {
  color: #212328;
  font-size: 40px;
  font-weight: 500;
  .TooltipComponent-container {
    width: 100%;
    .welcome-message-tooltip-section .TooltipComponent-arrow--top {
      .Icon {
        position: absolute;
      }
    }
  }
  // word-break: break-all;
}
.welcome-message{
  width: 40%;
}
.welcome-message-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.welcome-message-tooltip-section{
  word-break: break-all;
  max-width: 100%;
  top: 0.2px;
}
.welcome-tiles {
  display: flex;
  .welcome-tile {
    // width: 245px;
    height: 192px;
    border-radius: 24px;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    margin-right: 16px;
    padding: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .tile-icon {
      margin-bottom: 16px;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
    }
    .large-device {
      display: block;
    }
    .small-device {
      display: none;
    }
    .tile-title {
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 6px;
    }
    .tile-description {
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  }
  .quick-title-for-mobile,
  .quick-title-for-tablets {
    display: none;
  }
  .welcome-tile-bg-dark {
    background: #111530;
    color: #fff;
    .tile-title {
      color: #fff;
    }
    .tile-description {
      color: #e9e9e9;
    }
  }
  .welcome-tile-bg-white {
    background: #fff;
    color: #000;
    .tile-title {
      color: #000;
    }
    .tile-description {
      color: #3a3a3a;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .welcome-container {
    flex-direction: column;
  }
  .welcome-text {
    margin-bottom: 24px;
  }
  .welcome-tiles {
    margin-left: 0;
    .tile-icon {
      width: 42px;
      height: 44.252px;
      align-items: center;
      margin-bottom: 0;
    }
    .tile-title {
      text-align: center;
    }
    .tile-description {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .welcome-container {
    padding: 35px;
  }
  .welcome-text {
    text-align: left;
  }
}

/* Extra small devices (phones, 600px and down) */

@media screen and (max-width: 600px) {
  .welcome-container {
    padding: 35px 48px;
  }
  .welcome-tiles {
    align-items: flex-start;
    justify-content: center;
    .responsive-section {
      width: 145px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .quick-title-for-tablets {
      display: block;
      color: #000;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      margin-top: 12px;
      padding: 0 14px;
    }
    .welcome-tile {
      height: 120px;
      width: 120px;
      box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.12);
      margin: 0;
      .tile-icon {
        width: 80px;
        height: 80px;
        margin-bottom: 0;
      }
      .tile-title,
      .tile-description {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .welcome-container {
    background: #001a40;
    padding: 30px;
    flex-direction: column;
    position: relative;
    margin-bottom: 150px;
    border-radius: 0;
    span.welcome-tile-error-text,
    span.welcome-tile-error-description {
      color: white;
    }
  }
  .welcome-message {
    padding-left: 30px;
  }
  .welcome-text {
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 78px;
    text-align: left;
  }
  .welcome-tiles {
    margin-left: 0;
    position: absolute;
    top: 116px;
    left: 0;
    right: 0;
    .welcome-tile-bg-dark {
      background: #fff;
    }
    .responsive-section {
      width: 130px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .quick-title-for-tablets {
      display: none;
    }
    .quick-title-for-mobile {
      display: block;
      color: #000;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      margin-top: 12px;
      padding: 0px 10px;
    }
    .welcome-tile {
      height: 120px;
      width: 120px;
      box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.12);
      margin: 0;
      .large-device {
        display: none;
      }
      .small-device {
        display: flex;
      }
      .tile-icon {
        // width: 42px;
        // height: 44.252px;
        margin-bottom: 0;
      }
      .tile-title,
      .tile-description {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .welcome-tiles {
    .responsive-section {
      width: 105px;
    }
    .welcome-tile {
      height: 100px;
      width: 100px;
      .tile-icon {
        width: 70px;
        height: 70px;
      }
    }
  }
}
