.InputField-Container {
  display: flex;
}

.InputField {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
}

.InputField-inputContainer {
  position: relative;
}

.InputField .Label {
  margin-bottom: 4px;
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-heading-4);
}

.InputField-search {
  gap: 0;
}

.InputField-input {
  border: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
  border-radius: 4px;
  color: var(--color-foreground-secondary-bold, #00245b);
  width: 100%;
  text-overflow: ellipsis;
  transition: border 0.2s ease-in-out;
}

.InputField--neutral {
  background: var(--background-neutral-subtlest, #fff);
}
.InputField--secondary {
  background: var(--color-background-secondary-subtlest, #f7f9fc);
}
.InputField-input-suffixButton {
  width: calc(100% - 2.3125rem);
  border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
}
.InputField--small {
  padding: 0.5rem 2.25rem 0.5rem 0.25rem;
  font: var(--typography-body-3);
  height: 2rem;
}
.InputField--large {
  padding: 0.875rem 2.25rem 0.875rem 0.5rem;
  font: var(--typography-body-3);
  height: 3rem;
}
.InputField--multiline {
  max-width: 100%;
  max-height: 100%;
  min-height: 3rem;
  min-width: 7.5rem;
  padding: 0.75rem;
  font: var(--typography-body-2);
  transition: border-color 0.1s ease-in-out;
}
.InputField--multiline::-webkit-scrollbar {
  width: 1rem;
}
.InputField--multiline::-webkit-scrollbar-track {
  background-color: transparent;
}
.InputField--multiline::-webkit-scrollbar-thumb {
  background-color: var(--color-foreground-neutral-disabled);
  border-radius: 200px;
}
.InputField--neutral::-webkit-scrollbar-thumb {
  border: 0.25rem solid var(--background-neutral-subtlest, #fff);
}
.InputField--secondary::-webkit-scrollbar-thumb {
  border: 0.25rem solid var(--color-background-secondary-subtlest);
}
.InputField--multiline:disabled::-webkit-scrollbar-thumb {
  border: 0.25rem solid var(--color-background-neutral-disabled-subtle, #ebecf2);
}
.InputField--multiline:hover::-webkit-scrollbar-thumb {
  background-color: var(--color-foreground-neutral-subtle, #717385);
}
/* For Firefox */
.InputField--multiline {
  scrollbar-width: thin;
}

.InputField-input.InputField-noOutline:focus,
.InputField-input.InputField-noOutline:hover:focus {
  outline: none;
}
.InputField-input:focus,
.InputField-input:hover:focus {
  outline: 2px solid var(--color-border-focus, #6e7fff);
}
.InputField-suffixButton[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
}

.InputField-input:active {
  border: 1px solid var(--color-border-primary-medium, #3751ff);
}
.InputField-input:hover {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}
.InputField-input:disabled {
  border: 1px solid transparent;
  cursor: not-allowed;
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  color: var(--color-foreground-neutral-subtle, #717385);
}
.InputField-input::-webkit-input-placeholder,
.InputField-input:-ms-input-placeholder {
  /* Edge */
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-subtle, #717385);
}
.InputField-input::placeholder {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-subtle, #717385);
}
/* isDisabled */
.InputField-input--isDisabled {
  padding-right: 0.5rem;
}
/* isReadOnly */
.InputField-input--isReadOnly {
  pointer-events: none;
  background: transparent;
  padding-right: 0.5rem;
}
/* InputField Prefix Icon */
.InputField-prefixIcon {
  position: absolute;
  left: 0;
  top: 0;
}
.InputField-input-prefixIcon--small {
  padding-left: 2rem;
}
.InputField-input-prefixIcon--large {
  padding-left: 2.25rem;
}
.InputField-prefixIcon--small span {
  padding: 0.5rem 0 0.5rem 0.5rem;
}
.InputField-prefixIcon--small svg {
  height: 1rem;
  width: 1rem;
}
.InputField-prefixIcon--large span {
  padding: 0.875rem 0 0.875rem 0.5rem;
}
.InputField-prefixIcon--large svg {
  height: 1.25rem;
  width: 1.25rem;
}
/* InputFeild Temp Close Button */
.InputField-closeButtonContainer {
  position: absolute;
  right: 0;
  bottom: 0;
}
.InputField-closeButtonContainer--small {
  padding: 0.25rem 0.5rem 0.25rem 0;
}
.InputField-closeButtonContainer--large {
  padding: 0.75rem 0.5rem 0.75rem 0;
}
.InputField-closeButtonContainer--suffixButton {
  right: 2.3125rem;
}
.InputField-closeButtonContainer--isReadOnly {
  display: none;
}

/* Suffix Button */
.InputField-suffixButton {
  all: unset;
  position: absolute;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  top: 0;
  right: 0;
  padding: 0.75rem 0.5rem;
  border: 1px solid var(--color-border-neutral-decorative, #cecfd9);
  border-left: 1px solid transparent;
  cursor: pointer;
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  background: transparent;
  height: 3rem;
  transition: all 0.1s ease-in-out;
  isolation: isolate;
}
.InputField-suffixButton-icon {
  height: 1.25rem;
  width: 1.25rem;
}
.InputField-suffixButton-icon svg path,
.InputField-suffixButton-icon svg g {
  fill: var(--color-foreground-secondary-medium, #254b86);
}
.InputField-suffixButton--isReadOnly {
  background: #fff;
  border: 1px solid var(--color-border-neutral-decorative, #cecfd9);
}
.InputField-suffixButton:hover {
  border: 1px solid var(--color-border-secondary-subtle, #cecfd9);
  background: var(--background-neutral-subtlest, #fff);
}
.InputField-suffixButton-overlay {
  position: absolute;
  z-index: -1;
  right: -1px;
  top: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border: 1px solid transparent;
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  background: transparent;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}
.InputField-suffixButton-icon {
  transition: all 0.2s ease-in-out;
}
.InputField-suffixButton-overlay:hover,
.InputField-suffixButton:hover .InputField-suffixButton-overlay,
.InputField-suffixButton-icon:hover + .InputField-suffixButton-overlay {
  opacity: 1;
  background: rgba(55, 81, 255, 0.08);
}
.InputField-suffixButton-overlay:active,
.InputField-suffixButton:active .InputField-suffixButton-overlay,
.InputField-suffixButton-icon:active + .InputField-suffixButton-overlay {
  opacity: 1;
  background: rgba(55, 81, 255, 0.1);
}
/* Required Critical */
.InputField-input--isCritical,
.InputField-input--isCritical:focus,
.InputField-input--isCritical:focus:hover,
.InputField-input--isCritical:hover {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}
.InputField--isRequired {
  position: relative;
  top: 5px;
  right: 5px;
}
.InputField--isRequired--isCritical,
.InputField--isRequired--isWarning {
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  font: var(--typography-heading-3);
}
.InputField--isRequired--isWarning,
.InputField-suffixButton--isWarning {
  color: #a36a00;
  left: -8px;
}
.InputField--isRequired--isCritical {
  color: var(--color-foreground-critical-medium);
  left: -8px;
}
/* InputField Suffix Button isRequired */
.InputField-suffixButton--isCritical {
  border: 1px solid var(--color-foreground-critical-medium);
}
.InputField-suffixButton--isCritical:hover {
  border: 1px solid var(--color-foreground-critical-medium);
}
.InputField-suffixButton--isCritical .InputField-suffixButton-icon svg path,
.InputField-suffixButton--isCritical .InputField-suffixButton-icon svg g {
  fill: var(--color-foreground-neutral-subtle, #717385);
}
/* InputField Suffix Button isDisabled */
.InputField-suffixButton:disabled,
.InputField-suffixButton-overlay--isDisabled {
  border: 1px solid transparent;
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  cursor: not-allowed;
}
.InputField-suffixButton-neutral--isDisabled {
  border: 1px solid var(--color-foreground-neutral-subtle);
  border-left: 1px solid transparent;
}
.InputField-suffixButton-secondary--isDisabled {
  border: 1px solid var(--color-foreground-neutral-subtle);
  border-left: 1px solid transparent;
}
.InputField-suffixButton-overlay:hover,
.InputField-suffixButton:disabled:hover .InputField-suffixButton-overlay--isDisabled,
.InputField-suffixButton-icon:hover + .InputField-suffixButton-overlay--isDisabled {
  opacity: 0;
}
.InputField-suffixButton--isDisabled .InputField-suffixButton-icon svg path,
.InputField-suffixButton--isDisabled .InputField-suffixButton-icon svg g {
  fill: var(--color-foreground-neutral-subtle, #717385);
}
/* InputField Multiline Required */
.InputField-multiline--isRequired--isCritical,
.InputField-multiline--isRequired--isWarning {
  position: absolute;
  top: -1.25rem;
  left: -1.25rem;
  font: var(--typography-heading-3);
}
