@import "https://fonts.googleapis.com/css?family=Rubik:400,500";
:root {
  --box-shadow-overlay-left: [object Object];
  --box-shadow-overlay-right: [object Object];
  --box-shadow-overlay-top: [object Object];
  --box-shadow-overlay-bottom: [object Object];
  --box-shadow-overlay-interaction: [object Object];
  --box-shadow-button-interaction: [object Object];
  --box-shadow-container-interaction: [object Object];
  --box-shadow-container-default: [object Object];
  --box-shadow-none: [object Object];
  --text-decoration-underline: underline;
  --text-decoration-none: none;
  --text-case-capitalized: capitalize;
  --text-case-uppercase: uppercase;
  --text-case-none: none;
  --line-height-40: 2.5rem;
  --line-height-32: 2rem;
  --line-height-28: 1.75rem;
  --line-height-24: 1.5rem;
  --line-height-20: 1.25rem;
  --line-height-16: 1rem;
  --line-height-12: .75rem;
  --font-size-56: 3.5rem;
  --font-size-48: 3rem;
  --font-size-32: 2rem;
  --font-size-24: 1.5rem;
  --font-size-20: 1.25rem;
  --font-size-16: 1rem;
  --font-size-14: .875rem;
  --font-size-13: .8125rem;
  --font-size-12: .75rem;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --font-family-default: Rubik;
  --dimension-neg28: -1.75rem;
  --dimension-neg24: -1.5rem;
  --dimension-neg20: -1.25rem;
  --dimension-neg16: -1rem;
  --dimension-neg14: -.875rem;
  --dimension-neg12: -.75rem;
  --dimension-neg8: -.5rem;
  --dimension-neg4: -.25rem;
  --dimension-neg2: -.125rem;
  --dimension-80: 5rem;
  --dimension-72: 4.5rem;
  --dimension-64: 4rem;
  --dimension-56: 3.5rem;
  --dimension-48: 3rem;
  --dimension-40: 2.5rem;
  --dimension-32: 2rem;
  --dimension-28: 1.75rem;
  --dimension-24: 1.5rem;
  --dimension-20: 1.25rem;
  --dimension-16: 1rem;
  --dimension-14: .875rem;
  --dimension-12: .75rem;
  --dimension-8: .5rem;
  --dimension-4: .25rem;
  --dimension-2: .125rem;
  --dimension-0: 0rem;
  --border-width-xlg: .1875rem;
  --border-width-lg: .125rem;
  --border-width-md: .0625rem;
  --border-width-sm: .03125rem;
  --border-radius-circle: 99rem;
  --border-radius-sm: .25rem;
  --border-radius-lg: 1.5rem;
  --opacity-050: 4%;
  --opacity-000: 0%;
  --opacity-1000: 100%;
  --opacity-900: 72%;
  --opacity-600: 48%;
  --opacity-400: 32%;
  --opacity-300: 24%;
  --opacity-150: 12%;
  --opacity-125: 10%;
  --opacity-100: 8%;
  --color-blue-1100: #061527;
  --color-blue-1000: #062441;
  --color-blue-900: #013964;
  --color-blue-800: #00508a;
  --color-blue-700: #006bb5;
  --color-blue-600: #0281d8;
  --color-blue-500: #0294f7;
  --color-blue-400: #66acff;
  --color-blue-300: #9ac2fe;
  --color-blue-200: #c7dbfc;
  --color-blue-100: #edf2fc;
  --color-blue-50: #f4f8ff;
  --color-coral-1100: #2f0700;
  --color-coral-1000: #49060b;
  --color-coral-900: #700116;
  --color-coral-800: #9b0122;
  --color-coral-700: #ca002f;
  --color-coral-600: #ed1a3d;
  --color-coral-500: #ff4451;
  --color-coral-400: #f66;
  --color-coral-300: #ffa7a0;
  --color-coral-200: #fccec9;
  --color-coral-100: #fcefed;
  --color-coral-50: #fff8f7;
  --color-green-1100: #0b1b11;
  --color-green-1000: #0a2817;
  --color-green-900: #003f22;
  --color-green-800: #005932;
  --color-green-700: #007643;
  --color-green-600: #018e53;
  --color-green-500: #01ae66;
  --color-green-400: #00c675;
  --color-green-300: #53e596;
  --color-green-200: #8af7b5;
  --color-green-100: #d5fbe1;
  --color-green-50: #f4fdf7;
  --color-purple-1100: #270036;
  --color-purple-1000: #3c0651;
  --color-purple-900: #5d017d;
  --color-purple-800: #8000ac;
  --color-purple-700: #a800e0;
  --color-purple-600: #c428ff;
  --color-purple-500: #d057ff;
  --color-purple-400: #dd80ff;
  --color-purple-300: #e9a8ff;
  --color-purple-200: #f1cdfc;
  --color-purple-100: #f9eefc;
  --color-purple-50: #f9f5fa;
  --color-orange-1100: #2b1300;
  --color-orange-1000: #401900;
  --color-orange-900: #5f2201;
  --color-orange-800: #872f00;
  --color-orange-700: #b03f00;
  --color-orange-600: #d34d00;
  --color-orange-500: #f25a00;
  --color-orange-400: #ff6f26;
  --color-orange-300: #ffa97f;
  --color-orange-200: #fccfb9;
  --color-orange-100: #fcefe9;
  --color-orange-50: #fdf8f6;
  --color-pink-1100: #290a1b;
  --color-pink-1000: #46062d;
  --color-pink-900: #6b0146;
  --color-pink-800: #940062;
  --color-pink-700: #c20181;
  --color-pink-600: #e8009b;
  --color-pink-500: #fc3da6;
  --color-pink-400: #ff66ba;
  --color-pink-300: #ffa4d2;
  --color-pink-200: #fccbe2;
  --color-pink-100: #fceef4;
  --color-pink-50: #fff7fb;
  --color-teal-1100: #001b1c;
  --color-teal-1000: #002a2c;
  --color-teal-900: #044043;
  --color-teal-800: #005b5f;
  --color-teal-700: #007579;
  --color-teal-600: #008f94;
  --color-teal-500: #00a3a9;
  --color-teal-400: #00c1c7;
  --color-teal-300: #28d8df;
  --color-teal-200: #7bebef;
  --color-teal-100: #cffafb;
  --color-teal-50: #f3feff;
  --color-info-1100: #10003d;
  --color-info-1000: #201368;
  --color-info-900: #332196;
  --color-info-800: #422bbc;
  --color-info-700: #573bdc;
  --color-info-600: #7957e9;
  --color-info-500: #9574f1;
  --color-info-400: #b194f7;
  --color-info-300: #cab4f7;
  --color-info-200: #dfd4f4;
  --color-info-100: #f1ecfd;
  --color-info-50: #f8f5ff;
  --color-warning-1100: #221600;
  --color-warning-1000: #332100;
  --color-warning-900: #5c3c00;
  --color-warning-800: #805300;
  --color-warning-700: #a36a00;
  --color-warning-600: #c17c00;
  --color-warning-500: #e19701;
  --color-warning-400: #f6a91c;
  --color-warning-300: #ffc156;
  --color-warning-200: #ffd988;
  --color-warning-100: #fff1d9;
  --color-warning-50: #fef7eb;
  --color-success-1100: #031b00;
  --color-success-1000: #042805;
  --color-success-900: #033a0f;
  --color-success-800: #005b19;
  --color-success-700: #028329;
  --color-success-600: #2a973c;
  --color-success-500: #43a34d;
  --color-success-400: #6dbd70;
  --color-success-300: #8acd8a;
  --color-success-200: #bee4b6;
  --color-success-100: #e5f7e0;
  --color-success-50: #f3f8f2;
  --color-critical-1100: #390a00;
  --color-critical-1000: #4e0300;
  --color-critical-900: #700600;
  --color-critical-800: #9c0000;
  --color-critical-700: #cc1c10;
  --color-critical-600: #e5362f;
  --color-critical-500: #ef5955;
  --color-critical-400: #f07e7c;
  --color-critical-300: #f6a6a4;
  --color-critical-200: #fed1d0;
  --color-critical-100: #fdeeed;
  --color-critical-50: #fdf5f4;
  --color-navy-1100: #0e1126;
  --color-navy-1000: #001a40;
  --color-navy-900: #00245b;
  --color-navy-800: #033176;
  --color-navy-700: #254b86;
  --color-navy-600: #426498;
  --color-navy-500: #6888b5;
  --color-navy-400: #89a3c7;
  --color-navy-300: #aec1dc;
  --color-navy-200: #cfd9e9;
  --color-navy-100: #f2f4f8;
  --color-navy-50: #f7f9fc;
  --color-indigo-1100: #0e153b;
  --color-indigo-1000: #001c5c;
  --color-indigo-900: #032185;
  --color-indigo-800: #2035cb;
  --color-indigo-700: #3751ff;
  --color-indigo-600: #5e6dff;
  --color-indigo-500: #6e7fff;
  --color-indigo-400: #8694ff;
  --color-indigo-300: #b2bcff;
  --color-indigo-200: #d1d7ff;
  --color-indigo-100: #ebedff;
  --color-indigo-50: #f4f5fe;
  --color-neutral-000: #fff;
  --color-neutral-1100: #0c0d10;
  --color-neutral-1000: #212328;
  --color-neutral-900: #373840;
  --color-neutral-800: #4a4c59;
  --color-neutral-700: #5c5e6e;
  --color-neutral-600: #717385;
  --color-neutral-500: #888b9d;
  --color-neutral-400: #9ea1b1;
  --color-neutral-300: #b5b7c3;
  --color-neutral-200: #cecfd9;
  --color-neutral-100: #ebecf2;
  --color-neutral-50: #f7f8fa;
  --color-gray-000: #fff;
  --color-gray-1100: #020000;
  --color-gray-1000: #1c1b1b;
  --color-gray-900: #363636;
  --color-gray-800: #4d4d4d;
  --color-gray-700: #676666;
  --color-gray-600: #7c7b7b;
  --color-gray-500: #8e8e8e;
  --color-gray-400: #a3a2a2;
  --color-gray-300: #bfbfbf;
  --color-gray-200: #d9d9d9;
  --color-gray-100: #f1f1f1;
  --color-gray-50: #f8f8f8;
  --spacing-neg100: var(--dimension-neg2);
  --spacing-000: var(--dimension-0);
  --spacing-1000: var(--dimension-48);
  --spacing-900: var(--dimension-32);
  --spacing-800: var(--dimension-24);
  --spacing-700: var(--dimension-20);
  --spacing-600: var(--dimension-16);
  --spacing-500: var(--dimension-14);
  --spacing-400: var(--dimension-12);
  --spacing-300: var(--dimension-8);
  --spacing-200: var(--dimension-4);
  --spacing-100: var(--dimension-2);
  --sizing-icon-xsm: var(--dimension-12);
  --sizing-icon-xlg: var(--dimension-32);
  --sizing-icon-lg: var(--dimension-24);
  --sizing-icon-md: var(--dimension-20);
  --sizing-icon-sm: var(--dimension-16);
  --sizing-action-xlg: var(--dimension-48);
  --sizing-action-lg: var(--dimension-40);
  --sizing-action-md: var(--dimension-32);
  --sizing-action-s: var(--dimension-28);
  --sizing-action-xs: var(--dimension-24);
  --color-overlay-secondary: rgba(var(--color-navy-1000) var(--opacity-600));
  --color-overlay-primary: rgba(var(--color-indigo-1000) var(--opacity-600));
  --color-overlay-neutral: rgba(var(--color-neutral-1000) var(--opacity-600));
  --color-border-focus: var(--color-indigo-500);
  --color-border-info-decorative: var(--color-info-100);
  --color-border-info-subtlest: var(--color-info-200);
  --color-border-info-subtle: var(--color-info-500);
  --color-border-info-medium: var(--color-info-700);
  --color-border-info-bold: var(--color-info-900);
  --color-border-warning-decorative: var(--color-warning-100);
  --color-border-warning-subtlest: var(--color-warning-200);
  --color-border-warning-subtle: var(--color-warning-400);
  --color-border-warning-medium: var(--color-warning-700);
  --color-border-warning-bold: var(--color-warning-900);
  --color-border-success-decorative: var(--color-success-100);
  --color-border-success-subtlest: var(--color-success-200);
  --color-border-success-subtle: var(--color-success-500);
  --color-border-success-medium: var(--color-success-700);
  --color-border-success-bold: var(--color-success-900);
  --color-border-critical-decorative: var(--color-indigo-100);
  --color-border-critical-subtlest: var(--color-critical-200);
  --color-border-critical-subtle: var(--color-critical-500);
  --color-border-critical-medium: var(--color-critical-700);
  --color-border-critical-bold: var(--color-critical-900);
  --color-border-primary-decorative: var(--color-indigo-100);
  --color-border-primary-subtlest: var(--color-indigo-200);
  --color-border-primary-subtle: var(--color-indigo-500);
  --color-border-primary-medium: var(--color-indigo-700);
  --color-border-primary-bold: var(--color-indigo-900);
  --color-border-secondary-inverse: var(--color-navy-100);
  --color-border-secondary-decorative: var(--color-navy-100);
  --color-border-secondary-subtlest: var(--color-navy-200);
  --color-border-secondary-subtle: var(--color-navy-500);
  --color-border-secondary-medium: var(--color-navy-700);
  --color-border-secondary-bold: var(--color-navy-900);
  --color-border-neutral-decorative: var(--color-neutral-100);
  --color-border-neutral-subtlest: var(--color-neutral-200);
  --color-border-neutral-subtle: var(--color-neutral-500);
  --color-border-neutral-medium: var(--color-neutral-700);
  --color-border-neutral-bold: var(--color-neutral-900);
  --color-background-blue-subtlest: var(--color-blue-50);
  --color-background-blue-subtle: var(--color-blue-100);
  --color-background-blue-on-subtle-selected: rgba(var(--color-blue-700) var(--opacity-125));
  --color-background-blue-on-subtle-pressed: rgba(var(--color-blue-700) var(--opacity-150));
  --color-background-blue-on-subtle-hover: rgba(var(--color-blue-700) var(--opacity-100));
  --color-background-coral-subtlest: var(--color-coral-50);
  --color-background-coral-subtle: var(--color-coral-100);
  --color-background-coral-on-subtle-selected: rgba(var(--color-coral-700) var(--opacity-125));
  --color-background-coral-on-subtle-pressed: rgba(var(--color-coral-700) var(--opacity-150));
  --color-background-coral-on-subtle-hover: rgba(var(--color-coral-700) var(--opacity-100));
  --color-background-green-subtlest: var(--color-green-50);
  --color-background-green-subtle: var(--color-green-100);
  --color-background-green-on-subtle-selected: rgba(var(--color-green-700) var(--opacity-125));
  --color-background-green-on-subtle-pressed: rgba(var(--color-green-700) var(--opacity-150));
  --color-background-green-on-subtle-hover: rgba(var(--color-green-700) var(--opacity-100));
  --color-background-purple-subtlest: var(--color-purple-50);
  --color-background-purple-subtle: var(--color-purple-100);
  --color-background-purple-on-subtle-selected: rgba(var(--color-purple-700) var(--opacity-125));
  --color-background-purple-on-subtle-pressed: rgba(var(--color-purple-700) var(--opacity-150));
  --color-background-purple-on-subtle-hover: rgba(var(--color-purple-700) var(--opacity-100));
  --color-background-orange-subtlest: var(--color-orange-50);
  --color-background-orange-subtle: var(--color-orange-100);
  --color-background-orange-on-subtle-selected: rgba(var(--color-orange-700) var(--opacity-125));
  --color-background-orange-on-subtle-pressed: rgba(var(--color-orange-700) var(--opacity-150));
  --color-background-orange-on-subtle-hover: rgba(var(--color-orange-700) var(--opacity-100));
  --color-background-pink-subtlest: var(--color-pink-50);
  --color-background-pink-subtle: var(--color-pink-100);
  --color-background-pink-on-subtle-selected: rgba(var(--color-pink-700) var(--opacity-125));
  --color-background-pink-on-subtle-pressed: rgba(var(--color-pink-700) var(--opacity-150));
  --color-background-pink-on-subtle-hover: rgba(var(--color-pink-700) var(--opacity-100));
  --color-background-teal-subtlest: var(--color-teal-50);
  --color-background-teal-subtle: var(--color-teal-100);
  --color-background-teal-on-subtle-selected: rgba(var(--color-teal-700) var(--opacity-125));
  --color-background-teal-on-subtle-pressed: rgba(var(--color-teal-700) var(--opacity-150));
  --color-background-teal-on-subtle-hover: rgba(var(--color-teal-700) var(--opacity-100));
  --color-background-desaturated-subtlest: var(--color-gray-000);
  --color-background-desaturated-subtle: var(--color-gray-100);
  --color-background-desaturated-medium: var(--color-gray-200);
  --color-background-desaturated-bold: var(--color-gray-700);
  --color-background-desaturated-inverse: var(--color-gray-1000);
  --color-background-info-on-subtle-selected: rgba(var(--color-info-700) var(--opacity-125));
  --color-background-info-on-subtle-pressed: rgba(var(--color-info-700) var(--opacity-150));
  --color-background-info-on-subtle-hover: rgba(var(--color-info-700) var(--opacity-100));
  --color-background-info-subtlest: var(--color-info-50);
  --color-background-info-subtle: var(--color-info-100);
  --color-background-info-medium: var(--color-info-200);
  --color-background-info-bold: var(--color-info-700);
  --color-background-warning-on-subtle-selected: rgba(var(--color-warning-400) var(--opacity-125));
  --color-background-warning-on-subtle-pressed: rgba(var(--color-warning-400) var(--opacity-150));
  --color-background-warning-on-subtle-hover: rgba(var(--color-warning-400) var(--opacity-100));
  --color-background-warning-subtlest: var(--color-warning-50);
  --color-background-warning-subtle: var(--color-warning-100);
  --color-background-warning-medium: var(--color-warning-200);
  --color-background-warning-bold: var(--color-warning-400);
  --color-background-success-on-subtle-selected: rgba(var(--color-success-700) var(--opacity-125));
  --color-background-success-on-subtle-pressed: rgba(var(--color-success-700) var(--opacity-150));
  --color-background-success-on-subtle-hover: rgba(var(--color-success-700) var(--opacity-100));
  --color-background-success-subtlest: var(--color-success-50);
  --color-background-success-subtle: var(--color-success-100);
  --color-background-success-medium: var(--color-success-200);
  --color-background-success-bold: var(--color-success-700);
  --color-background-critical-on-subtle-selected: rgba(var(--color-critical-700) var(--opacity-125));
  --color-background-critical-on-subtle-pressed: rgba(var(--color-critical-700) var(--opacity-150));
  --color-background-critical-on-subtle-hover: rgba(var(--color-critical-700) var(--opacity-100));
  --color-background-critical-subtlest: var(--color-critical-50);
  --color-background-critical-subtle: var(--color-critical-100);
  --color-background-critical-medium: var(--color-critical-200);
  --color-background-critical-bold: var(--color-critical-700);
  --color-background-secondary-on-subtle-inverse-selected: rgba(var(--color-navy-400) var(--opacity-125));
  --color-background-secondary-on-subtle-inverse-pressed: rgba(var(--color-navy-400) var(--opacity-150));
  --color-background-secondary-on-subtle-inverse-hover: rgba(var(--color-navy-400) var(--opacity-100));
  --color-background-secondary-on-subtle-selected: rgba(var(--color-navy-700) var(--opacity-125));
  --color-background-secondary-on-subtle-pressed: rgba(var(--color-navy-700) var(--opacity-150));
  --color-background-secondary-on-subtle-hover: rgba(var(--color-navy-700) var(--opacity-100));
  --color-background-secondary-subtlest: var(--color-navy-50);
  --color-background-secondary-subtle: var(--color-navy-100);
  --color-background-secondary-medium: var(--color-navy-200);
  --color-background-secondary-bold: var(--color-navy-700);
  --color-background-secondary-inverse: var(--color-navy-1000);
  --color-background-primary-on-subtle-selected: rgba(var(--color-indigo-700) var(--opacity-125));
  --color-background-primary-on-subtle-pressed: rgba(var(--color-indigo-700) var(--opacity-150));
  --color-background-primary-on-subtle-hover: rgba(var(--color-indigo-700) var(--opacity-100));
  --color-background-primary-subtlest: var(--color-indigo-50);
  --color-background-primary-subtle: var(--color-indigo-100);
  --color-background-primary-medium: var(--color-indigo-200);
  --color-background-primary-bold: var(--color-indigo-700);
  --color-background-neutral-on-subtle-inverse-selected: rgba(var(--color-neutral-400) var(--opacity-125));
  --color-background-neutral-on-subtle-inverse-pressed: rgba(var(--color-neutral-400) var(--opacity-150));
  --color-background-neutral-on-subtle-inverse-hover: rgba(var(--color-neutral-400) var(--opacity-100));
  --color-background-neutral-disabled-medium: var(--color-neutral-200);
  --color-background-neutral-disabled-subtle: var(--color-neutral-100);
  --color-background-neutral-on-subtle-selected: rgba(var(--color-neutral-700) var(--opacity-125));
  --color-background-neutral-on-subtle-pressed: rgba(var(--color-neutral-700) var(--opacity-150));
  --color-background-neutral-on-subtle-hover: rgba(var(--color-neutral-700) var(--opacity-100));
  --color-background-neutral-on-bold-selected: rgba(var(--color-neutral-1000) var(--opacity-300));
  --color-background-neutral-on-bold-pressed: rgba(var(--color-neutral-1000) var(--opacity-400));
  --color-background-neutral-on-bold-hover: rgba(var(--color-neutral-000) var(--opacity-300));
  --color-background-neutral-subtlest: var(--color-neutral-000);
  --color-background-neutral-subtle: var(--color-neutral-100);
  --color-background-neutral-medium: var(--color-neutral-200);
  --color-background-neutral-bold: var(--color-neutral-700);
  --color-background-neutral-inverse: var(--color-neutral-1000);
  --color-page-subtle-desaturated: var(--color-gray-50);
  --color-page-subtle: var(--color-navy-100);
  --color-page-bold: var(--color-neutral-000);
  --color-foreground-blue-subtle: var(--color-blue-500);
  --color-foreground-coral-subtle: var(--color-coral-500);
  --color-foreground-green-subtle: var(--color-green-500);
  --color-foreground-purple-subtle: var(--color-purple-500);
  --color-foreground-orange-subtle: var(--color-orange-500);
  --color-foreground-pink-subtle: var(--color-pink-500);
  --color-foreground-teal-subtle: var(--color-teal-500);
  --color-foreground-info-subtle: var(--color-info-500);
  --color-foreground-info-medium: var(--color-info-700);
  --color-foreground-info-bold: var(--color-info-900);
  --color-foreground-warning-subtle: var(--color-warning-500);
  --color-foreground-warning-medium: var(--color-warning-700);
  --color-foreground-warning-bold: var(--color-warning-900);
  --color-foreground-success-subtle: var(--color-success-500);
  --color-foreground-success-medium: var(--color-success-700);
  --color-foreground-success-bold: var(--color-success-900);
  --color-foreground-critical-subtle: var(--color-critical-500);
  --color-foreground-critical-medium: var(--color-critical-700);
  --color-foreground-critical-bold: var(--color-critical-900);
  --color-foreground-secondary-subtle: var(--color-navy-500);
  --color-foreground-secondary-medium: var(--color-navy-700);
  --color-foreground-secondary-bold: var(--color-navy-900);
  --color-foreground-primary-subtle: var(--color-indigo-500);
  --color-foreground-primary-medium: var(--color-indigo-700);
  --color-foreground-primary-bold: var(--color-indigo-900);
  --color-foreground-neutral-inverse: var(--color-neutral-000);
  --color-foreground-neutral-disabled: var(--color-neutral-400);
  --color-foreground-neutral-subtle: var(--color-neutral-600);
  --color-foreground-neutral-medium: var(--color-neutral-800);
  --color-foreground-neutral-bold: var(--color-neutral-1000);
}



:root {
  --typography-display: var(--font-weight-medium) var(--font-size-32) / var(--line-height-40) "Rubik", sans-serif;
  --typography-heading-1: var(--font-weight-medium) var(--font-size-24) / var(--line-height-32) "Rubik", sans-serif;
  --typography-heading-2: var(--font-weight-medium) var(--font-size-20) / var(--line-height-28) "Rubik", sans-serif;
  --typography-heading-3: var(--font-weight-medium) var(--font-size-16) / var(--line-height-24) "Rubik", sans-serif;
  --typography-heading-4: var(--font-weight-medium) var(--font-size-14) / var(--line-height-20) "Rubik", sans-serif;
  --typography-heading-5: var(--font-weight-regular) var(--font-size-13) / var(--line-height-20) "Rubik", sans-serif;
  --typography-body-1: var(--font-weight-regular) var(--font-size-16) / var(--line-height-24) "Rubik", sans-serif;
  --typography-body-bold-1: var(--font-weight-medium) var(--font-size-16) / var(--line-height-24) "Rubik", sans-serif;
  --typography-body-2: var(--font-weight-regular) var(--font-size-14) / var(--line-height-20) "Rubik", sans-serif;
  --typography-body-bold-2: var(--font-weight-medium) var(--font-size-14) / var(--line-height-20) "Rubik", sans-serif;
  --typography-body-3: var(--font-weight-regular) var(--font-size-12) / var(--line-height-16) "Rubik", sans-serif;
  --typography-body-bold-3: var(--font-weight-medium) var(--font-size-12) / var(--line-height-16) "Rubik", sans-serif;
  --typography-display-mobile: var(--font-weight-medium) var(--font-size-24) / var(--line-height-32) "Rubik", sans-serif;
  --typography-heading-1-mobile: var(--font-weight-medium) var(--font-size-20) / var(--line-height-28) "Rubik", sans-serif;
  --typography-heading-2-mobile: var(--font-weight-medium) var(--font-size-16) / var(--line-height-24) "Rubik", sans-serif;
  --typography-heading-3-mobile: var(--font-weight-medium) var(--font-size-14) / var(--line-height-20) "Rubik", sans-serif;
  --typography-heading-4-mobile: var(--font-weight-medium) var(--font-size-12) / var(--line-height-16) "Rubik", sans-serif;
  --typography-heading-5-mobile: var(--font-weight-regular) var(--font-size-12) / var(--line-height-16) "Rubik", sans-serif;
  --typography-body-1-mobile: var(--font-weight-regular) var(--font-size-16) / var(--line-height-24) "Rubik", sans-serif;
  --typography-body-bold-1-mobile: var(--font-weight-medium) var(--font-size-16) / var(--line-height-24) "Rubik", sans-serif;
  --typography-body-2-mobile: var(--font-weight-regular) var(--font-size-14) / var(--line-height-20) "Rubik", sans-serif;
  --typography-body-bold-2-mobile: var(--font-weight-medium) var(--font-size-14) / var(--line-height-20) "Rubik", sans-serif;
  --typography-body-3-mobile: var(--font-weight-regular) var(--font-size-12) / var(--line-height-16) "Rubik", sans-serif;
  --typography-body-bold-3-mobile: var(--font-weight-medium) var(--font-size-12) / var(--line-height-16) "Rubik", sans-serif;
  --box-shadow-pretoken-overlay-left: -4px 0px 12px 0px #0000000f;
  --box-shadow-pretoken-overlay-right: 4px 0px 12px 0px #0000000f;
  --box-shadow-pretoken-overlay-top: 0px -4px 12px 0px #0000000f;
  --box-shadow-pretoken-overlay-bottom: 0px 4px 12px 0px #0000000f;
  --box-shadow-pretoken-overlay-interaction: 0px 2px 16px 2px #00000014;
  --box-shadow-pretoken-button-interaction: 0px 1px 8px 0px #0000000f;
  --box-shadow-pretoken-container-interaction: 0px 4px 16px 0px #00000029;
  --box-shadow-pretoken-container-default: 0px 2px 12px 0px #0000000f;
  --application-icon-size-xxsmall: 1.25rem;
  --application-icon-size-xsmall: 1.5rem;
  --application-icon-size-small: 2rem;
  --application-icon-size-medium: 2.5rem;
  --application-icon-size-large: 3rem;
  --application-icon-size-xlarge: 3.5rem;
  --application-icon-size-xxlarge: 4rem;
  --z-index-floating-banner: 1050;
  --z-index-modal-island: 1010;
  --z-index-react-date-picker-popper: 1010;
  --z-index-tooltip: 1000;
  --z-index-page-header: 999;
  --z-index-accordion-button: 4;
  --z-index-landingpage-sidepanel: 2;
  --z-index-landingpage-listrows: 3;
  --z-index-dropdown: 10;
  --z-index-alertbanners: 11;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  font-family: Rubik, Helvetica, Arial, serif;
}

.Color-foreground-gray-50 {
  color: var(--color-gray-50);
}

.Color-foreground-gray-100 {
  color: var(--color-gray-100);
}

.Color-foreground-gray-200 {
  color: var(--color-gray-200);
}

.Color-foreground-gray-300 {
  color: var(--color-gray-300);
}

.Color-foreground-gray-400 {
  color: var(--color-gray-400);
}

.Color-foreground-gray-500 {
  color: var(--color-gray-500);
}

.Color-foreground-gray-600 {
  color: var(--color-gray-600);
}

.Color-foreground-gray-700 {
  color: var(--color-gray-700);
}

.Color-foreground-gray-800 {
  color: var(--color-gray-800);
}

.Color-foreground-gray-900 {
  color: var(--color-gray-900);
}

.Color-foreground-gray-1000 {
  color: var(--color-gray-1000);
}

.Color-foreground-gray-1100 {
  color: var(--color-gray-1100);
}

.Color-foreground-gray-000 {
  color: var(--color-gray-000);
}

.Color-foreground-neutral-50 {
  color: var(--color-neutral-50);
}

.Color-foreground-neutral-100 {
  color: var(--color-neutral-100);
}

.Color-foreground-neutral-200 {
  color: var(--color-neutral-200);
}

.Color-foreground-neutral-300 {
  color: var(--color-neutral-300);
}

.Color-foreground-neutral-400 {
  color: var(--color-neutral-400);
}

.Color-foreground-neutral-500 {
  color: var(--color-neutral-500);
}

.Color-foreground-neutral-600 {
  color: var(--color-neutral-600);
}

.Color-foreground-neutral-700 {
  color: var(--color-neutral-700);
}

.Color-foreground-neutral-800 {
  color: var(--color-neutral-800);
}

.Color-foreground-neutral-900 {
  color: var(--color-neutral-900);
}

.Color-foreground-neutral-1000 {
  color: var(--color-neutral-1000);
}

.Color-foreground-neutral-1100 {
  color: var(--color-neutral-1100);
}

.Color-foreground-neutral-000 {
  color: var(--color-neutral-000);
}

.Color-foreground-indigo-50 {
  color: var(--color-indigo-50);
}

.Color-foreground-indigo-100 {
  color: var(--color-indigo-100);
}

.Color-foreground-indigo-200 {
  color: var(--color-indigo-200);
}

.Color-foreground-indigo-300 {
  color: var(--color-indigo-300);
}

.Color-foreground-indigo-400 {
  color: var(--color-indigo-400);
}

.Color-foreground-indigo-500 {
  color: var(--color-indigo-500);
}

.Color-foreground-indigo-600 {
  color: var(--color-indigo-600);
}

.Color-foreground-indigo-700 {
  color: var(--color-indigo-700);
}

.Color-foreground-indigo-800 {
  color: var(--color-indigo-800);
}

.Color-foreground-indigo-900 {
  color: var(--color-indigo-900);
}

.Color-foreground-indigo-1000 {
  color: var(--color-indigo-1000);
}

.Color-foreground-indigo-1100 {
  color: var(--color-indigo-1100);
}

.Color-foreground-navy-50 {
  color: var(--color-navy-50);
}

.Color-foreground-navy-100 {
  color: var(--color-navy-100);
}

.Color-foreground-navy-200 {
  color: var(--color-navy-200);
}

.Color-foreground-navy-300 {
  color: var(--color-navy-300);
}

.Color-foreground-navy-400 {
  color: var(--color-navy-400);
}

.Color-foreground-navy-500 {
  color: var(--color-navy-500);
}

.Color-foreground-navy-600 {
  color: var(--color-navy-600);
}

.Color-foreground-navy-700 {
  color: var(--color-navy-700);
}

.Color-foreground-navy-800 {
  color: var(--color-navy-800);
}

.Color-foreground-navy-900 {
  color: var(--color-navy-900);
}

.Color-foreground-navy-1000 {
  color: var(--color-navy-1000);
}

.Color-foreground-navy-1100 {
  color: var(--color-navy-1100);
}

.Color-foreground-critical-50 {
  color: var(--color-critical-50);
}

.Color-foreground-critical-100 {
  color: var(--color-critical-100);
}

.Color-foreground-critical-200 {
  color: var(--color-critical-200);
}

.Color-foreground-critical-300 {
  color: var(--color-critical-300);
}

.Color-foreground-critical-400 {
  color: var(--color-critical-400);
}

.Color-foreground-critical-500 {
  color: var(--color-critical-500);
}

.Color-foreground-critical-600 {
  color: var(--color-critical-600);
}

.Color-foreground-critical-700 {
  color: var(--color-critical-700);
}

.Color-foreground-critical-800 {
  color: var(--color-critical-800);
}

.Color-foreground-critical-900 {
  color: var(--color-critical-900);
}

.Color-foreground-critical-1000 {
  color: var(--color-critical-1000);
}

.Color-foreground-critical-1100 {
  color: var(--color-critical-1100);
}

.Color-foreground-success-50 {
  color: var(--color-success-50);
}

.Color-foreground-success-100 {
  color: var(--color-success-100);
}

.Color-foreground-success-200 {
  color: var(--color-success-200);
}

.Color-foreground-success-300 {
  color: var(--color-success-300);
}

.Color-foreground-success-400 {
  color: var(--color-success-400);
}

.Color-foreground-success-500 {
  color: var(--color-success-500);
}

.Color-foreground-success-600 {
  color: var(--color-success-600);
}

.Color-foreground-success-700 {
  color: var(--color-success-700);
}

.Color-foreground-success-800 {
  color: var(--color-success-800);
}

.Color-foreground-success-900 {
  color: var(--color-success-900);
}

.Color-foreground-success-1000 {
  color: var(--color-success-1000);
}

.Color-foreground-success-1100 {
  color: var(--color-success-1100);
}

.Color-foreground-warning-50 {
  color: var(--color-warning-50);
}

.Color-foreground-warning-100 {
  color: var(--color-warning-100);
}

.Color-foreground-warning-200 {
  color: var(--color-warning-200);
}

.Color-foreground-warning-300 {
  color: var(--color-warning-300);
}

.Color-foreground-warning-400 {
  color: var(--color-warning-400);
}

.Color-foreground-warning-500 {
  color: var(--color-warning-500);
}

.Color-foreground-warning-600 {
  color: var(--color-warning-600);
}

.Color-foreground-warning-700 {
  color: var(--color-warning-700);
}

.Color-foreground-warning-800 {
  color: var(--color-warning-800);
}

.Color-foreground-warning-900 {
  color: var(--color-warning-900);
}

.Color-foreground-warning-1000 {
  color: var(--color-warning-1000);
}

.Color-foreground-warning-1100 {
  color: var(--color-warning-1100);
}

.Color-foreground-info-50 {
  color: var(--color-info-50);
}

.Color-foreground-info-100 {
  color: var(--color-info-100);
}

.Color-foreground-info-200 {
  color: var(--color-info-200);
}

.Color-foreground-info-300 {
  color: var(--color-info-300);
}

.Color-foreground-info-400 {
  color: var(--color-info-400);
}

.Color-foreground-info-500 {
  color: var(--color-info-500);
}

.Color-foreground-info-600 {
  color: var(--color-info-600);
}

.Color-foreground-info-700 {
  color: var(--color-info-700);
}

.Color-foreground-info-800 {
  color: var(--color-info-800);
}

.Color-foreground-info-900 {
  color: var(--color-info-900);
}

.Color-foreground-info-1000 {
  color: var(--color-info-1000);
}

.Color-foreground-info-1100 {
  color: var(--color-info-1100);
}

.Color-foreground-teal-50 {
  color: var(--color-teal-50);
}

.Color-foreground-teal-100 {
  color: var(--color-teal-100);
}

.Color-foreground-teal-200 {
  color: var(--color-teal-200);
}

.Color-foreground-teal-300 {
  color: var(--color-teal-300);
}

.Color-foreground-teal-400 {
  color: var(--color-teal-400);
}

.Color-foreground-teal-500 {
  color: var(--color-teal-500);
}

.Color-foreground-teal-600 {
  color: var(--color-teal-600);
}

.Color-foreground-teal-700 {
  color: var(--color-teal-700);
}

.Color-foreground-teal-800 {
  color: var(--color-teal-800);
}

.Color-foreground-teal-900 {
  color: var(--color-teal-900);
}

.Color-foreground-teal-1000 {
  color: var(--color-teal-1000);
}

.Color-foreground-teal-1100 {
  color: var(--color-teal-1100);
}

.Color-foreground-pink-50 {
  color: var(--color-pink-50);
}

.Color-foreground-pink-100 {
  color: var(--color-pink-100);
}

.Color-foreground-pink-200 {
  color: var(--color-pink-200);
}

.Color-foreground-pink-300 {
  color: var(--color-pink-300);
}

.Color-foreground-pink-400 {
  color: var(--color-pink-400);
}

.Color-foreground-pink-500 {
  color: var(--color-pink-500);
}

.Color-foreground-pink-600 {
  color: var(--color-pink-600);
}

.Color-foreground-pink-700 {
  color: var(--color-pink-700);
}

.Color-foreground-pink-800 {
  color: var(--color-pink-800);
}

.Color-foreground-pink-900 {
  color: var(--color-pink-900);
}

.Color-foreground-pink-1000 {
  color: var(--color-pink-1000);
}

.Color-foreground-pink-1100 {
  color: var(--color-pink-1100);
}

.Color-foreground-orange-50 {
  color: var(--color-orange-50);
}

.Color-foreground-orange-100 {
  color: var(--color-orange-100);
}

.Color-foreground-orange-200 {
  color: var(--color-orange-200);
}

.Color-foreground-orange-300 {
  color: var(--color-orange-300);
}

.Color-foreground-orange-400 {
  color: var(--color-orange-400);
}

.Color-foreground-orange-500 {
  color: var(--color-orange-500);
}

.Color-foreground-orange-600 {
  color: var(--color-orange-600);
}

.Color-foreground-orange-700 {
  color: var(--color-orange-700);
}

.Color-foreground-orange-800 {
  color: var(--color-orange-800);
}

.Color-foreground-orange-900 {
  color: var(--color-orange-900);
}

.Color-foreground-orange-1000 {
  color: var(--color-orange-1000);
}

.Color-foreground-orange-1100 {
  color: var(--color-orange-1100);
}

.Color-foreground-purple-50 {
  color: var(--color-purple-50);
}

.Color-foreground-purple-100 {
  color: var(--color-purple-100);
}

.Color-foreground-purple-200 {
  color: var(--color-purple-200);
}

.Color-foreground-purple-300 {
  color: var(--color-purple-300);
}

.Color-foreground-purple-400 {
  color: var(--color-purple-400);
}

.Color-foreground-purple-500 {
  color: var(--color-purple-500);
}

.Color-foreground-purple-600 {
  color: var(--color-purple-600);
}

.Color-foreground-purple-700 {
  color: var(--color-purple-700);
}

.Color-foreground-purple-800 {
  color: var(--color-purple-800);
}

.Color-foreground-purple-900 {
  color: var(--color-purple-900);
}

.Color-foreground-purple-1000 {
  color: var(--color-purple-1000);
}

.Color-foreground-purple-1100 {
  color: var(--color-purple-1100);
}

.Color-foreground-green-50 {
  color: var(--color-green-50);
}

.Color-foreground-green-100 {
  color: var(--color-green-100);
}

.Color-foreground-green-200 {
  color: var(--color-green-200);
}

.Color-foreground-green-300 {
  color: var(--color-green-300);
}

.Color-foreground-green-400 {
  color: var(--color-green-400);
}

.Color-foreground-green-500 {
  color: var(--color-green-500);
}

.Color-foreground-green-600 {
  color: var(--color-green-600);
}

.Color-foreground-green-700 {
  color: var(--color-green-700);
}

.Color-foreground-green-800 {
  color: var(--color-green-800);
}

.Color-foreground-green-900 {
  color: var(--color-green-900);
}

.Color-foreground-green-1000 {
  color: var(--color-green-1000);
}

.Color-foreground-green-1100 {
  color: var(--color-green-1100);
}

.Color-foreground-coral-50 {
  color: var(--color-coral-50);
}

.Color-foreground-coral-100 {
  color: var(--color-coral-100);
}

.Color-foreground-coral-200 {
  color: var(--color-coral-200);
}

.Color-foreground-coral-300 {
  color: var(--color-coral-300);
}

.Color-foreground-coral-400 {
  color: var(--color-coral-400);
}

.Color-foreground-coral-500 {
  color: var(--color-coral-500);
}

.Color-foreground-coral-600 {
  color: var(--color-coral-600);
}

.Color-foreground-coral-700 {
  color: var(--color-coral-700);
}

.Color-foreground-coral-800 {
  color: var(--color-coral-800);
}

.Color-foreground-coral-900 {
  color: var(--color-coral-900);
}

.Color-foreground-coral-1000 {
  color: var(--color-coral-1000);
}

.Color-foreground-coral-1100 {
  color: var(--color-coral-1100);
}

.Color-foreground-blue-50 {
  color: var(--color-blue-50);
}

.Color-foreground-blue-100 {
  color: var(--color-blue-100);
}

.Color-foreground-blue-200 {
  color: var(--color-blue-200);
}

.Color-foreground-blue-300 {
  color: var(--color-blue-300);
}

.Color-foreground-blue-400 {
  color: var(--color-blue-400);
}

.Color-foreground-blue-500 {
  color: var(--color-blue-500);
}

.Color-foreground-blue-600 {
  color: var(--color-blue-600);
}

.Color-foreground-blue-700 {
  color: var(--color-blue-700);
}

.Color-foreground-blue-800 {
  color: var(--color-blue-800);
}

.Color-foreground-blue-900 {
  color: var(--color-blue-900);
}

.Color-foreground-blue-1000 {
  color: var(--color-blue-1000);
}

.Color-foreground-blue-1100 {
  color: var(--color-blue-1100);
}

.Color-background-gray-50 {
  background: var(--color-gray-50);
}

.Color-background-gray-100 {
  background: var(--color-gray-100);
}

.Color-background-gray-200 {
  background: var(--color-gray-200);
}

.Color-background-gray-300 {
  background: var(--color-gray-300);
}

.Color-background-gray-400 {
  background: var(--color-gray-400);
}

.Color-background-gray-500 {
  background: var(--color-gray-500);
}

.Color-background-gray-600 {
  background: var(--color-gray-600);
}

.Color-background-gray-700 {
  background: var(--color-gray-700);
}

.Color-background-gray-800 {
  background: var(--color-gray-800);
}

.Color-background-gray-900 {
  background: var(--color-gray-900);
}

.Color-background-gray-1000 {
  background: var(--color-gray-1000);
}

.Color-background-gray-1100 {
  background: var(--color-gray-1100);
}

.Color-background-gray-000 {
  background: var(--color-gray-000);
}

.Color-background-neutral-50 {
  background: var(--color-neutral-50);
}

.Color-background-neutral-100 {
  background: var(--color-neutral-100);
}

.Color-background-neutral-200 {
  background: var(--color-neutral-200);
}

.Color-background-neutral-300 {
  background: var(--color-neutral-300);
}

.Color-background-neutral-400 {
  background: var(--color-neutral-400);
}

.Color-background-neutral-500 {
  background: var(--color-neutral-500);
}

.Color-background-neutral-600 {
  background: var(--color-neutral-600);
}

.Color-background-neutral-700 {
  background: var(--color-neutral-700);
}

.Color-background-neutral-800 {
  background: var(--color-neutral-800);
}

.Color-background-neutral-900 {
  background: var(--color-neutral-900);
}

.Color-background-neutral-1000 {
  background: var(--color-neutral-1000);
}

.Color-background-neutral-1100 {
  background: var(--color-neutral-1100);
}

.Color-background-neutral-000 {
  background: var(--color-neutral-000);
}

.Color-background-indigo-50 {
  background: var(--color-indigo-50);
}

.Color-background-indigo-100 {
  background: var(--color-indigo-100);
}

.Color-background-indigo-200 {
  background: var(--color-indigo-200);
}

.Color-background-indigo-300 {
  background: var(--color-indigo-300);
}

.Color-background-indigo-400 {
  background: var(--color-indigo-400);
}

.Color-background-indigo-500 {
  background: var(--color-indigo-500);
}

.Color-background-indigo-600 {
  background: var(--color-indigo-600);
}

.Color-background-indigo-700 {
  background: var(--color-indigo-700);
}

.Color-background-indigo-800 {
  background: var(--color-indigo-800);
}

.Color-background-indigo-900 {
  background: var(--color-indigo-900);
}

.Color-background-indigo-1000 {
  background: var(--color-indigo-1000);
}

.Color-background-indigo-1100 {
  background: var(--color-indigo-1100);
}

.Color-background-navy-50 {
  background: var(--color-navy-50);
}

.Color-background-navy-100 {
  background: var(--color-navy-100);
}

.Color-background-navy-200 {
  background: var(--color-navy-200);
}

.Color-background-navy-300 {
  background: var(--color-navy-300);
}

.Color-background-navy-400 {
  background: var(--color-navy-400);
}

.Color-background-navy-500 {
  background: var(--color-navy-500);
}

.Color-background-navy-600 {
  background: var(--color-navy-600);
}

.Color-background-navy-700 {
  background: var(--color-navy-700);
}

.Color-background-navy-800 {
  background: var(--color-navy-800);
}

.Color-background-navy-900 {
  background: var(--color-navy-900);
}

.Color-background-navy-1000 {
  background: var(--color-navy-1000);
}

.Color-background-navy-1100 {
  background: var(--color-navy-1100);
}

.Color-background-critical-50 {
  background: var(--color-critical-50);
}

.Color-background-critical-100 {
  background: var(--color-critical-100);
}

.Color-background-critical-200 {
  background: var(--color-critical-200);
}

.Color-background-critical-300 {
  background: var(--color-critical-300);
}

.Color-background-critical-400 {
  background: var(--color-critical-400);
}

.Color-background-critical-500 {
  background: var(--color-critical-500);
}

.Color-background-critical-600 {
  background: var(--color-critical-600);
}

.Color-background-critical-700 {
  background: var(--color-critical-700);
}

.Color-background-critical-800 {
  background: var(--color-critical-800);
}

.Color-background-critical-900 {
  background: var(--color-critical-900);
}

.Color-background-critical-1000 {
  background: var(--color-critical-1000);
}

.Color-background-critical-1100 {
  background: var(--color-critical-1100);
}

.Color-background-success-50 {
  background: var(--color-success-50);
}

.Color-background-success-100 {
  background: var(--color-success-100);
}

.Color-background-success-200 {
  background: var(--color-success-200);
}

.Color-background-success-300 {
  background: var(--color-success-300);
}

.Color-background-success-400 {
  background: var(--color-success-400);
}

.Color-background-success-500 {
  background: var(--color-success-500);
}

.Color-background-success-600 {
  background: var(--color-success-600);
}

.Color-background-success-700 {
  background: var(--color-success-700);
}

.Color-background-success-800 {
  background: var(--color-success-800);
}

.Color-background-success-900 {
  background: var(--color-success-900);
}

.Color-background-success-1000 {
  background: var(--color-success-1000);
}

.Color-background-success-1100 {
  background: var(--color-success-1100);
}

.Color-background-warning-50 {
  background: var(--color-warning-50);
}

.Color-background-warning-100 {
  background: var(--color-warning-100);
}

.Color-background-warning-200 {
  background: var(--color-warning-200);
}

.Color-background-warning-300 {
  background: var(--color-warning-300);
}

.Color-background-warning-400 {
  background: var(--color-warning-400);
}

.Color-background-warning-500 {
  background: var(--color-warning-500);
}

.Color-background-warning-600 {
  background: var(--color-warning-600);
}

.Color-background-warning-700 {
  background: var(--color-warning-700);
}

.Color-background-warning-800 {
  background: var(--color-warning-800);
}

.Color-background-warning-900 {
  background: var(--color-warning-900);
}

.Color-background-warning-1000 {
  background: var(--color-warning-1000);
}

.Color-background-warning-1100 {
  background: var(--color-warning-1100);
}

.Color-background-info-50 {
  background: var(--color-info-50);
}

.Color-background-info-100 {
  background: var(--color-info-100);
}

.Color-background-info-200 {
  background: var(--color-info-200);
}

.Color-background-info-300 {
  background: var(--color-info-300);
}

.Color-background-info-400 {
  background: var(--color-info-400);
}

.Color-background-info-500 {
  background: var(--color-info-500);
}

.Color-background-info-600 {
  background: var(--color-info-600);
}

.Color-background-info-700 {
  background: var(--color-info-700);
}

.Color-background-info-800 {
  background: var(--color-info-800);
}

.Color-background-info-900 {
  background: var(--color-info-900);
}

.Color-background-info-1000 {
  background: var(--color-info-1000);
}

.Color-background-info-1100 {
  background: var(--color-info-1100);
}

.Color-background-teal-50 {
  background: var(--color-teal-50);
}

.Color-background-teal-100 {
  background: var(--color-teal-100);
}

.Color-background-teal-200 {
  background: var(--color-teal-200);
}

.Color-background-teal-300 {
  background: var(--color-teal-300);
}

.Color-background-teal-400 {
  background: var(--color-teal-400);
}

.Color-background-teal-500 {
  background: var(--color-teal-500);
}

.Color-background-teal-600 {
  background: var(--color-teal-600);
}

.Color-background-teal-700 {
  background: var(--color-teal-700);
}

.Color-background-teal-800 {
  background: var(--color-teal-800);
}

.Color-background-teal-900 {
  background: var(--color-teal-900);
}

.Color-background-teal-1000 {
  background: var(--color-teal-1000);
}

.Color-background-teal-1100 {
  background: var(--color-teal-1100);
}

.Color-background-pink-50 {
  background: var(--color-pink-50);
}

.Color-background-pink-100 {
  background: var(--color-pink-100);
}

.Color-background-pink-200 {
  background: var(--color-pink-200);
}

.Color-background-pink-300 {
  background: var(--color-pink-300);
}

.Color-background-pink-400 {
  background: var(--color-pink-400);
}

.Color-background-pink-500 {
  background: var(--color-pink-500);
}

.Color-background-pink-600 {
  background: var(--color-pink-600);
}

.Color-background-pink-700 {
  background: var(--color-pink-700);
}

.Color-background-pink-800 {
  background: var(--color-pink-800);
}

.Color-background-pink-900 {
  background: var(--color-pink-900);
}

.Color-background-pink-1000 {
  background: var(--color-pink-1000);
}

.Color-background-pink-1100 {
  background: var(--color-pink-1100);
}

.Color-background-orange-50 {
  background: var(--color-orange-50);
}

.Color-background-orange-100 {
  background: var(--color-orange-100);
}

.Color-background-orange-200 {
  background: var(--color-orange-200);
}

.Color-background-orange-300 {
  background: var(--color-orange-300);
}

.Color-background-orange-400 {
  background: var(--color-orange-400);
}

.Color-background-orange-500 {
  background: var(--color-orange-500);
}

.Color-background-orange-600 {
  background: var(--color-orange-600);
}

.Color-background-orange-700 {
  background: var(--color-orange-700);
}

.Color-background-orange-800 {
  background: var(--color-orange-800);
}

.Color-background-orange-900 {
  background: var(--color-orange-900);
}

.Color-background-orange-1000 {
  background: var(--color-orange-1000);
}

.Color-background-orange-1100 {
  background: var(--color-orange-1100);
}

.Color-background-purple-50 {
  background: var(--color-purple-50);
}

.Color-background-purple-100 {
  background: var(--color-purple-100);
}

.Color-background-purple-200 {
  background: var(--color-purple-200);
}

.Color-background-purple-300 {
  background: var(--color-purple-300);
}

.Color-background-purple-400 {
  background: var(--color-purple-400);
}

.Color-background-purple-500 {
  background: var(--color-purple-500);
}

.Color-background-purple-600 {
  background: var(--color-purple-600);
}

.Color-background-purple-700 {
  background: var(--color-purple-700);
}

.Color-background-purple-800 {
  background: var(--color-purple-800);
}

.Color-background-purple-900 {
  background: var(--color-purple-900);
}

.Color-background-purple-1000 {
  background: var(--color-purple-1000);
}

.Color-background-purple-1100 {
  background: var(--color-purple-1100);
}

.Color-background-green-50 {
  background: var(--color-green-50);
}

.Color-background-green-100 {
  background: var(--color-green-100);
}

.Color-background-green-200 {
  background: var(--color-green-200);
}

.Color-background-green-300 {
  background: var(--color-green-300);
}

.Color-background-green-400 {
  background: var(--color-green-400);
}

.Color-background-green-500 {
  background: var(--color-green-500);
}

.Color-background-green-600 {
  background: var(--color-green-600);
}

.Color-background-green-700 {
  background: var(--color-green-700);
}

.Color-background-green-800 {
  background: var(--color-green-800);
}

.Color-background-green-900 {
  background: var(--color-green-900);
}

.Color-background-green-1000 {
  background: var(--color-green-1000);
}

.Color-background-green-1100 {
  background: var(--color-green-1100);
}

.Color-background-coral-50 {
  background: var(--color-coral-50);
}

.Color-background-coral-100 {
  background: var(--color-coral-100);
}

.Color-background-coral-200 {
  background: var(--color-coral-200);
}

.Color-background-coral-300 {
  background: var(--color-coral-300);
}

.Color-background-coral-400 {
  background: var(--color-coral-400);
}

.Color-background-coral-500 {
  background: var(--color-coral-500);
}

.Color-background-coral-600 {
  background: var(--color-coral-600);
}

.Color-background-coral-700 {
  background: var(--color-coral-700);
}

.Color-background-coral-800 {
  background: var(--color-coral-800);
}

.Color-background-coral-900 {
  background: var(--color-coral-900);
}

.Color-background-coral-1000 {
  background: var(--color-coral-1000);
}

.Color-background-coral-1100 {
  background: var(--color-coral-1100);
}

.Color-background-blue-50 {
  background: var(--color-blue-50);
}

.Color-background-blue-100 {
  background: var(--color-blue-100);
}

.Color-background-blue-200 {
  background: var(--color-blue-200);
}

.Color-background-blue-300 {
  background: var(--color-blue-300);
}

.Color-background-blue-400 {
  background: var(--color-blue-400);
}

.Color-background-blue-500 {
  background: var(--color-blue-500);
}

.Color-background-blue-600 {
  background: var(--color-blue-600);
}

.Color-background-blue-700 {
  background: var(--color-blue-700);
}

.Color-background-blue-800 {
  background: var(--color-blue-800);
}

.Color-background-blue-900 {
  background: var(--color-blue-900);
}

.Color-background-blue-1000 {
  background: var(--color-blue-1000);
}

.Color-background-blue-1100 {
  background: var(--color-blue-1100);
}

.Color-icon-gray-50 path, .Color-icon-gray-50 rect, .Color-icon-gray-50 polygon, .Color-icon-gray-50 circle, .Color-icon-gray-50 {
  fill: var(--color-gray-50);
}

.Color-icon-gray-100 path, .Color-icon-gray-100 rect, .Color-icon-gray-100 polygon, .Color-icon-gray-100 circle, .Color-icon-gray-100 {
  fill: var(--color-gray-100);
}

.Color-icon-gray-200 path, .Color-icon-gray-200 rect, .Color-icon-gray-200 polygon, .Color-icon-gray-200 circle, .Color-icon-gray-200 {
  fill: var(--color-gray-200);
}

.Color-icon-gray-300 path, .Color-icon-gray-300 rect, .Color-icon-gray-300 polygon, .Color-icon-gray-300 circle, .Color-icon-gray-300 {
  fill: var(--color-gray-300);
}

.Color-icon-gray-400 path, .Color-icon-gray-400 rect, .Color-icon-gray-400 polygon, .Color-icon-gray-400 circle, .Color-icon-gray-400 {
  fill: var(--color-gray-400);
}

.Color-icon-gray-500 path, .Color-icon-gray-500 rect, .Color-icon-gray-500 polygon, .Color-icon-gray-500 circle, .Color-icon-gray-500 {
  fill: var(--color-gray-500);
}

.Color-icon-gray-600 path, .Color-icon-gray-600 rect, .Color-icon-gray-600 polygon, .Color-icon-gray-600 circle, .Color-icon-gray-600 {
  fill: var(--color-gray-600);
}

.Color-icon-gray-700 path, .Color-icon-gray-700 rect, .Color-icon-gray-700 polygon, .Color-icon-gray-700 circle, .Color-icon-gray-700 {
  fill: var(--color-gray-700);
}

.Color-icon-gray-800 path, .Color-icon-gray-800 rect, .Color-icon-gray-800 polygon, .Color-icon-gray-800 circle, .Color-icon-gray-800 {
  fill: var(--color-gray-800);
}

.Color-icon-gray-900 path, .Color-icon-gray-900 rect, .Color-icon-gray-900 polygon, .Color-icon-gray-900 circle, .Color-icon-gray-900 {
  fill: var(--color-gray-900);
}

.Color-icon-gray-1000 path, .Color-icon-gray-1000 rect, .Color-icon-gray-1000 polygon, .Color-icon-gray-1000 circle, .Color-icon-gray-1000 {
  fill: var(--color-gray-1000);
}

.Color-icon-gray-1100 path, .Color-icon-gray-1100 rect, .Color-icon-gray-1100 polygon, .Color-icon-gray-1100 circle, .Color-icon-gray-1100 {
  fill: var(--color-gray-1100);
}

.Color-icon-gray-000 path, .Color-icon-gray-000 rect, .Color-icon-gray-000 polygon, .Color-icon-gray-000 circle, .Color-icon-gray-000 {
  fill: var(--color-gray-000);
}

.Color-icon-neutral-50 path, .Color-icon-neutral-50 rect, .Color-icon-neutral-50 polygon, .Color-icon-neutral-50 circle, .Color-icon-neutral-50 {
  fill: var(--color-neutral-50);
}

.Color-icon-neutral-100 path, .Color-icon-neutral-100 rect, .Color-icon-neutral-100 polygon, .Color-icon-neutral-100 circle, .Color-icon-neutral-100 {
  fill: var(--color-neutral-100);
}

.Color-icon-neutral-200 path, .Color-icon-neutral-200 rect, .Color-icon-neutral-200 polygon, .Color-icon-neutral-200 circle, .Color-icon-neutral-200 {
  fill: var(--color-neutral-200);
}

.Color-icon-neutral-300 path, .Color-icon-neutral-300 rect, .Color-icon-neutral-300 polygon, .Color-icon-neutral-300 circle, .Color-icon-neutral-300 {
  fill: var(--color-neutral-300);
}

.Color-icon-neutral-400 path, .Color-icon-neutral-400 rect, .Color-icon-neutral-400 polygon, .Color-icon-neutral-400 circle, .Color-icon-neutral-400 {
  fill: var(--color-neutral-400);
}

.Color-icon-neutral-500 path, .Color-icon-neutral-500 rect, .Color-icon-neutral-500 polygon, .Color-icon-neutral-500 circle, .Color-icon-neutral-500 {
  fill: var(--color-neutral-500);
}

.Color-icon-neutral-600 path, .Color-icon-neutral-600 rect, .Color-icon-neutral-600 polygon, .Color-icon-neutral-600 circle, .Color-icon-neutral-600 {
  fill: var(--color-neutral-600);
}

.Color-icon-neutral-700 path, .Color-icon-neutral-700 rect, .Color-icon-neutral-700 polygon, .Color-icon-neutral-700 circle, .Color-icon-neutral-700 {
  fill: var(--color-neutral-700);
}

.Color-icon-neutral-800 path, .Color-icon-neutral-800 rect, .Color-icon-neutral-800 polygon, .Color-icon-neutral-800 circle, .Color-icon-neutral-800 {
  fill: var(--color-neutral-800);
}

.Color-icon-neutral-900 path, .Color-icon-neutral-900 rect, .Color-icon-neutral-900 polygon, .Color-icon-neutral-900 circle, .Color-icon-neutral-900 {
  fill: var(--color-neutral-900);
}

.Color-icon-neutral-1000 path, .Color-icon-neutral-1000 rect, .Color-icon-neutral-1000 polygon, .Color-icon-neutral-1000 circle, .Color-icon-neutral-1000 {
  fill: var(--color-neutral-1000);
}

.Color-icon-neutral-1100 path, .Color-icon-neutral-1100 rect, .Color-icon-neutral-1100 polygon, .Color-icon-neutral-1100 circle, .Color-icon-neutral-1100 {
  fill: var(--color-neutral-1100);
}

.Color-icon-neutral-000 path, .Color-icon-neutral-000 rect, .Color-icon-neutral-000 polygon, .Color-icon-neutral-000 circle, .Color-icon-neutral-000 {
  fill: var(--color-neutral-000);
}

.Color-icon-indigo-50 path, .Color-icon-indigo-50 rect, .Color-icon-indigo-50 polygon, .Color-icon-indigo-50 circle, .Color-icon-indigo-50 {
  fill: var(--color-indigo-50);
}

.Color-icon-indigo-100 path, .Color-icon-indigo-100 rect, .Color-icon-indigo-100 polygon, .Color-icon-indigo-100 circle, .Color-icon-indigo-100 {
  fill: var(--color-indigo-100);
}

.Color-icon-indigo-200 path, .Color-icon-indigo-200 rect, .Color-icon-indigo-200 polygon, .Color-icon-indigo-200 circle, .Color-icon-indigo-200 {
  fill: var(--color-indigo-200);
}

.Color-icon-indigo-300 path, .Color-icon-indigo-300 rect, .Color-icon-indigo-300 polygon, .Color-icon-indigo-300 circle, .Color-icon-indigo-300 {
  fill: var(--color-indigo-300);
}

.Color-icon-indigo-400 path, .Color-icon-indigo-400 rect, .Color-icon-indigo-400 polygon, .Color-icon-indigo-400 circle, .Color-icon-indigo-400 {
  fill: var(--color-indigo-400);
}

.Color-icon-indigo-500 path, .Color-icon-indigo-500 rect, .Color-icon-indigo-500 polygon, .Color-icon-indigo-500 circle, .Color-icon-indigo-500 {
  fill: var(--color-indigo-500);
}

.Color-icon-indigo-600 path, .Color-icon-indigo-600 rect, .Color-icon-indigo-600 polygon, .Color-icon-indigo-600 circle, .Color-icon-indigo-600 {
  fill: var(--color-indigo-600);
}

.Color-icon-indigo-700 path, .Color-icon-indigo-700 rect, .Color-icon-indigo-700 polygon, .Color-icon-indigo-700 circle, .Color-icon-indigo-700 {
  fill: var(--color-indigo-700);
}

.Color-icon-indigo-800 path, .Color-icon-indigo-800 rect, .Color-icon-indigo-800 polygon, .Color-icon-indigo-800 circle, .Color-icon-indigo-800 {
  fill: var(--color-indigo-800);
}

.Color-icon-indigo-900 path, .Color-icon-indigo-900 rect, .Color-icon-indigo-900 polygon, .Color-icon-indigo-900 circle, .Color-icon-indigo-900 {
  fill: var(--color-indigo-900);
}

.Color-icon-indigo-1000 path, .Color-icon-indigo-1000 rect, .Color-icon-indigo-1000 polygon, .Color-icon-indigo-1000 circle, .Color-icon-indigo-1000 {
  fill: var(--color-indigo-1000);
}

.Color-icon-indigo-1100 path, .Color-icon-indigo-1100 rect, .Color-icon-indigo-1100 polygon, .Color-icon-indigo-1100 circle, .Color-icon-indigo-1100 {
  fill: var(--color-indigo-1100);
}

.Color-icon-navy-50 path, .Color-icon-navy-50 rect, .Color-icon-navy-50 polygon, .Color-icon-navy-50 circle, .Color-icon-navy-50 {
  fill: var(--color-navy-50);
}

.Color-icon-navy-100 path, .Color-icon-navy-100 rect, .Color-icon-navy-100 polygon, .Color-icon-navy-100 circle, .Color-icon-navy-100 {
  fill: var(--color-navy-100);
}

.Color-icon-navy-200 path, .Color-icon-navy-200 rect, .Color-icon-navy-200 polygon, .Color-icon-navy-200 circle, .Color-icon-navy-200 {
  fill: var(--color-navy-200);
}

.Color-icon-navy-300 path, .Color-icon-navy-300 rect, .Color-icon-navy-300 polygon, .Color-icon-navy-300 circle, .Color-icon-navy-300 {
  fill: var(--color-navy-300);
}

.Color-icon-navy-400 path, .Color-icon-navy-400 rect, .Color-icon-navy-400 polygon, .Color-icon-navy-400 circle, .Color-icon-navy-400 {
  fill: var(--color-navy-400);
}

.Color-icon-navy-500 path, .Color-icon-navy-500 rect, .Color-icon-navy-500 polygon, .Color-icon-navy-500 circle, .Color-icon-navy-500 {
  fill: var(--color-navy-500);
}

.Color-icon-navy-600 path, .Color-icon-navy-600 rect, .Color-icon-navy-600 polygon, .Color-icon-navy-600 circle, .Color-icon-navy-600 {
  fill: var(--color-navy-600);
}

.Color-icon-navy-700 path, .Color-icon-navy-700 rect, .Color-icon-navy-700 polygon, .Color-icon-navy-700 circle, .Color-icon-navy-700 {
  fill: var(--color-navy-700);
}

.Color-icon-navy-800 path, .Color-icon-navy-800 rect, .Color-icon-navy-800 polygon, .Color-icon-navy-800 circle, .Color-icon-navy-800 {
  fill: var(--color-navy-800);
}

.Color-icon-navy-900 path, .Color-icon-navy-900 rect, .Color-icon-navy-900 polygon, .Color-icon-navy-900 circle, .Color-icon-navy-900 {
  fill: var(--color-navy-900);
}

.Color-icon-navy-1000 path, .Color-icon-navy-1000 rect, .Color-icon-navy-1000 polygon, .Color-icon-navy-1000 circle, .Color-icon-navy-1000 {
  fill: var(--color-navy-1000);
}

.Color-icon-navy-1100 path, .Color-icon-navy-1100 rect, .Color-icon-navy-1100 polygon, .Color-icon-navy-1100 circle, .Color-icon-navy-1100 {
  fill: var(--color-navy-1100);
}

.Color-icon-critical-50 path, .Color-icon-critical-50 rect, .Color-icon-critical-50 polygon, .Color-icon-critical-50 circle, .Color-icon-critical-50 {
  fill: var(--color-critical-50);
}

.Color-icon-critical-100 path, .Color-icon-critical-100 rect, .Color-icon-critical-100 polygon, .Color-icon-critical-100 circle, .Color-icon-critical-100 {
  fill: var(--color-critical-100);
}

.Color-icon-critical-200 path, .Color-icon-critical-200 rect, .Color-icon-critical-200 polygon, .Color-icon-critical-200 circle, .Color-icon-critical-200 {
  fill: var(--color-critical-200);
}

.Color-icon-critical-300 path, .Color-icon-critical-300 rect, .Color-icon-critical-300 polygon, .Color-icon-critical-300 circle, .Color-icon-critical-300 {
  fill: var(--color-critical-300);
}

.Color-icon-critical-400 path, .Color-icon-critical-400 rect, .Color-icon-critical-400 polygon, .Color-icon-critical-400 circle, .Color-icon-critical-400 {
  fill: var(--color-critical-400);
}

.Color-icon-critical-500 path, .Color-icon-critical-500 rect, .Color-icon-critical-500 polygon, .Color-icon-critical-500 circle, .Color-icon-critical-500 {
  fill: var(--color-critical-500);
}

.Color-icon-critical-600 path, .Color-icon-critical-600 rect, .Color-icon-critical-600 polygon, .Color-icon-critical-600 circle, .Color-icon-critical-600 {
  fill: var(--color-critical-600);
}

.Color-icon-critical-700 path, .Color-icon-critical-700 rect, .Color-icon-critical-700 polygon, .Color-icon-critical-700 circle, .Color-icon-critical-700 {
  fill: var(--color-critical-700);
}

.Color-icon-critical-800 path, .Color-icon-critical-800 rect, .Color-icon-critical-800 polygon, .Color-icon-critical-800 circle, .Color-icon-critical-800 {
  fill: var(--color-critical-800);
}

.Color-icon-critical-900 path, .Color-icon-critical-900 rect, .Color-icon-critical-900 polygon, .Color-icon-critical-900 circle, .Color-icon-critical-900 {
  fill: var(--color-critical-900);
}

.Color-icon-critical-1000 path, .Color-icon-critical-1000 rect, .Color-icon-critical-1000 polygon, .Color-icon-critical-1000 circle, .Color-icon-critical-1000 {
  fill: var(--color-critical-1000);
}

.Color-icon-critical-1100 path, .Color-icon-critical-1100 rect, .Color-icon-critical-1100 polygon, .Color-icon-critical-1100 circle, .Color-icon-critical-1100 {
  fill: var(--color-critical-1100);
}

.Color-icon-success-50 path, .Color-icon-success-50 rect, .Color-icon-success-50 polygon, .Color-icon-success-50 circle, .Color-icon-success-50 {
  fill: var(--color-success-50);
}

.Color-icon-success-100 path, .Color-icon-success-100 rect, .Color-icon-success-100 polygon, .Color-icon-success-100 circle, .Color-icon-success-100 {
  fill: var(--color-success-100);
}

.Color-icon-success-200 path, .Color-icon-success-200 rect, .Color-icon-success-200 polygon, .Color-icon-success-200 circle, .Color-icon-success-200 {
  fill: var(--color-success-200);
}

.Color-icon-success-300 path, .Color-icon-success-300 rect, .Color-icon-success-300 polygon, .Color-icon-success-300 circle, .Color-icon-success-300 {
  fill: var(--color-success-300);
}

.Color-icon-success-400 path, .Color-icon-success-400 rect, .Color-icon-success-400 polygon, .Color-icon-success-400 circle, .Color-icon-success-400 {
  fill: var(--color-success-400);
}

.Color-icon-success-500 path, .Color-icon-success-500 rect, .Color-icon-success-500 polygon, .Color-icon-success-500 circle, .Color-icon-success-500 {
  fill: var(--color-success-500);
}

.Color-icon-success-600 path, .Color-icon-success-600 rect, .Color-icon-success-600 polygon, .Color-icon-success-600 circle, .Color-icon-success-600 {
  fill: var(--color-success-600);
}

.Color-icon-success-700 path, .Color-icon-success-700 rect, .Color-icon-success-700 polygon, .Color-icon-success-700 circle, .Color-icon-success-700 {
  fill: var(--color-success-700);
}

.Color-icon-success-800 path, .Color-icon-success-800 rect, .Color-icon-success-800 polygon, .Color-icon-success-800 circle, .Color-icon-success-800 {
  fill: var(--color-success-800);
}

.Color-icon-success-900 path, .Color-icon-success-900 rect, .Color-icon-success-900 polygon, .Color-icon-success-900 circle, .Color-icon-success-900 {
  fill: var(--color-success-900);
}

.Color-icon-success-1000 path, .Color-icon-success-1000 rect, .Color-icon-success-1000 polygon, .Color-icon-success-1000 circle, .Color-icon-success-1000 {
  fill: var(--color-success-1000);
}

.Color-icon-success-1100 path, .Color-icon-success-1100 rect, .Color-icon-success-1100 polygon, .Color-icon-success-1100 circle, .Color-icon-success-1100 {
  fill: var(--color-success-1100);
}

.Color-icon-warning-50 path, .Color-icon-warning-50 rect, .Color-icon-warning-50 polygon, .Color-icon-warning-50 circle, .Color-icon-warning-50 {
  fill: var(--color-warning-50);
}

.Color-icon-warning-100 path, .Color-icon-warning-100 rect, .Color-icon-warning-100 polygon, .Color-icon-warning-100 circle, .Color-icon-warning-100 {
  fill: var(--color-warning-100);
}

.Color-icon-warning-200 path, .Color-icon-warning-200 rect, .Color-icon-warning-200 polygon, .Color-icon-warning-200 circle, .Color-icon-warning-200 {
  fill: var(--color-warning-200);
}

.Color-icon-warning-300 path, .Color-icon-warning-300 rect, .Color-icon-warning-300 polygon, .Color-icon-warning-300 circle, .Color-icon-warning-300 {
  fill: var(--color-warning-300);
}

.Color-icon-warning-400 path, .Color-icon-warning-400 rect, .Color-icon-warning-400 polygon, .Color-icon-warning-400 circle, .Color-icon-warning-400 {
  fill: var(--color-warning-400);
}

.Color-icon-warning-500 path, .Color-icon-warning-500 rect, .Color-icon-warning-500 polygon, .Color-icon-warning-500 circle, .Color-icon-warning-500 {
  fill: var(--color-warning-500);
}

.Color-icon-warning-600 path, .Color-icon-warning-600 rect, .Color-icon-warning-600 polygon, .Color-icon-warning-600 circle, .Color-icon-warning-600 {
  fill: var(--color-warning-600);
}

.Color-icon-warning-700 path, .Color-icon-warning-700 rect, .Color-icon-warning-700 polygon, .Color-icon-warning-700 circle, .Color-icon-warning-700 {
  fill: var(--color-warning-700);
}

.Color-icon-warning-800 path, .Color-icon-warning-800 rect, .Color-icon-warning-800 polygon, .Color-icon-warning-800 circle, .Color-icon-warning-800 {
  fill: var(--color-warning-800);
}

.Color-icon-warning-900 path, .Color-icon-warning-900 rect, .Color-icon-warning-900 polygon, .Color-icon-warning-900 circle, .Color-icon-warning-900 {
  fill: var(--color-warning-900);
}

.Color-icon-warning-1000 path, .Color-icon-warning-1000 rect, .Color-icon-warning-1000 polygon, .Color-icon-warning-1000 circle, .Color-icon-warning-1000 {
  fill: var(--color-warning-1000);
}

.Color-icon-warning-1100 path, .Color-icon-warning-1100 rect, .Color-icon-warning-1100 polygon, .Color-icon-warning-1100 circle, .Color-icon-warning-1100 {
  fill: var(--color-warning-1100);
}

.Color-icon-info-50 path, .Color-icon-info-50 rect, .Color-icon-info-50 polygon, .Color-icon-info-50 circle, .Color-icon-info-50 {
  fill: var(--color-info-50);
}

.Color-icon-info-100 path, .Color-icon-info-100 rect, .Color-icon-info-100 polygon, .Color-icon-info-100 circle, .Color-icon-info-100 {
  fill: var(--color-info-100);
}

.Color-icon-info-200 path, .Color-icon-info-200 rect, .Color-icon-info-200 polygon, .Color-icon-info-200 circle, .Color-icon-info-200 {
  fill: var(--color-info-200);
}

.Color-icon-info-300 path, .Color-icon-info-300 rect, .Color-icon-info-300 polygon, .Color-icon-info-300 circle, .Color-icon-info-300 {
  fill: var(--color-info-300);
}

.Color-icon-info-400 path, .Color-icon-info-400 rect, .Color-icon-info-400 polygon, .Color-icon-info-400 circle, .Color-icon-info-400 {
  fill: var(--color-info-400);
}

.Color-icon-info-500 path, .Color-icon-info-500 rect, .Color-icon-info-500 polygon, .Color-icon-info-500 circle, .Color-icon-info-500 {
  fill: var(--color-info-500);
}

.Color-icon-info-600 path, .Color-icon-info-600 rect, .Color-icon-info-600 polygon, .Color-icon-info-600 circle, .Color-icon-info-600 {
  fill: var(--color-info-600);
}

.Color-icon-info-700 path, .Color-icon-info-700 rect, .Color-icon-info-700 polygon, .Color-icon-info-700 circle, .Color-icon-info-700 {
  fill: var(--color-info-700);
}

.Color-icon-info-800 path, .Color-icon-info-800 rect, .Color-icon-info-800 polygon, .Color-icon-info-800 circle, .Color-icon-info-800 {
  fill: var(--color-info-800);
}

.Color-icon-info-900 path, .Color-icon-info-900 rect, .Color-icon-info-900 polygon, .Color-icon-info-900 circle, .Color-icon-info-900 {
  fill: var(--color-info-900);
}

.Color-icon-info-1000 path, .Color-icon-info-1000 rect, .Color-icon-info-1000 polygon, .Color-icon-info-1000 circle, .Color-icon-info-1000 {
  fill: var(--color-info-1000);
}

.Color-icon-info-1100 path, .Color-icon-info-1100 rect, .Color-icon-info-1100 polygon, .Color-icon-info-1100 circle, .Color-icon-info-1100 {
  fill: var(--color-info-1100);
}

.Color-icon-teal-50 path, .Color-icon-teal-50 rect, .Color-icon-teal-50 polygon, .Color-icon-teal-50 circle, .Color-icon-teal-50 {
  fill: var(--color-teal-50);
}

.Color-icon-teal-100 path, .Color-icon-teal-100 rect, .Color-icon-teal-100 polygon, .Color-icon-teal-100 circle, .Color-icon-teal-100 {
  fill: var(--color-teal-100);
}

.Color-icon-teal-200 path, .Color-icon-teal-200 rect, .Color-icon-teal-200 polygon, .Color-icon-teal-200 circle, .Color-icon-teal-200 {
  fill: var(--color-teal-200);
}

.Color-icon-teal-300 path, .Color-icon-teal-300 rect, .Color-icon-teal-300 polygon, .Color-icon-teal-300 circle, .Color-icon-teal-300 {
  fill: var(--color-teal-300);
}

.Color-icon-teal-400 path, .Color-icon-teal-400 rect, .Color-icon-teal-400 polygon, .Color-icon-teal-400 circle, .Color-icon-teal-400 {
  fill: var(--color-teal-400);
}

.Color-icon-teal-500 path, .Color-icon-teal-500 rect, .Color-icon-teal-500 polygon, .Color-icon-teal-500 circle, .Color-icon-teal-500 {
  fill: var(--color-teal-500);
}

.Color-icon-teal-600 path, .Color-icon-teal-600 rect, .Color-icon-teal-600 polygon, .Color-icon-teal-600 circle, .Color-icon-teal-600 {
  fill: var(--color-teal-600);
}

.Color-icon-teal-700 path, .Color-icon-teal-700 rect, .Color-icon-teal-700 polygon, .Color-icon-teal-700 circle, .Color-icon-teal-700 {
  fill: var(--color-teal-700);
}

.Color-icon-teal-800 path, .Color-icon-teal-800 rect, .Color-icon-teal-800 polygon, .Color-icon-teal-800 circle, .Color-icon-teal-800 {
  fill: var(--color-teal-800);
}

.Color-icon-teal-900 path, .Color-icon-teal-900 rect, .Color-icon-teal-900 polygon, .Color-icon-teal-900 circle, .Color-icon-teal-900 {
  fill: var(--color-teal-900);
}

.Color-icon-teal-1000 path, .Color-icon-teal-1000 rect, .Color-icon-teal-1000 polygon, .Color-icon-teal-1000 circle, .Color-icon-teal-1000 {
  fill: var(--color-teal-1000);
}

.Color-icon-teal-1100 path, .Color-icon-teal-1100 rect, .Color-icon-teal-1100 polygon, .Color-icon-teal-1100 circle, .Color-icon-teal-1100 {
  fill: var(--color-teal-1100);
}

.Color-icon-pink-50 path, .Color-icon-pink-50 rect, .Color-icon-pink-50 polygon, .Color-icon-pink-50 circle, .Color-icon-pink-50 {
  fill: var(--color-pink-50);
}

.Color-icon-pink-100 path, .Color-icon-pink-100 rect, .Color-icon-pink-100 polygon, .Color-icon-pink-100 circle, .Color-icon-pink-100 {
  fill: var(--color-pink-100);
}

.Color-icon-pink-200 path, .Color-icon-pink-200 rect, .Color-icon-pink-200 polygon, .Color-icon-pink-200 circle, .Color-icon-pink-200 {
  fill: var(--color-pink-200);
}

.Color-icon-pink-300 path, .Color-icon-pink-300 rect, .Color-icon-pink-300 polygon, .Color-icon-pink-300 circle, .Color-icon-pink-300 {
  fill: var(--color-pink-300);
}

.Color-icon-pink-400 path, .Color-icon-pink-400 rect, .Color-icon-pink-400 polygon, .Color-icon-pink-400 circle, .Color-icon-pink-400 {
  fill: var(--color-pink-400);
}

.Color-icon-pink-500 path, .Color-icon-pink-500 rect, .Color-icon-pink-500 polygon, .Color-icon-pink-500 circle, .Color-icon-pink-500 {
  fill: var(--color-pink-500);
}

.Color-icon-pink-600 path, .Color-icon-pink-600 rect, .Color-icon-pink-600 polygon, .Color-icon-pink-600 circle, .Color-icon-pink-600 {
  fill: var(--color-pink-600);
}

.Color-icon-pink-700 path, .Color-icon-pink-700 rect, .Color-icon-pink-700 polygon, .Color-icon-pink-700 circle, .Color-icon-pink-700 {
  fill: var(--color-pink-700);
}

.Color-icon-pink-800 path, .Color-icon-pink-800 rect, .Color-icon-pink-800 polygon, .Color-icon-pink-800 circle, .Color-icon-pink-800 {
  fill: var(--color-pink-800);
}

.Color-icon-pink-900 path, .Color-icon-pink-900 rect, .Color-icon-pink-900 polygon, .Color-icon-pink-900 circle, .Color-icon-pink-900 {
  fill: var(--color-pink-900);
}

.Color-icon-pink-1000 path, .Color-icon-pink-1000 rect, .Color-icon-pink-1000 polygon, .Color-icon-pink-1000 circle, .Color-icon-pink-1000 {
  fill: var(--color-pink-1000);
}

.Color-icon-pink-1100 path, .Color-icon-pink-1100 rect, .Color-icon-pink-1100 polygon, .Color-icon-pink-1100 circle, .Color-icon-pink-1100 {
  fill: var(--color-pink-1100);
}

.Color-icon-orange-50 path, .Color-icon-orange-50 rect, .Color-icon-orange-50 polygon, .Color-icon-orange-50 circle, .Color-icon-orange-50 {
  fill: var(--color-orange-50);
}

.Color-icon-orange-100 path, .Color-icon-orange-100 rect, .Color-icon-orange-100 polygon, .Color-icon-orange-100 circle, .Color-icon-orange-100 {
  fill: var(--color-orange-100);
}

.Color-icon-orange-200 path, .Color-icon-orange-200 rect, .Color-icon-orange-200 polygon, .Color-icon-orange-200 circle, .Color-icon-orange-200 {
  fill: var(--color-orange-200);
}

.Color-icon-orange-300 path, .Color-icon-orange-300 rect, .Color-icon-orange-300 polygon, .Color-icon-orange-300 circle, .Color-icon-orange-300 {
  fill: var(--color-orange-300);
}

.Color-icon-orange-400 path, .Color-icon-orange-400 rect, .Color-icon-orange-400 polygon, .Color-icon-orange-400 circle, .Color-icon-orange-400 {
  fill: var(--color-orange-400);
}

.Color-icon-orange-500 path, .Color-icon-orange-500 rect, .Color-icon-orange-500 polygon, .Color-icon-orange-500 circle, .Color-icon-orange-500 {
  fill: var(--color-orange-500);
}

.Color-icon-orange-600 path, .Color-icon-orange-600 rect, .Color-icon-orange-600 polygon, .Color-icon-orange-600 circle, .Color-icon-orange-600 {
  fill: var(--color-orange-600);
}

.Color-icon-orange-700 path, .Color-icon-orange-700 rect, .Color-icon-orange-700 polygon, .Color-icon-orange-700 circle, .Color-icon-orange-700 {
  fill: var(--color-orange-700);
}

.Color-icon-orange-800 path, .Color-icon-orange-800 rect, .Color-icon-orange-800 polygon, .Color-icon-orange-800 circle, .Color-icon-orange-800 {
  fill: var(--color-orange-800);
}

.Color-icon-orange-900 path, .Color-icon-orange-900 rect, .Color-icon-orange-900 polygon, .Color-icon-orange-900 circle, .Color-icon-orange-900 {
  fill: var(--color-orange-900);
}

.Color-icon-orange-1000 path, .Color-icon-orange-1000 rect, .Color-icon-orange-1000 polygon, .Color-icon-orange-1000 circle, .Color-icon-orange-1000 {
  fill: var(--color-orange-1000);
}

.Color-icon-orange-1100 path, .Color-icon-orange-1100 rect, .Color-icon-orange-1100 polygon, .Color-icon-orange-1100 circle, .Color-icon-orange-1100 {
  fill: var(--color-orange-1100);
}

.Color-icon-purple-50 path, .Color-icon-purple-50 rect, .Color-icon-purple-50 polygon, .Color-icon-purple-50 circle, .Color-icon-purple-50 {
  fill: var(--color-purple-50);
}

.Color-icon-purple-100 path, .Color-icon-purple-100 rect, .Color-icon-purple-100 polygon, .Color-icon-purple-100 circle, .Color-icon-purple-100 {
  fill: var(--color-purple-100);
}

.Color-icon-purple-200 path, .Color-icon-purple-200 rect, .Color-icon-purple-200 polygon, .Color-icon-purple-200 circle, .Color-icon-purple-200 {
  fill: var(--color-purple-200);
}

.Color-icon-purple-300 path, .Color-icon-purple-300 rect, .Color-icon-purple-300 polygon, .Color-icon-purple-300 circle, .Color-icon-purple-300 {
  fill: var(--color-purple-300);
}

.Color-icon-purple-400 path, .Color-icon-purple-400 rect, .Color-icon-purple-400 polygon, .Color-icon-purple-400 circle, .Color-icon-purple-400 {
  fill: var(--color-purple-400);
}

.Color-icon-purple-500 path, .Color-icon-purple-500 rect, .Color-icon-purple-500 polygon, .Color-icon-purple-500 circle, .Color-icon-purple-500 {
  fill: var(--color-purple-500);
}

.Color-icon-purple-600 path, .Color-icon-purple-600 rect, .Color-icon-purple-600 polygon, .Color-icon-purple-600 circle, .Color-icon-purple-600 {
  fill: var(--color-purple-600);
}

.Color-icon-purple-700 path, .Color-icon-purple-700 rect, .Color-icon-purple-700 polygon, .Color-icon-purple-700 circle, .Color-icon-purple-700 {
  fill: var(--color-purple-700);
}

.Color-icon-purple-800 path, .Color-icon-purple-800 rect, .Color-icon-purple-800 polygon, .Color-icon-purple-800 circle, .Color-icon-purple-800 {
  fill: var(--color-purple-800);
}

.Color-icon-purple-900 path, .Color-icon-purple-900 rect, .Color-icon-purple-900 polygon, .Color-icon-purple-900 circle, .Color-icon-purple-900 {
  fill: var(--color-purple-900);
}

.Color-icon-purple-1000 path, .Color-icon-purple-1000 rect, .Color-icon-purple-1000 polygon, .Color-icon-purple-1000 circle, .Color-icon-purple-1000 {
  fill: var(--color-purple-1000);
}

.Color-icon-purple-1100 path, .Color-icon-purple-1100 rect, .Color-icon-purple-1100 polygon, .Color-icon-purple-1100 circle, .Color-icon-purple-1100 {
  fill: var(--color-purple-1100);
}

.Color-icon-green-50 path, .Color-icon-green-50 rect, .Color-icon-green-50 polygon, .Color-icon-green-50 circle, .Color-icon-green-50 {
  fill: var(--color-green-50);
}

.Color-icon-green-100 path, .Color-icon-green-100 rect, .Color-icon-green-100 polygon, .Color-icon-green-100 circle, .Color-icon-green-100 {
  fill: var(--color-green-100);
}

.Color-icon-green-200 path, .Color-icon-green-200 rect, .Color-icon-green-200 polygon, .Color-icon-green-200 circle, .Color-icon-green-200 {
  fill: var(--color-green-200);
}

.Color-icon-green-300 path, .Color-icon-green-300 rect, .Color-icon-green-300 polygon, .Color-icon-green-300 circle, .Color-icon-green-300 {
  fill: var(--color-green-300);
}

.Color-icon-green-400 path, .Color-icon-green-400 rect, .Color-icon-green-400 polygon, .Color-icon-green-400 circle, .Color-icon-green-400 {
  fill: var(--color-green-400);
}

.Color-icon-green-500 path, .Color-icon-green-500 rect, .Color-icon-green-500 polygon, .Color-icon-green-500 circle, .Color-icon-green-500 {
  fill: var(--color-green-500);
}

.Color-icon-green-600 path, .Color-icon-green-600 rect, .Color-icon-green-600 polygon, .Color-icon-green-600 circle, .Color-icon-green-600 {
  fill: var(--color-green-600);
}

.Color-icon-green-700 path, .Color-icon-green-700 rect, .Color-icon-green-700 polygon, .Color-icon-green-700 circle, .Color-icon-green-700 {
  fill: var(--color-green-700);
}

.Color-icon-green-800 path, .Color-icon-green-800 rect, .Color-icon-green-800 polygon, .Color-icon-green-800 circle, .Color-icon-green-800 {
  fill: var(--color-green-800);
}

.Color-icon-green-900 path, .Color-icon-green-900 rect, .Color-icon-green-900 polygon, .Color-icon-green-900 circle, .Color-icon-green-900 {
  fill: var(--color-green-900);
}

.Color-icon-green-1000 path, .Color-icon-green-1000 rect, .Color-icon-green-1000 polygon, .Color-icon-green-1000 circle, .Color-icon-green-1000 {
  fill: var(--color-green-1000);
}

.Color-icon-green-1100 path, .Color-icon-green-1100 rect, .Color-icon-green-1100 polygon, .Color-icon-green-1100 circle, .Color-icon-green-1100 {
  fill: var(--color-green-1100);
}

.Color-icon-coral-50 path, .Color-icon-coral-50 rect, .Color-icon-coral-50 polygon, .Color-icon-coral-50 circle, .Color-icon-coral-50 {
  fill: var(--color-coral-50);
}

.Color-icon-coral-100 path, .Color-icon-coral-100 rect, .Color-icon-coral-100 polygon, .Color-icon-coral-100 circle, .Color-icon-coral-100 {
  fill: var(--color-coral-100);
}

.Color-icon-coral-200 path, .Color-icon-coral-200 rect, .Color-icon-coral-200 polygon, .Color-icon-coral-200 circle, .Color-icon-coral-200 {
  fill: var(--color-coral-200);
}

.Color-icon-coral-300 path, .Color-icon-coral-300 rect, .Color-icon-coral-300 polygon, .Color-icon-coral-300 circle, .Color-icon-coral-300 {
  fill: var(--color-coral-300);
}

.Color-icon-coral-400 path, .Color-icon-coral-400 rect, .Color-icon-coral-400 polygon, .Color-icon-coral-400 circle, .Color-icon-coral-400 {
  fill: var(--color-coral-400);
}

.Color-icon-coral-500 path, .Color-icon-coral-500 rect, .Color-icon-coral-500 polygon, .Color-icon-coral-500 circle, .Color-icon-coral-500 {
  fill: var(--color-coral-500);
}

.Color-icon-coral-600 path, .Color-icon-coral-600 rect, .Color-icon-coral-600 polygon, .Color-icon-coral-600 circle, .Color-icon-coral-600 {
  fill: var(--color-coral-600);
}

.Color-icon-coral-700 path, .Color-icon-coral-700 rect, .Color-icon-coral-700 polygon, .Color-icon-coral-700 circle, .Color-icon-coral-700 {
  fill: var(--color-coral-700);
}

.Color-icon-coral-800 path, .Color-icon-coral-800 rect, .Color-icon-coral-800 polygon, .Color-icon-coral-800 circle, .Color-icon-coral-800 {
  fill: var(--color-coral-800);
}

.Color-icon-coral-900 path, .Color-icon-coral-900 rect, .Color-icon-coral-900 polygon, .Color-icon-coral-900 circle, .Color-icon-coral-900 {
  fill: var(--color-coral-900);
}

.Color-icon-coral-1000 path, .Color-icon-coral-1000 rect, .Color-icon-coral-1000 polygon, .Color-icon-coral-1000 circle, .Color-icon-coral-1000 {
  fill: var(--color-coral-1000);
}

.Color-icon-coral-1100 path, .Color-icon-coral-1100 rect, .Color-icon-coral-1100 polygon, .Color-icon-coral-1100 circle, .Color-icon-coral-1100 {
  fill: var(--color-coral-1100);
}

.Color-icon-blue-50 path, .Color-icon-blue-50 rect, .Color-icon-blue-50 polygon, .Color-icon-blue-50 circle, .Color-icon-blue-50 {
  fill: var(--color-blue-50);
}

.Color-icon-blue-100 path, .Color-icon-blue-100 rect, .Color-icon-blue-100 polygon, .Color-icon-blue-100 circle, .Color-icon-blue-100 {
  fill: var(--color-blue-100);
}

.Color-icon-blue-200 path, .Color-icon-blue-200 rect, .Color-icon-blue-200 polygon, .Color-icon-blue-200 circle, .Color-icon-blue-200 {
  fill: var(--color-blue-200);
}

.Color-icon-blue-300 path, .Color-icon-blue-300 rect, .Color-icon-blue-300 polygon, .Color-icon-blue-300 circle, .Color-icon-blue-300 {
  fill: var(--color-blue-300);
}

.Color-icon-blue-400 path, .Color-icon-blue-400 rect, .Color-icon-blue-400 polygon, .Color-icon-blue-400 circle, .Color-icon-blue-400 {
  fill: var(--color-blue-400);
}

.Color-icon-blue-500 path, .Color-icon-blue-500 rect, .Color-icon-blue-500 polygon, .Color-icon-blue-500 circle, .Color-icon-blue-500 {
  fill: var(--color-blue-500);
}

.Color-icon-blue-600 path, .Color-icon-blue-600 rect, .Color-icon-blue-600 polygon, .Color-icon-blue-600 circle, .Color-icon-blue-600 {
  fill: var(--color-blue-600);
}

.Color-icon-blue-700 path, .Color-icon-blue-700 rect, .Color-icon-blue-700 polygon, .Color-icon-blue-700 circle, .Color-icon-blue-700 {
  fill: var(--color-blue-700);
}

.Color-icon-blue-800 path, .Color-icon-blue-800 rect, .Color-icon-blue-800 polygon, .Color-icon-blue-800 circle, .Color-icon-blue-800 {
  fill: var(--color-blue-800);
}

.Color-icon-blue-900 path, .Color-icon-blue-900 rect, .Color-icon-blue-900 polygon, .Color-icon-blue-900 circle, .Color-icon-blue-900 {
  fill: var(--color-blue-900);
}

.Color-icon-blue-1000 path, .Color-icon-blue-1000 rect, .Color-icon-blue-1000 polygon, .Color-icon-blue-1000 circle, .Color-icon-blue-1000 {
  fill: var(--color-blue-1000);
}

.Color-icon-blue-1100 path, .Color-icon-blue-1100 rect, .Color-icon-blue-1100 polygon, .Color-icon-blue-1100 circle, .Color-icon-blue-1100 {
  fill: var(--color-blue-1100);
}



.Icon {
  display: inherit;
}

:root {
  --icon-size-xx-xxxsmall: .5rem;
  --icon-size-x-xxxsmall: .625rem;
  --icon-size-xxxsmall: .75rem;
  --icon-size-xxsmall: .875rem;
  --icon-size-xsmall: 1rem;
  --icon-size-small: 1.125rem;
  --icon-size-small-medium: 1.25rem;
  --icon-size-medium: 1.5rem;
  --icon-size-large: 2rem;
  --icon-size-xlarge: 2.75rem;
  --icon-size-xxlarge: 3rem;
}

.Icon {
  transition: transform .2s ease-in-out;
  display: inline-block;
}

.Icon svg {
  width: 100%;
  height: 100%;
}

.Icon--xx-xxxSmall {
  height: var(--icon-size-xx-xxxsmall);
  width: var(--icon-size-xx-xxxsmall);
  min-width: var(--icon-size-xx-xxxsmall);
  min-height: unset;
}

.Icon--x-xxxSmall {
  height: var(--icon-size-x-xxxsmall);
  width: var(--icon-size-x-xxxsmall);
  min-width: var(--icon-size-x-xxxsmall);
  min-height: unset;
}

.Icon--xxxSmall {
  height: var(--icon-size-xxxsmall);
  width: var(--icon-size-xxxsmall);
  min-width: var(--icon-size-xxxsmall);
  min-height: unset;
}

.Icon--xxSmall {
  height: var(--icon-size-xxsmall);
  width: var(--icon-size-xxsmall);
  min-width: var(--icon-size-xxsmall);
  min-height: unset;
}

.Icon--xSmall {
  height: var(--icon-size-xsmall);
  width: var(--icon-size-xsmall);
  min-width: var(--icon-size-xsmall);
  min-height: unset;
}

.Icon--small {
  height: var(--icon-size-small);
  width: var(--icon-size-small);
  min-width: var(--icon-size-small);
  min-height: unset;
}

.Icon--smallMedium {
  height: var(--icon-size-small-medium);
  width: var(--icon-size-small-medium);
  min-width: var(--icon-size-small-medium);
  min-height: unset;
}

.Icon--medium {
  height: var(--icon-size-medium);
  width: var(--icon-size-medium);
  min-width: var(--icon-size-medium);
  min-height: unset;
}

.Icon--large {
  height: var(--icon-size-large);
  width: var(--icon-size-large);
  min-width: var(--icon-size-large);
  min-height: unset;
}

.Icon--xLarge {
  height: var(--icon-size-xlarge);
  width: var(--icon-size-xlarge);
  min-width: var(--icon-size-xlarge);
  min-height: unset;
}

.Icon--xxLarge {
  height: var(--icon-size-xxlarge);
  width: var(--icon-size-xxlarge);
  min-width: var(--icon-size-xxlarge);
  min-height: unset;
}

.Icon--rotate-90 {
  transform: rotate(90deg);
}

.Icon--rotate-180 {
  transform: rotate(180deg);
}

.Icon--rotate-270 {
  transform: rotate(270deg);
}

.AccordionStepper-inlineBox {
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  display: flex;
}

.AccordionStepper-inlineBox .InlineMessage--critical {
  padding: 0;
}

.AccordionStepper-article {
  border-radius: var(--border-radius-sm);
  border: 1px solid #0000;
  width: 100%;
  min-height: 4.875rem;
  position: relative;
}

.AccordionStepper-article--hasSecondaryText {
  min-height: 6.5rem;
}

.AccordionStepper-article--isCritical {
  border: 1px solid var(--color-foreground-critical-medium, #cc1c10);
}

.AccordionStepper-button {
  all: unset;
  box-sizing: border-box;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  z-index: var(--z-index-accordion-button);
  align-items: center;
  gap: .5rem;
  width: 100%;
  height: 100%;
  max-height: 4.875rem;
  padding: 1rem 1.5rem;
  transition: all .25s ease-in-out;
  display: flex;
  position: absolute;
}

.AccordionStepper-button:hover {
  box-shadow: var(--box-shadow-pretoken-container-interaction);
}

.AccordionStepper-button.AccordionStepper-button--hasSecondaryText {
  height: 6.5rem;
  max-height: 6.5rem;
}

.AccordionStepper-button[tabindex]:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  outline-offset: .125rem;
}

.AccordionStepper-button--isExpanded {
  border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
  border-bottom: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.AccordionStepper-button--hasSecondaryText.AccordionStepper-button--isExpanded {
  max-height: 4.875rem;
}

.AccordionStepper-prefixIcon {
  justify-content: center;
  align-self: flex-start;
  width: 1.5rem;
  transition: height .25s ease-in-out, width .25s ease-in-out, transform .25s ease-in-out;
  display: flex;
}

.AccordionStepper-prefixIcon .Icon {
  transition: height .25s ease-in-out, width .25s ease-in-out;
}

.AccordionStepper-prefixIcon .Icon svg path {
  fill: var(--color-foreground-neutral-bold, #212328);
}

.AccordionStepper-prefixIcon--isExpanded {
  width: 2rem;
  transform: translateY(15%);
}

.AccordionStepper-prefixIcon--isExpanded .Icon {
  max-width: unset;
  max-height: unset;
  width: 2rem;
  height: 2rem;
}

.AccordionStepper-bodyContent {
  flex-direction: column;
  gap: .125rem;
  width: 100%;
  transition: gap .25s ease-in-out, height .25s ease-in-out;
  display: flex;
}

.AccordionStepper-bodyContent--isExpanded {
  gap: 0;
}

.AccordionStepper-header {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-bold, #212328);
  margin: 0;
  transition: font .25s ease-in-out, height .25s ease-in-out;
}

.AccordionStepper-header--isExpanded {
  font: var(--typography-heading-2);
}

.AccordionStepper-supportingContent {
  flex-direction: column;
  gap: .125rem;
  transition: gap .25s ease-in-out, height .25s ease-in-out;
  display: flex;
  overflow: hidden;
}

.AccordionStepper-supportingContent--isShowingSupportingTextOnExpanded {
  height: 1.5rem;
}

.AccordionStepper-supportingContent--hasSecondaryText {
  height: 3rem;
}

.AccordionStepper-supportingContent--isShowingSupportingTextOnExpanded.AccordionStepper-supportingContent--isExpanded {
  gap: .125rem;
  height: 0;
}

.AccordionStepper-supportingText, .AccordionStepper-accordionValue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.AccordionStepper-supportingText {
  font: var(--typography-body-1);
  color: var(--color-foreground-neutral-subtle, #717385);
  margin: 0;
}

.AccordionStepper-accordionValue {
  font: var(--typography-body-bold-1);
  color: var(--color-foreground-secondary-bold, #00245b);
}

.AccordionStepper-suffixIcon {
  justify-content: center;
  align-self: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: .5rem;
  display: flex;
}

.AccordionStepper-suffixIcon .Icon svg path {
  fill: var(--color-foreground-secondary-bold, #00245b);
}

.AccordionStepper-contentPanelWrapper {
  border-radius: var(--border-radius-sm);
  background: var(--color-background-neutral-subtlest, #fff);
  width: 100%;
  padding-top: 4.875rem;
  display: flex;
  overflow: hidden;
}

.AccordionStepper-contentPanelWrapper:after {
  content: "";
  height: 3.125rem;
  max-height: 0;
  transition: height .25s ease-in-out, max-height .25s ease-in-out;
}

.AccordionStepper-contentPanel {
  width: 100%;
  margin-bottom: 0;
  padding: 1.5rem 3rem;
  transition: height .25s ease-in-out, margin-bottom .25s ease-in-out, padding .25s ease-in-out;
}

.AccordionStepper-contentPanelWrapper.AccordionStepper-contentPanelWrapper--isNotExpanded > .AccordionStepper-contentPanel {
  max-height: 0;
  margin-bottom: -15rem;
  transition: height .25s ease-in-out, margin-bottom .25s ease-in-out, max-height .25s;
}

.AccordionStepper-contentPanelWrapper.AccordionStepper-contentPanelWrapper--isNotExpanded:after {
  height: 0;
  max-height: 3.125rem;
  transition: height .25s ease-in-out;
}

.ButtonIcon {
  all: unset;
  border-radius: var(--border-radius-circle, 1584px);
  caret-color: #0000;
  cursor: pointer;
  isolation: isolate;
  justify-content: center;
  align-items: center;
  transition: background .1s ease-in-out, color .1s ease-in-out;
  display: flex;
  position: relative;
}

.ButtonIcon:hover {
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

.ButtonIcon[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
  outline-offset: .125rem;
}

.ButtonIcon--small span, .ButtonIcon--medium span, .ButtonIcon--large span {
  justify-content: center;
  align-items: center;
  display: flex;
}

.ButtonIcon--small {
  width: 1.5rem;
  height: 1.5rem;
}

.ButtonIcon--small .Icon, .ButtonIcon--small span svg, .ButtonIcon--small span svg path {
  width: 1rem;
  height: 1rem;
}

.ButtonIcon--medium {
  width: 2rem;
  height: 2rem;
}

.ButtonIcon--medium .Icon, .ButtonIcon--medium span svg, .ButtonIcon--medium span svg path {
  width: 1.25rem;
  height: 1.25rem;
}

.ButtonIcon--large {
  width: 2.5rem;
  height: 2.5rem;
}

.ButtonIcon--large .Icon, .ButtonIcon--large span svg, .ButtonIcon--large span svg path {
  width: 1.5rem;
  height: 1.5rem;
}

.ButtonIcon--large:focus {
  outline-width: 3px;
}

.ButtonIcon-bold {
  background: var(--color-background-primary-bold, #3751ff);
}

.ButtonIcon-bold svg path {
  fill: var(--color-foreground-neutral-inverse, #fff);
}

.ButtonIcon-outlinedSecondary {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
  background: var(--color-background-neutral-subtlest, #fff);
}

.ButtonIcon-outlinedSecondary svg path {
  fill: var(--color-foreground-secondary-bold, #00245b);
}

.ButtonIcon-outlinedNeutral {
  border: 1px solid var(--color-border-secondary-decorative, #6888b5);
  background: var(--color-background-neutral-subtlest, #fff);
}

.ButtonIcon-outlinedNeutral svg path {
  fill: var(--color-foreground-neutral-bold, #212328);
}

.ButtonIcon-ghost {
  background: none;
}

.ButtonIcon-ghost svg path {
  fill: var(--color-foreground-neutral-bold, #212328);
}

.ButtonIcon-outlinedCritical {
  border: 1px solid var(--color-border-critical-subtle, #ef5955);
  background: var(--color-background-neutral-subtlest, #fff);
}

.ButtonIcon-outlinedCritical svg path {
  fill: var(--color-foreground-critical-medium, #cc1c10);
}

.ButtonIcon-ghostInverse {
  background: none;
  border: 1px solid #0000;
}

.ButtonIcon-ghostInverse svg path {
  fill: var(--color-foreground-neutral-inverse, #fff);
}

.ButtonIcon-overlay {
  z-index: -1;
  border-radius: var(--border-radius-circle, 1584px);
  opacity: 0;
  background: none;
  width: 100%;
  height: 100%;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.ButtonIcon-overlay-bold:hover, .ButtonIcon.ButtonIcon-bold:hover .ButtonIcon-overlay-bold, .Icon:hover + .ButtonIcon-overlay-bold {
  opacity: 1;
  background: #ffffff3d;
  border: none;
}

.ButtonIcon-outlinedSecondary:hover {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  transition: all .1s ease-in-out;
}

.ButtonIcon-overlay-outlinedSecondary:hover, .ButtonIcon.ButtonIcon-outlinedSecondary:hover .ButtonIcon-overlay-outlinedSecondary, .Icon:hover + .ButtonIcon-overlay-outlinedSecondary {
  opacity: 1;
  background: #254b8614;
}

.ButtonIcon-outlinedNeutral:hover {
  border: 1px solid #3751ff;
}

.ButtonIcon-outlinedNeutral:hover svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}

.ButtonIcon-overlay-outlinedNeutral:hover, .ButtonIcon.ButtonIcon-outlinedNeutral:hover .ButtonIcon-overlay-outlinedNeutral, .Icon:hover + .ButtonIcon-overlay-outlinedNeutral {
  opacity: 1;
  background: #3751ff14;
}

.ButtonIcon-overlay-ghost:hover, .ButtonIcon.ButtonIcon-ghost:hover .ButtonIcon-overlay-ghost, .Icon:hover + .ButtonIcon-overlay-ghost {
  opacity: 1;
  background: #5c5e6e14;
  border: none;
}

.ButtonIcon-outlinedCritical:hover {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}

.ButtonIcon-overlay-outlinedCritical:hover, .ButtonIcon.ButtonIcon-outlinedCritical:hover .ButtonIcon-overlay-outlinedCritical, .Icon:hover + .ButtonIcon-overlay-outlinedCritical {
  opacity: 1;
  background: #cc1c1014;
}

.ButtonIcon-overlay-ghostInverse:hover, .ButtonIcon.ButtonIcon-ghostInverse:hover .ButtonIcon-overlay-ghostInverse, .Icon:hover + .ButtonIcon-overlay-ghostInverse {
  opacity: 1;
  background: #9ea1b114;
}

.ButtonIcon-overlay-bold:active, .ButtonIcon.ButtonIcon-bold:active .ButtonIcon-overlay-bold, .Icon:active + .ButtonIcon-overlay-bold {
  opacity: 1;
  background: #21232852;
}

.ButtonIcon-overlay-outlinedSecondary:active, .ButtonIcon.ButtonIcon-outlinedSecondary:active .ButtonIcon-overlay-outlinedSecondary, .Icon:active + .ButtonIcon-overlay-outlinedSecondary {
  opacity: 1;
  background: #254b861f;
}

.ButtonIcon-overlay-outlinedNeutral:active, .ButtonIcon.ButtonIcon-outlinedNeutral:active .ButtonIcon-overlay-outlinedNeutral, .Icon:active + .ButtonIcon-overlay-outlinedNeutral {
  opacity: 1;
  background: #3751ff1f;
}

.ButtonIcon-overlay-ghost:active, .ButtonIcon.ButtonIcon-ghost:active .ButtonIcon-overlay-ghost, .Icon:active + .ButtonIcon-overlay-ghost {
  opacity: 1;
  background: #5c5e6e1f;
}

.ButtonIcon-overlay-outlinedCritical:active, .ButtonIcon.ButtonIcon-outlinedCritical:active .ButtonIcon-overlay-outlinedCritical, .Icon:active + .ButtonIcon-overlay-outlinedCritical {
  opacity: 1;
  background: #cc1c101f;
}

.ButtonIcon-overlay-ghostInverse:active, .ButtonIcon.ButtonIcon-ghostInverse:active .ButtonIcon-overlay-ghostInverse, .Icon:active + .ButtonIcon-overlay-ghostInverse {
  opacity: 1;
  background: #9ea1b11f;
}

.ButtonIcon-bold--isDisabled, .ButtonIcon-outlinedSecondary--isDisabled, .ButtonIcon-outlinedNeutral--isDisabled, .ButtonIcon-outlinedCritical--isDisabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  pointer-events: none;
  border: #0000;
}

.ButtonIcon-bold--isDisabled svg path, .ButtonIcon-outlinedSecondary--isDisabled svg path, .ButtonIcon-outlinedNeutral--isDisabled svg path, .ButtonIcon-outlinedCritical--isDisabled svg path, .ButtonIcon-ghost--isDisabled svg path, .ButtonIcon-ghostInverse--isDisabled svg path {
  fill: var(--color-foreground-neutral-disabled, #9ea1b1);
}

.ButtonIcon-ghost--isDisabled, .ButtonIcon-ghostInverse--isDisabled {
  pointer-events: none;
  background: none;
  border: #0000;
}

.ButtonIcon-bold--isSelected {
  background: var(--color-background-secondary-bold, #254b86);
}

.ButtonIcon-overlay-outlinedSecondary--isSelected:hover, .ButtonIcon.ButtonIcon-outlinedSecondary--isSelected:hover .ButtonIcon-overlay-outlinedSecondary--isSelected, .Icon:hover + .ButtonIcon-overlay-outlinedSecondary--isSelected {
  opacity: 1;
  background: #ffffff3d;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
}

.ButtonIcon-overlay-outlinedSecondary--isSelected:active, .ButtonIcon.ButtonIcon-outlinedSecondary--isSelected:active .ButtonIcon-overlay-outlinedSecondary--isSelected, .Icon:active + .ButtonIcon-overlay-outlinedSecondary--isSelected {
  opacity: 1;
  background: #21232852;
}

.ButtonIcon-outlinedSecondary--isSelected {
  background: var(--color-background-secondary-bold, #254b86);
  border: 1px solid #0000;
}

.ButtonIcon-bold--isSelected svg path, .ButtonIcon-outlinedSecondary--isSelected svg path {
  fill: #fff;
}

.ButtonIcon-outlinedNeutral--isSelected {
  border: 1px solid var(--color-foreground-primary-medium, #3751ff);
  background: #3751ff1a;
}

.ButtonIcon-outlinedNeutral--isSelected svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}

.ButtonIcon-ghost--isSelected {
  border: 1px solid var(--color-foreground-secondary-bold, #00245b);
  background: #254b861a;
}

.ButtonIcon-ghost--isSelected svg path {
  fill: var(--color-foreground-secondary-bold, #00245b);
}

.ButtonIcon-ghost--isSelected:hover {
  border: 1px solid var(--color-foreground-secondary-bold, #00245b);
}

.ButtonIcon-ghostInverse--isSelected {
  background: #89a3c71a;
  border: 1px solid #fff;
}

.ButtonIcon-ghostInverse--isSelected svg path {
  fill: #fff;
}

.AlertBanner-container {
  border-radius: .25rem;
  align-items: center;
  min-height: 2.75rem;
  padding: .25rem 1rem .25rem .5rem;
  animation: .2s cubic-bezier(.64, 0, .32, 1) forwards AlertBannerFadeOut;
  display: flex;
}

.AlertBanner-container.AlertBanner--isFloating {
  z-index: var(--z-index-floating-banner);
}

.AlertBanner-container.AlertBanner-container--isVisible {
  animation: .2s cubic-bezier(.64, 0, .32, 1) forwards AlertBannerFadeIn;
}

.AlertBanner-main {
  width: 100%;
}

.AlertBanner-section {
  justify-content: space-between;
  gap: .25rem;
  width: 100%;
  display: flex;
}

.AlertBanner-heading {
  gap: .25rem;
  display: flex;
}

.AlertBanner-icon {
  align-items: center;
  display: flex;
}

.AlertBanner-success--low {
  background: var(--color-background-success-subtlest, #f3f8f2);
  border: 1px solid var(--color-border-success-medium, #028329);
  color: var(--color-foreground-neutral-bold, #212328);
}

.AlertBanner-success--high {
  background: var(--color-border-success-medium, #028329);
  color: var(--color-foreground-neutral-inverse, #fff);
}

.AlertBanner-critical {
  background: var(--color-background-critical-bold, #cc1c10);
  color: var(--color-foreground-neutral-inverse, #fff);
}

.AlertBanner-warning--low {
  background: var(--color-background-warning-subtle, #fff1d9);
  border: 1px solid var(--color-border-warning-subtle, #f6a91c);
  color: var(--color-foreground-neutral-bold, #212328);
}

.AlertBanner-warning--high {
  background: var(--color-background-warning-bold, #f6a91c);
  color: var(--color-foreground-neutral-bold, #212328);
}

.AlertBanner-information--low {
  background: var(--color-background-info-subtle, #f1ecfd);
  border: 1px solid var(--color-foreground-info-medium, #573bdc);
  color: var(--color-foreground-neutral-bold, #212328);
}

.AlertBanner-information--high {
  background: var(--color-background-info-bold, #573bdc);
  color: var(--color-foreground-neutral-inverse, #fff);
}

.AlertBanner-title {
  font: var(--typography-heading-4);
  align-items: center;
  gap: .25rem;
  display: flex;
}

.AlertBanner-title .AlertBanner-title-description {
  font: var(--typography-body-2);
}

.AlertBanner-close {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.AlertBanner-body {
  height: 0;
  transition: height .2s;
  overflow: hidden;
}

.AlertBanner-expand-btn {
  align-items: center;
  display: flex;
}

.AlertBanner-show-section {
  cursor: pointer;
  align-items: center;
  padding-left: .25rem;
  display: flex;
}

.AlertBanner-show-text {
  color: var(--color-foreground-primary-medium, #3751ff);
  font: var(--typography-body-bold-2);
}

.AlertBanner-body .AlertBanner-content {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-bold, #212328);
  padding: 1.25rem 2.5rem;
}

.AlertBanner-link {
  align-items: center;
  padding-left: .25rem;
  display: flex;
}

.AlertBanner-critical .Link .Link-wrapper, .AlertBanner-information--high .Link .Link-wrapper, .AlertBanner-success--high .Link .Link-wrapper {
  color: var(--color-foreground-neutral-inverse, #fff);
}

.AlertBanner-warning--high .Link .Link-wrapper {
  color: var(--color-foreground-neutral-bold, #212328);
}

.AlertBanner-success--low .Link .Link-wrapper, .AlertBanner-information--low .Link .Link-wrapper, .AlertBanner-warning--low .Link .Link-wrapper {
  color: var(--color-foreground-primary-medium, #3751ff);
}

.AlertBanner-icon .Color-neutral-000 svg path {
  fill: #fff;
}

@keyframes AlertBannerFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes AlertBannerFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.ApplicationIcon {
  background-color: var(--color-background-primary-subtle);
  border-radius: 50%;
  transition: transform .2s ease-in-out;
  display: inline-block;
}

.ApplicationIcon-icon {
  min-width: unset;
  width: 100%;
  height: 100%;
  transform: scale(.71);
}

.ApplicationIcon svg {
  width: 100%;
  height: 100%;
}

.ApplicationIcon--xxSmall {
  height: var(--application-icon-size-xxsmall);
  width: var(--application-icon-size-xxsmall);
  min-width: var(--application-icon-size-xxsmall);
  min-height: unset;
}

.ApplicationIcon--xSmall {
  height: var(--application-icon-size-xsmall);
  width: var(--application-icon-size-xsmall);
  min-width: var(--application-icon-size-xsmall);
  min-height: unset;
}

.ApplicationIcon--small {
  height: var(--application-icon-size-small);
  width: var(--application-icon-size-small);
  min-width: var(--application-icon-size-small);
  min-height: unset;
}

.ApplicationIcon--medium {
  height: var(--application-icon-size-medium);
  width: var(--application-icon-size-medium);
  min-width: var(--application-icon-size-medium);
  min-height: unset;
}

.ApplicationIcon--large {
  height: var(--application-icon-size-large);
  width: var(--application-icon-size-large);
  min-width: var(--application-icon-size-large);
  min-height: unset;
}

.ApplicationIcon--xLarge {
  height: var(--application-icon-size-xlarge);
  width: var(--application-icon-size-xlarge);
  min-width: var(--application-icon-size-xlarge);
  min-height: unset;
}

.ApplicationIcon--xxLarge {
  height: var(--application-icon-size-xxlarge);
  width: var(--application-icon-size-xxlarge);
  min-width: var(--application-icon-size-xxlarge);
  min-height: unset;
}

.Avatar {
  border: 2px solid var(--color-border-secondary-inverse, #f2f4f8);
  background: var(--color-background-neutral-medium, #cecfd9);
  color: var(--color-foreground-secondary-medium, #254b86);
  border-radius: 4rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.Avatar-img {
  text-align: center;
  object-fit: cover;
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.Avatar--xxSmall, .Avatar--xxSmall img {
  font: var(--typography-body-bold-3);
  width: 1.25rem;
  height: 1.25rem;
}

.Avatar--xSmall, .Avatar--xSmall img {
  font: var(--typography-body-bold-2);
  width: 1.5rem;
  height: 1.5rem;
}

.Avatar--small, .Avatar--small img {
  font: var(--typography-body-bold-1);
  width: 2rem;
  height: 2rem;
}

.Avatar--medium, .Avatar--medium img {
  font: var(--typography-heading-2);
  width: 2.5rem;
  height: 2.5rem;
}

.Badge {
  position: relative;
}

.Badge-number {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-bold-3);
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
}

.Badge--dotNumber {
  color: var(--color-foreground-neutral-inverse, #fff);
  font: var(--typography-body-bold-3);
}

.Badge--dotNumber.Badge--warning .Badge-number {
  color: var(--color-foreground-neutral-bold);
}

.Badge--dotNumber .Badge-number {
  color: var(--color-foreground-neutral-inverse, #fff);
  font: var(--typography-body-bold-3);
  position: absolute;
  top: 0;
  left: 0;
}

.Badge--dot.Badge--neutral .Icon svg circle, .Badge--dotNumber.Badge--neutral .Icon svg circle {
  fill: var(--color-background-neutral-bold, #5c5e6e);
  stroke: var(--color-border-secondary-inverse, #f2f4f8);
  stroke-width: .125rem;
}

.Badge--dot.Badge--primary .Icon svg circle, .Badge--dotNumber.Badge--primary .Icon svg circle {
  fill: var(--color-background-primary-bold, #3751ff);
  stroke: var(--color-border-secondary-inverse, #f2f4f8);
  stroke-width: .125rem;
}

.Badge--dot.Badge--secondary .Icon svg circle, .Badge--dotNumber.Badge--secondary .Icon svg circle {
  fill: var(--color-background-secondary-bold, #254b86);
  stroke: var(--color-border-secondary-inverse, #f2f4f8);
  stroke-width: .125rem;
}

.Badge--dot.Badge--error .Icon svg circle, .Badge--dotNumber.Badge--error .Icon svg circle {
  fill: var(--color-background-critical-bold, #cc1c10);
  stroke: var(--color-border-secondary-inverse, #f2f4f8);
  stroke-width: .125rem;
}

.Badge--dot.Badge--success .Icon svg circle, .Badge--dotNumber.Badge--success .Icon svg circle {
  fill: var(--color-background-success-bold, #028329);
  stroke: var(--color-border-secondary-inverse, #f2f4f8);
  stroke-width: .125rem;
}

.Badge--dot.Badge--warning .Icon svg {
  border-radius: 50%;
}

.Badge--dotNumber.Badge--warning .Icon svg circle {
  fill: var(--color-background-warning-bold, #f6a91c);
}

.Badge--dot.Badge--warning .Icon svg circle {
  fill: var(--color-background-warning-bold, #f6a91c);
  stroke: var(--color-border-warning-medium, #a36a00);
  stroke-width: .0625rem;
}

.Badge--dot.Badge--info .Icon svg circle, .Badge--dotNumber.Badge--info .Icon svg circle {
  fill: var(--color-background-info-bold, #573bdc);
  stroke: var(--color-border-secondary-inverse, #f2f4f8);
  stroke-width: .125rem;
}

.Badge--small, .Badge--small .Icon {
  justify-content: center;
  align-items: center;
  width: .625rem;
  min-width: .625rem;
  height: .625rem;
  min-height: .625rem;
  display: flex;
}

.Badge--medium, .Badge--medium .Icon {
  justify-content: center;
  align-items: center;
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  min-height: 1rem;
  display: flex;
}

.Badge--large, .Badge--large .Icon {
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  display: flex;
}

.Loader {
  width: 5rem;
  height: 5rem;
}

.Loader--inline, .Loader--inlineSmall {
  width: 1.25rem;
  height: 1.25rem;
}

.Loader--inlineMedium {
  width: 1.5rem;
  height: 1.5rem;
}

.Loader--inlineLarge {
  width: 2rem;
  height: 2rem;
}

.Loader--circle {
  width: 2.5rem;
  height: 2.5rem;
}

.Loader--text {
  width: 100%;
  min-width: 5rem;
  max-width: 10rem;
  height: 1rem;
}

.Loader--square {
  width: 7.5rem;
  height: 7.5rem;
}

.Button {
  all: unset;
  box-sizing: border-box;
  border-radius: var(--border-radius-sm, 4px);
  font: var(--typography-body-bold-1);
  caret-color: #0000;
  cursor: pointer;
  isolation: isolate;
  transition: all .1s ease-in-out;
  display: flex;
  position: relative;
}

.Button-content-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Button:hover {
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

.Button:not(.Button--isDisabled)[tabindex]:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  outline-offset: .125rem;
}

.Button-children {
  white-space: nowrap;
  padding: 0 .25rem;
}

.Button-square--small, .Button-square--medium, .Button-square--large {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Button-square--small {
  width: 2rem;
}

.Button-square--medium {
  width: 2.5rem;
}

.Button-square--large {
  width: 3rem;
}

.Button-children-null {
  display: none;
}

.Button-icons--small, .Button-icons--small svg {
  width: 1rem;
  height: 1rem;
}

.Button-icons--medium, .Button-icons--medium svg {
  width: 1.25rem;
  height: 1.25rem;
}

.Button-icons--large, .Button-icons--large svg {
  width: 1.5rem;
  height: 1.5rem;
}

.Button--small {
  font: var(--typography-body-bold-3);
  height: 2rem;
  padding: .625rem .75rem;
}

.Button--medium {
  font: var(--typography-body-bold-2);
  height: 2.5rem;
  padding: .625rem .875rem;
}

.Button--large {
  font: var(--typography-body-bold-1);
  height: 3rem;
  padding: .75rem 1rem;
}

.Button-filled {
  background: var(--color-background-primary-bold, #3751ff);
  color: var(--color-foreground-neutral-inverse, #fff);
}

.Button-filled svg path {
  fill: var(--color-foreground-neutral-inverse, #fff);
}

.Button-outlined {
  background: var(--color-background-neutral-subtlest, #fff);
  color: var(--color-foreground-primary-medium, #3751ff);
  border: 1px solid var(--color-border-primary-subtle, #6e7fff);
}

.Button-outlined svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}

.Button-subtle {
  background: var(--color-background-primary-subtle, #ebedff);
  color: var(--color-foreground-primary-medium, #3751ff);
}

.Button-subtle svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}

.Button-ghost {
  color: var(--color-foreground-primary-medium, #3751ff);
  background: none;
}

.Button-ghost svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}

.button-outlined--isenabled:hover .Button-overlay-outlined--isEnabled {
  background: red;
}

.Button-overlay {
  z-index: -1;
  border-radius: var(--border-radius-sm, 4px);
  opacity: 0;
  background: none;
  width: 100%;
  height: 100%;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.Button-overlay-filled:hover, .Button-content-container:hover + .Button-overlay-filled {
  opacity: 1;
  background: #ffffff3d;
}

.Button-outlined:hover, .Button-outlined:active {
  border: 1px solid var(--color-border-primary-medium, #3751ff);
}

.Button-overlay-outlined:hover, .Button:hover .Button-overlay-outlined, .Button-content-container:hover + .Button-overlay-outlined, .Button-overlay-subtle:hover, .Button-content-container:hover + .Button-overlay-subtle, .Button-overlay-ghost:hover, .Button-content-container:hover + .Button-overlay-ghost {
  opacity: 1;
  background: #3751ff14;
}

.Button-subtle:hover, .Button-ghost:hover {
  box-shadow: unset;
}

.Button-overlay-filled:active, .Button:active .Button-overlay, .Button-content-container:active + .Button-overlay-filled {
  opacity: 1;
  background: #21232852;
}

.Button-overlay-outlined:active, .Button:active .Button-overlay, .Button-content-container:active + .Button-overlay-outlined, .Button-overlay-subtle:active, .Button:active .Button-overlay, .Button-content-container:active + .Button-overlay-subtle, .Button-overlay-ghost:active, .Button:active .Button-overlay, .Button-content-container:active + .Button-overlay-ghost {
  opacity: 1;
  background: #3751ff1f;
}

.Button-filled--isEnabled {
  background: var(--color-background-critical-bold, #cc1c10);
  color: var(--color-foreground-neutral-inverse, #fff);
}

.Button-filled--isEnabled svg path {
  fill: var(--color-foreground-neutral-inverse, #fff);
}

.Button-outlined--isEnabled {
  background: var(--color-background-neutral-subtlest, #fff);
  color: var(--color-foreground-critical-medium, #cc1c10);
  border: 1px solid var(--color-border-critical-subtle, #ef5955);
}

.Button-outlined--isEnabled svg path {
  fill: var(--color-foreground-critical-medium, #cc1c10);
}

.Button-outlined--isEnabled:hover {
  border: 1px solid var(--color-foreground-critical-medium, #cc1c10);
}

.Button-overlay-outlined--isEnabled:hover, .Button:hover .Button-overlay-outlined--isEnabled, .Button-content-container:hover + .Button-overlay-outlined--isEnabled {
  opacity: 1;
  background: #cc1c1014;
}

.Button-overlay-outlined--isEnabled:active, .Button-content-container:active + .Button-overlay-outlined--isEnabled {
  opacity: 1;
  background: #cc1c101f;
}

.Button-subtle--isEnabled {
  background: var(--color-background-critical-subtle, #fdeeed);
  color: var(--color-foreground-critical-medium, #cc1c10);
}

.Button-subtle--isEnabled svg path {
  fill: var(--color-foreground-critical-medium, #cc1c10);
}

.Button-overlay-subtle--isEnabled:hover, .Button:hover .Button-overlay-subtle--isEnabled, .Button-content-container:hover + .Button-overlay-subtle--isEnabled {
  opacity: 1;
  background: #cc1c1014;
}

.Button-overlay-subtle--isEnabled:active, .Button-content-container:active + .Button-overlay-subtle--isEnabled {
  opacity: 1;
  background: #cc1c101f;
}

.Button-ghost--isEnabled {
  color: var(--color-foreground-critical-medium, #cc1c10);
  background: none;
}

.Button-ghost--isEnabled svg path {
  fill: var(--color-foreground-critical-medium, #cc1c10);
}

.Button-overlay-ghost--isEnabled:hover, .Button:hover .Button-overlay-ghost--isEnabled, .Button-content-container:hover + .Button-overlay-ghost--isEnabled {
  opacity: 1;
  background: #cc1c1014;
}

.Button-overlay-ghost--isEnabled:active, .Button-content-container:active + .Button-overlay-ghost--isEnabled {
  opacity: 1;
  background: #cc1c101f;
}

.Button-filled--isSuccess {
  background: var(--color-background-success-bold, #028329);
  pointer-events: none;
}

.Button-outlined--isSuccess, .Button-subtle--isSuccess, .Button-ghost--isSuccess {
  color: var(--color-foreground-success-medium, #028329);
  pointer-events: none;
}

.Button-outlined--isSuccess svg path, .Button-subtle--isSuccess svg path, .Button-ghost--isSuccess svg path {
  fill: var(--color-foreground-success-medium, #028329);
}

.Button-outlined--isSuccess {
  border: 1px solid var(--color-border-success-subtle, #43a34d);
}

.Button-subtle--isSuccess {
  background: var(--color-background-success-subtle, #e5f7e0);
}

.Button--isDisabled, .Button--isDisabled:hover {
  color: var(--color-foreground-neutral-subtle, #717385);
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  cursor: not-allowed;
  box-shadow: none;
}

.Button--isDisabled svg path {
  fill: var(--color-foreground-neutral-subtle, #717385);
}

.Button--isDisabled .Button-overlay {
  pointer-events: none;
  cursor: not-allowed;
}

.Button--isDisabled:hover .Button-content-container + .Button-overlay {
  background: none;
}



.Typography-h1-d {
  font: var(--typography-heading-1);
}

.Typography-h1-m {
  font: var(--typography-heading-1-mobile);
}

.Typography-h2-d {
  font: var(--typography-heading-2);
}

.Typography-h2-m {
  font: var(--typography-heading-2-mobile);
}

.Typography-h3-d {
  font: var(--typography-heading-3);
}

.Typography-h3-m {
  font: var(--typography-heading-3-mobile);
}

.Typography-h4-d {
  font: var(--typography-heading-4);
}

.Typography-h4-m {
  font: var(--typography-heading-4-mobile);
}

.Typography-h5-d {
  font: var(--typography-heading-5);
}

.Typography-h5-m {
  font: var(--typography-heading-5-mobile);
}

.Typography-body1-d {
  font: var(--typography-body-1);
}

.Typography-body1-m {
  font: var(--typography-body-1-mobile);
}

.Typography-body1-bold-d {
  font: var(--typography-body-bold-1);
}

.Typography-body1-bold-m {
  font: var(--typography-body-bold-1-mobile);
}

.Typography-body2-d {
  font: var(--typography-body-2);
}

.Typography-body2-m {
  font: var(--typography-body-2-mobile);
}

.Typography-body2-bold-d {
  font: var(--typography-body-bold-2);
}

.Typography-body2-bold-m {
  font: var(--typography-body-bold-2-mobile);
}

.Typography-body3-d {
  font: var(--typography-body-3);
}

.Typography-body3-m {
  font: var(--typography-body-3-mobile);
}

.Typography-body3-bold-d {
  font: var(--typography-body-bold-3);
}

.Typography-body3-bold-m {
  font: var(--typography-body-bold-3-mobile);
}

.Typography-header-d {
  font: var(--typography-display);
}

.Typography-header-m {
  font: var(--typography-display-mobile);
}

.Typography-display-d {
  font: var(--typography-display);
}

.Typography-display-m {
  font: var(--typography-display-mobile);
}

.Typography-label-d {
  font: var(--typography-heading-3);
}

.Typography-label-m {
  font: var(--typography-heading-3-mobile);
}

@media only screen and (width <= 600px) {
  .Typography-mobile-h1-d {
    font: var(--typography-heading-1);
  }

  .Typography-mobile-h1-m {
    font: var(--typography-heading-1-mobile);
  }

  .Typography-mobile-h2-d {
    font: var(--typography-heading-2);
  }

  .Typography-mobile-h2-m {
    font: var(--typography-heading-2-mobile);
  }

  .Typography-mobile-h3-d {
    font: var(--typography-heading-3);
  }

  .Typography-mobile-h3-m {
    font: var(--typography-heading-3-mobile);
  }

  .Typography-mobile-h4-d {
    font: var(--typography-heading-4);
  }

  .Typography-mobile-h4-m {
    font: var(--typography-heading-4-mobile);
  }

  .Typography-mobile-h5-d {
    font: var(--typography-heading-5);
  }

  .Typography-mobile-h5-m {
    font: var(--typography-heading-5-mobile);
  }

  .Typography-mobile-body1-d {
    font: var(--typography-body-1);
  }

  .Typography-mobile-body1-m {
    font: var(--typography-body-1-mobile);
  }

  .Typography-mobile-body1-bold-d {
    font: var(--typography-body-bold-1);
  }

  .Typography-mobile-body1-bold-m {
    font: var(--typography-body-bold-1-mobile);
  }

  .Typography-mobile-body2-d {
    font: var(--typography-body-2);
  }

  .Typography-mobile-body2-m {
    font: var(--typography-body-2-mobile);
  }

  .Typography-mobile-body2-bold-d {
    font: var(--typography-body-bold-2);
  }

  .Typography-mobile-body2-bold-m {
    font: var(--typography-body-bold-2-mobile);
  }

  .Typography-mobile-body3-d {
    font: var(--typography-body-3);
  }

  .Typography-mobile-body3-m {
    font: var(--typography-body-3-mobile);
  }

  .Typography-mobile-body3-bold-d {
    font: var(--typography-body-bold-3);
  }

  .Typography-mobile-body3-bold-m {
    font: var(--typography-body-bold-3-mobile);
  }

  .Typography-mobile-header-d {
    font: var(--typography-display);
  }

  .Typography-mobile-header-m {
    font: var(--typography-display-mobile);
  }

  .Typography-mobile-display-d {
    font: var(--typography-display);
  }

  .Typography-mobile-display-m {
    font: var(--typography-display-mobile);
  }

  .Typography-mobile-label-d {
    font: var(--typography-heading-3);
  }

  .Typography-mobile-label-m {
    font: var(--typography-heading-3-mobile);
  }
}

label.Label {
  margin-bottom: 0;
}

.Typography {
  margin: 0;
  transition: font .2s ease-in-out;
}

.Typography--isNoWrap {
  white-space: nowrap;
}

.ButtonSelect--rectangle {
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
  cursor: pointer;
  font: var(--typography-body-2);
  background: var(--color-background-neutral-subtlest, #fff);
  justify-content: center;
  align-items: center;
  gap: .125rem;
  padding: .25rem .75rem;
  display: inline-flex;
  position: relative;
}

.ButtonSelect--rectangle .ButtonSelect-label {
  cursor: pointer;
}

.ButtonSelect--rectangle.ButtonSelect--small {
  height: 2rem;
}

.ButtonSelect--rectangle.ButtonSelect--medium {
  height: 2.5rem;
}

.ButtonSelect--rectangle.ButtonSelect--large {
  height: 3rem;
}

.ButtonSelect--rectangle .ButtonSelect-label {
  color: var(--color-foreground-secondary-medium, #254b86);
  font: var(--typography-body-2);
  padding: .1875rem .125rem .1875rem .25rem;
}

.ButtonSelect--rectangle.ButtonSelect--hasBadge .ButtonSelect-label {
  color: var(--color-foreground-secondary-medium, #254b86);
  font: var(--typography-body-bold-2);
}

.ButtonSelect--rectangle:hover {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
  background: #254b8614;
}

.ButtonSelect--rectangle:active {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
  background: #254b861f;
}

.ButtonSelect--rectangle--isOpen {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: var(--color-background-secondary-subtle, #f2f4f8);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

.ButtonSelect--rectangle--isOpen:hover {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: var(--color-background-secondary-subtle, #f2f4f8);
  box-shadow: 0 1px 8px #0000000f;
}

.ButtonSelect--rectangle--isOpen:active {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
  background: #254b861f;
}

.ButtonSelect--rectangle.ButtonSelect--isDisabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  pointer-events: none;
  border: 0;
}

.ButtonSelect--rectangle .Badge {
  position: absolute;
}

.ButtonSelect--rectangle .Badge--small {
  top: -.25rem;
  right: .0625rem;
}

.ButtonSelect--rectangle-icon, .ButtonSelect--rectangle-chevron {
  width: 1.25rem;
  height: 1.25rem;
}

.ButtonSelect--small .ButtonSelect--rectangle-icon, .ButtonSelect--small .ButtonSelect--rectangle-chevron {
  width: 1rem;
  height: 1rem;
}

.ButtonSelect--pill {
  border: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
  cursor: pointer;
  font: var(--typography-body-2);
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: 120px;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  padding: .25rem .75rem;
  display: inline-flex;
  position: relative;
}

.ButtonSelect--pill .ButtonSelect-label {
  color: var(--color-foreground-secondary-medium, #254b86);
  font: var(--typography-body-2);
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: initial;
  flex: auto;
  max-width: 10.9375rem;
  padding: .1875rem .375rem;
  overflow: hidden;
}

.ButtonSelect--pill.ButtonSelect--hasBadge .ButtonSelect-label {
  color: var(--color-foreground-secondary-medium, #254b86);
  font: var(--typography-body-bold-2);
}

.ButtonSelect--pill:hover {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
  background: #254b8614;
}

.ButtonSelect--pill:active {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
  background: #254b861f;
}

.ButtonSelect--pill--isOpen, .ButtonSelect--pill--isOpen:hover {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: var(--color-background-secondary-subtle, #f2f4f8);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

.ButtonSelect--pill--isOpen:active {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: #254b861f;
  box-shadow: 0 1px 8px #0000000f;
}

.ButtonSelect--pill.ButtonSelect--isDisabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  pointer-events: none;
  border: 0;
}

.u-button--isLabelAppended {
  color: var(--color-foreground-secondary-medium, #254b86);
  font: var(--typography-body-bold-2);
}

.ButtonSelect--pill .Badge {
  position: absolute;
}

.ButtonSelect--pill .Badge--small {
  top: -.25rem;
  right: .0625rem;
}

.ButtonSelect--pill-icon, .ButtonSelect--pill-chevron {
  width: 1rem;
  height: 1rem;
}

.Checkbox--core {
  display: inline-flex;
  position: relative;
}

.Checkbox--core .Checkbox-input {
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  position: absolute;
}

.Checkbox-input:focus-visible + .Checkbox-icon:before {
  content: "";
  border: 2px solid #6e7fff;
  border-radius: 6px;
  position: absolute;
  inset: -.1875rem;
}

.Checkbox--core .Checkbox-icon {
  cursor: pointer;
  min-width: auto;
  min-height: auto;
  transition: all .2s;
}

.Checkbox .Checkbox-icon--small, .Checkbox .Checkbox-icon--small > svg {
  width: 1rem;
  height: 1rem;
}

.Checkbox .Checkbox-icon--medium, .Checkbox .Checkbox-icon--medium > svg {
  width: 1.25rem;
  height: 1.25rem;
}

.Checkbox .Checkbox-icon--large, .Checkbox .Checkbox-icon--large > svg {
  width: 1.5rem;
  height: 1.5rem;
}

.Checkbox-icon.Checkbox-icon {
  display: none;
}

.Checkbox-icon--checked.Checkbox-icon--checked, .Checkbox-icon--disabled.Checkbox-icon--disabled, .Checkbox-icon--indeterminate.Checkbox-icon--indeterminate, .Checkbox-icon--default.Checkbox-icon--default {
  display: inline-block;
}

.Checkbox--core:hover .Checkbox-icon--default, .Checkbox--core:hover .Checkbox-icon--active {
  display: none;
}

.Checkbox--core:hover .Checkbox-icon--hover {
  display: inline-block;
}

.Checkbox--core:active .Checkbox-icon--default, .Checkbox--core:hover:active .Checkbox-icon--hover, .Checkbox--core:active .Checkbox-icon--hover {
  display: none;
}

.Checkbox--core:active .Checkbox-icon--active {
  display: inline-block;
}

.Checkbox.Checkbox--base {
  width: auto;
  display: inline-flex;
}

.Checkbox.Checkbox--base .Checkbox-label {
  padding: 0;
}

.Checkbox:not(.Checkbox--disabled, .Checkbox--hoverEffectDisabled) .Checkbox-icon:hover {
  color: var(--color-foreground-secondary-medium, #254b86);
  background: #3751ff14;
  border: none;
  border-radius: 50%;
  outline: 11px solid #3751ff14;
}

.Checkbox.Checkbox--disabled .Checkbox-icon, .Checkbox.Checkbox--disabled .Checkbox-label {
  cursor: not-allowed;
}

.Checkbox.Checkbox-card .Checkbox-label {
  padding: 1rem;
}

.Checkbox.Checkbox-card .Checkbox-textWrapper {
  flex-direction: column;
  padding: 0 1rem 0 .5rem;
  display: flex;
}

.Checkbox-card .Checkbox-labelText {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.Checkbox-card .Checkbox-iconTextWrapper {
  display: flex;
}

.Checkbox-card .Checkbox-iconTextWrapper .Checkbox-iconWrapper {
  align-self: flex-start;
}

.Checkbox.Checkbox-card.Checkbox--disabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
}

.Checkbox-container .Checkbox.Checkbox--simpleText {
  background-color: #0000;
  border: 0;
  border-radius: 0;
}

.Checkbox-container .Checkbox.Checkbox--simpleText .Checkbox-label {
  gap: .5rem;
  padding: 0;
}

.Checkbox-container .Checkbox.Checkbox--simpleText .Checkbox-labelText {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-medium, #4a4c59);
}

.Checkbox-container .Checkbox.Checkbox--simpleText:active {
  border: 0;
}

.Checkbox-container .Checkbox.Checkbox--simpleText:hover {
  box-shadow: none;
  border: 0;
}

.Checkbox-container .Checkbox.Checkbox--simpleText.Checkbox--checked {
  border: 0;
}

.Checkbox-container .Checkbox.Checkbox--simpleText.Checkbox--checked:hover {
  color: initial;
  border: 0;
}

.Checkbox:not(.Checkbox--base) {
  box-sizing: border-box;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 4px);
  border: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
  caret-color: #0000;
  cursor: pointer;
  isolation: isolate;
  transition: all .1s ease-in-out;
  position: relative;
}

.Checkbox:not(.Checkbox--base, .Checkbox--error).Checkbox--checked {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
}

.Checkbox.Checkbox--withText.Checkbox--disabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
}

.Checkbox--withText .Checkbox-iconTextWrapper {
  align-items: center;
  display: flex;
}

.Checkbox--withText .Checkbox-labelText--before {
  padding: .125rem .75rem .125rem 0;
}

.Checkbox--withText .Checkbox-labelText--after {
  padding: .125rem 0 .125rem .75rem;
}

.Checkbox--withText .Checkbox-titleText--before {
  padding: .125rem .75rem .125rem 0;
}

.Checkbox--withText .Checkbox-titleText--after {
  padding: .125rem 0 .125rem .75rem;
}

.Checkbox-label {
  word-break: break-word;
  cursor: pointer;
  align-items: center;
  padding: .75rem 1rem;
  display: flex;
}

.Checkbox:not(.Checkbox--base, .Checkbox--disabled, .Checkbox--error):hover {
  color: var(--color-foreground-secondary-medium, #254b86);
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.Checkbox:not(.Checkbox--base, .Checkbox--disabled):active {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.Checkbox:not(.Checkbox--base, .Checkbox--disabled):hover {
  box-shadow: 0 .25rem 1rem #00000029;
}

.Checkbox:not(.Checkbox--base, .Checkbox--disabled, .Checkbox--error).Checkbox--checked:hover {
  color: var(--color-foreground-secondary-medium, #254b86);
  border: 1px solid var(--color-border-secondary-medium, #254b86);
}

.Checkbox:hover .Checkbox-icon--default, .Checkbox:active .Checkbox-icon--default {
  display: none;
}

.Checkbox:hover:not(:active) .Checkbox-icon--hover {
  display: inline-block;
}

.Checkbox:active .Checkbox-icon--hover {
  display: none;
}

.Checkbox:active .Checkbox-icon--active {
  display: inline-block;
}

.Checkbox-labelText {
  color: var(--color-foreground-secondary-bold, #00245b);
  font: var(--typography-body-2);
}

.Checkbox--withText .Checkbox-iconWrapper {
  transform: translate(0, 3px);
}

.Checkbox-titleText {
  color: var(--color-foreground-secondary-bold, #00245b);
  font: var(--typography-heading-3);
}

.Checkbox.Checkbox--disabled .Checkbox-labelText, .Checkbox.Checkbox--disabled .Checkbox-titleText {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.Checkbox.Checkbox--error, .Checkbox.Checkbox--error:hover, .Checkbox.Checkbox--error:active {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}

.Checkbox-container {
  flex-direction: column;
  gap: .25rem;
  display: inline-flex;
}

.Checkbox-container .Checkbox-errorWrapper {
  color: var(--color-foreground-critical-medium, #cc1c10);
  font: var(--typography-body-3);
  gap: .25rem;
  display: flex;
}

.Checkbox-container .Checkbox-errorWrapper .Icon {
  align-self: center;
}

.Checkbox-overlay {
  z-index: -1;
  border-radius: var(--border-radius-sm, 4px);
  opacity: 0;
  background: none;
  border: 1px solid #0000;
  width: 100%;
  height: 100%;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.Checkbox-overlay:active, .Checkbox:active .Checkbox-overlay {
  opacity: 1;
  background: #254b861f;
}

.Checkbox--disabled.Checkbox:active .Checkbox-overlay, .Checkbox--disabled .Checkbox-overlay:active {
  opacity: 0;
}

.CheckboxGroup {
  border: none;
  flex-flow: wrap;
  justify-content: flex-start;
  gap: .5rem;
  margin: 0;
  padding: 0;
  display: flex;
}

.CheckboxGroup-legend {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-heading-4);
  padding: 0;
  display: block;
}

.CheckboxGroup-container {
  flex-direction: column;
  display: flex;
}

.CheckboxGroup-error {
  color: var(--color-foreground-critical-medium, #cc1c10);
  word-break: break-word;
  font: var(--typography-body-3);
  align-items: center;
  gap: .25rem;
  margin-top: .25rem;
  display: flex;
}

.CheckboxGroup-error span svg {
  width: .75rem;
  height: .75rem;
}

.Chip {
  all: unset;
  box-sizing: border-box;
  border-radius: var(--border-radius-circle, 99rem);
  cursor: pointer;
  isolation: isolate;
  transition: all .1s ease-in-out;
  display: flex;
  position: relative;
}

.Chip-content-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Chip:not(.Chip--isDisabled)[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
  outline-offset: .125rem;
}

.Chip-label {
  white-space: nowrap;
  cursor: pointer;
  padding: 0 .25rem;
}

.Chip-label--small {
  font: var(--typography-body-3);
}

.Chip-label--medium {
  font: var(--typography-body-2);
}

.Chip-label--large {
  font: var(--typography-body-1);
}

.Chip-icons--small, .Chip-icons--small svg {
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  display: flex;
}

.Chip-icons--medium, .Chip-icons--medium svg, .Chip-icons--large, .Chip-icons--large svg {
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
}

.Chip--small {
  height: 1.5rem;
  padding: .1875rem .375rem;
}

.Chip--medium {
  height: 2rem;
  padding: .25rem .5rem;
}

.Chip--large {
  height: 2.25rem;
  padding: .5rem .75rem;
}

.Chip-outlined {
  background: var(--color-background-neutral-subtlest, #fff);
  border: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
}

.Chip-outlined .Chip-label, .Chip-outlined .Icon svg path {
  color: var(--color-foreground-neutral-bold, #212328);
  fill: var(--color-foreground-neutral-bold, #212328);
}

.Chip-boldFilled {
  background: var(--color-background-neutral-bold, #5c5e6e);
  border: none;
}

.Chip-boldFilled .Chip-label, .Chip-boldFilled .Icon svg path {
  fill: var(--color-background-neutral-subtlest, #fff);
  color: var(--color-background-neutral-subtlest, #fff);
}

.Chip-subtleFilled {
  background: var(--color-background-neutral-subtle, #ebecf2);
  border: none;
}

.Chip-subtleFilled .Chip-label, .Chip-subtleFilled .Icon svg path {
  color: var(--color-foreground-neutral-bold, #212328);
  fill: var(--color-foreground-neutral-bold, #212328);
}

.Chip-overlay {
  z-index: -1;
  border-radius: var(--border-radius-circle, 99rem);
  opacity: 0;
  background: none;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transition: all .1s ease-in-out;
  position: absolute;
  top: -1px;
  left: -1px;
}

.Chip-overlay.Chip-overlay-outlined {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.Chip-outlined:hover {
  border: 1px solid var(--color-border-neutral-subtle, #888b9d);
}

.Chip-outlined:active {
  border: 1px solid var(--color-border-neutral-medium, #5c5e6e);
}

.Chip-overlay-outlined:hover, .Chip:hover .Chip-overlay-outlined, .Chip-content-container:hover + .Chip-overlay-outlined {
  opacity: 1;
  background: #5c5e6e14;
}

.Chip-overlay-outlined:active, .Chip:active .Chip-overlay, .Chip-content-container:active + .Chip-overlay-outlined {
  opacity: 1;
  background: #5c5e6e1a;
}

.Chip-boldFilled:hover, .Chip-boldFilled:active {
  border: none;
}

.Chip-overlay-boldFilled:hover, .Chip:hover .Chip-overlay-boldFilled, .Chip-content-container:hover + .Chip-overlay-boldFilled {
  opacity: 1;
  background: #ffffff3d;
}

.Chip-overlay-boldFilled:active, .Chip:active .Chip-overlay, .Chip-content-container:active + .Chip-overlay-boldFilled {
  opacity: 1;
  background: #5c5e6e1a;
}

.Chip-subtleFilled:hover, .Chip-subtleFilled:active {
  border: none;
}

.Chip-overlay-subtleFilled:hover, .Chip:hover .Chip-overlay-subtleFilled, .Chip-content-container:hover + .Chip-overlay-subtleFilled {
  opacity: 1;
  background: #5c5e6e14;
}

.Chip-overlay-subtleFilled:active, .Chip:active .Chip-overlay, .Chip-content-container:active + .Chip-overlay-subtleFilled {
  opacity: 1;
  background: #5c5e6e1a;
}

.Chip-outlined.Chip--brandPrimary {
  border: 1px solid var(--color-border-primary-subtle, #6e7fff);
}

.Chip-outlined.Chip--brandPrimary .Chip-label, .Chip-outlined.Chip--brandPrimary .Icon svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
  color: var(--color-foreground-primary-medium, #3751ff);
}

.Chip-outlined.Chip--brandPrimary:hover {
  border: 1px solid var(--color-border-primary-medium, #3751ff);
}

.Chip--isDisabled.Chip-outlined.Chip--brandPrimary:hover {
  border-color: var(--color-border-primary-subtle, #6e7fff);
}

.Chip-outlined .Chip-overlay--brandPrimary:hover, .Chip-outlined.Chip:hover .Chip-overlay--brandPrimary, .Chip-outlined .Chip-content-container:hover + .Chip-overlay--brandPrimary {
  opacity: 1;
  background: #3751ff14;
}

.Chip-outlined.Chip--brandSecondary {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.Chip-outlined.Chip--brandSecondary .Chip-label, .Chip-outlined.Chip--brandSecondary .Icon svg path {
  fill: var(--color-foreground-secondary-medium, #254b86);
  color: var(--color-foreground-secondary-medium, #254b86);
}

.Chip-outlined.Chip--brandSecondary:hover {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
}

.Chip-outlined .Chip-overlay--brandSecondary:hover, .Chip-outlined.Chip:hover .Chip-overlay--brandSecondary, .Chip-outlined .Chip-content-container:hover + .Chip-overlay--brandSecondary {
  opacity: 1;
  background: #254b8614;
}

.Chip-outlined.Chip--critical {
  border: 1px solid var(--color-border-critical-subtle, #ef5955);
}

.Chip-outlined.Chip--critical .Chip-label, .Chip-outlined.Chip--critical .Icon svg path {
  fill: var(--color-foreground-critical-medium, #cc1c10);
  color: var(--color-foreground-critical-medium, #cc1c10);
}

.Chip-outlined.Chip--critical:hover {
  border: 1px solid var(--color-foreground-critical-medium, #cc1c10);
}

.Chip--isDisabled.Chip-outlined.Chip--critical:hover {
  border-color: var(--color-border-critical-subtle, #ef5955);
}

.Chip-outlined .Chip-overlay--critical:hover, .Chip-outlined.Chip:hover .Chip-overlay--critical, .Chip-outlined .Chip-content-container:hover + .Chip-overlay--critical {
  opacity: 1;
  background: #cc1c1014;
}

.Chip-outlined.Chip--success {
  border: 1px solid var(--color-border-success-subtle, #43a34d);
}

.Chip-outlined.Chip--success .Chip-label, .Chip-outlined.Chip--success .Icon svg path {
  fill: var(--color-foreground-success-medium, #028329);
  color: var(--color-foreground-success-medium, #028329);
}

.Chip-outlined.Chip--success:hover {
  border: 1px solid var(--color-border-success-medium, #028329);
}

.Chip--isDisabled.Chip-outlined.Chip--success:hover {
  border-color: var(--color-border-success-subtle, #43a34d);
}

.Chip-outlined .Chip-overlay--success:hover, .Chip-outlined.Chip:hover .Chip-overlay--success, .Chip-outlined .Chip-content-container:hover + .Chip-overlay--success {
  opacity: 1;
  background: #02832914;
}

.Chip-outlined.Chip--warning {
  border: 1px solid var(--color-border-warning-subtle, #f6a91c);
}

.Chip-outlined.Chip--warning .Chip-label, .Chip-outlined.Chip--warning .Icon svg path {
  fill: var(--color-foreground-warning-medium, #a36a00);
  color: var(--color-foreground-warning-medium, #a36a00);
}

.Chip-outlined.Chip--warning:hover {
  border: 1px solid var(--color-border-warning-medium, #a36a00);
}

.Chip--isDisabled.Chip-outlined.Chip--warning:hover {
  border-color: var(--color-border-warning-subtle, #f6a91c);
}

.Chip-outlined .Chip-overlay--warning:hover, .Chip-outlined.Chip:hover .Chip-overlay--warning, .Chip-outlined .Chip-content-container:hover + .Chip-overlay--warning {
  opacity: 1;
  background: #e1970114;
}

.Chip-outlined.Chip--info {
  border: 1px solid var(--color-border-info-subtle, #9574f1);
}

.Chip-outlined.Chip--info .Chip-label, .Chip-outlined.Chip--info .Icon svg path {
  fill: var(--color-foreground-info-medium, #573bdc);
  color: var(--color-foreground-info-medium, #573bdc);
}

.Chip-outlined.Chip--info:hover {
  border: 1px solid var(--color-border-info-medium, #573bdc);
}

.Chip--isDisabled.Chip-outlined.Chip--info:hover {
  border-color: var(--color-border-info-subtle, #9574f1);
}

.Chip-outlined .Chip-overlay--info:hover, .Chip-outlined.Chip:hover .Chip-overlay--info, .Chip-outlined .Chip-content-container:hover + .Chip-overlay--info {
  opacity: 1;
  background: #573bdc14;
}

.Chip-boldFilled.Chip--brandPrimary {
  background: var(--color-background-primary-bold, #3751ff);
}

.Chip-boldFilled.Chip--brandSecondary {
  background: var(--color-background-secondary-bold, #254b86);
}

.Chip-boldFilled.Chip--critical {
  background: var(--color-background-critical-bold, #cc1c10);
}

.Chip-boldFilled.Chip--success {
  background: var(--color-background-success-bold, #028329);
}

.Chip-boldFilled.Chip--warning {
  background: var(--color-background-warning-bold, #f6a91c);
}

.Chip-boldFilled.Chip-boldFilled.Chip--warning .Chip-label, .Chip-boldFilled.Chip-boldFilled.Chip--warning .Icon svg path {
  fill: var(--color-foreground-neutral-bold, #212328);
  color: var(--color-foreground-neutral-bold, #212328);
}

.Chip-boldFilled.Chip--info {
  background: var(--color-background-info-bold, #573bdc);
}

.Chip-boldFilled.Chip-boldFilled, .Chip-boldFilled.Chip-boldFilled:hover {
  border: 1px solid #0000;
}

.Chip-boldFilled.Chip-boldFilled .Chip-label, .Chip-boldFilled.Chip-boldFilled .Icon svg path {
  fill: var(--color-background-neutral-subtlest, #fff);
  color: var(--color-background-neutral-subtlest, #fff);
}

.Chip-boldFilled.Chip-boldFilled .Chip-overlay:hover, .Chip-boldFilled.Chip:hover .Chip-overlay, .Chip-boldFilled.Chip-boldFilled .Chip-content-container:hover + .Chip-overlay {
  opacity: 1;
  background: #ffffff3d;
}

.Chip-subtleFilled.Chip--brandPrimary {
  background: var(--color-background-primary-subtle, #ebedff);
}

.Chip-subtleFilled.Chip--brandPrimary .Chip-label, .Chip-subtleFilled.Chip--brandPrimary .Icon svg path {
  fill: var(--color-foreground-primary-bold, #032185);
  color: var(--color-foreground-primary-bold, #032185);
}

.Chip-subtleFilled .Chip-overlay--brandPrimary:hover, .Chip-subtleFilled.Chip:hover .Chip-overlay--brandPrimary, .Chip-subtleFilled .Chip-content-container:hover + .Chip-overlay--brandPrimary {
  opacity: 1;
  background: #3751ff14;
}

.Chip-subtleFilled.Chip--brandSecondary {
  background: var(--color-background-secondary-subtle, #f2f4f8);
}

.Chip-subtleFilled.Chip--brandSecondary .Chip-label, .Chip-subtleFilled.Chip--brandSecondary .Icon svg path {
  fill: var(--color-foreground-secondary-bold, #00245b);
  color: var(--color-foreground-secondary-bold, #00245b);
}

.Chip-subtleFilled .Chip-overlay--brandSecondary:hover, .Chip-subtleFilled.Chip:hover .Chip-overlay--brandSecondary, .Chip-subtleFilled .Chip-content-container:hover + .Chip-overlay--brandSecondary {
  opacity: 1;
  background: #254b8614;
}

.Chip-subtleFilled.Chip--critical {
  background: var(--color-background-critical-subtle, #fdeeed);
}

.Chip-subtleFilled.Chip--critical .Chip-label, .Chip-subtleFilled.Chip--critical .Icon svg path {
  fill: var(--color-foreground-critical-bold, #700600);
  color: var(--color-foreground-critical-bold, #700600);
}

.Chip-subtleFilled .Chip-overlay--critical:hover, .Chip-subtleFilled.Chip:hover .Chip-overlay--critical, .Chip-subtleFilled .Chip-content-container:hover + .Chip-overlay--critical {
  opacity: 1;
  background: #cc1c1014;
}

.Chip-subtleFilled.Chip--success {
  background: var(--color-background-success-subtle, #e5f7e0);
}

.Chip-subtleFilled.Chip--success .Chip-label, .Chip-subtleFilled.Chip--success .Icon svg path {
  fill: var(--color-foreground-success-bold, #033a0f);
  color: var(--color-foreground-success-bold, #033a0f);
}

.Chip-subtleFilled .Chip-overlay--success:hover, .Chip-subtleFilled.Chip:hover .Chip-overlay--success, .Chip-subtleFilled .Chip-content-container:hover + .Chip-overlay--success {
  opacity: 1;
  background: #02832914;
}

.Chip-subtleFilled.Chip--warning {
  background: var(--color-background-warning-subtle, #fff1d9);
}

.Chip-subtleFilled.Chip--warning .Chip-label, .Chip-subtleFilled.Chip--warning .Icon svg path {
  fill: var(--color-foreground-warning-bold, #5c3c00);
  color: var(--color-foreground-warning-bold, #5c3c00);
}

.Chip-subtleFilled .Chip-overlay--warning:hover, .Chip-subtleFilled.Chip:hover .Chip-overlay--warning, .Chip-subtleFilled .Chip-content-container:hover + .Chip-overlay--warning {
  opacity: 1;
  background: #e1970114;
}

.Chip-subtleFilled.Chip--info {
  background: var(--color-background-info-subtle, #f1ecfd);
}

.Chip-subtleFilled.Chip--info .Chip-label, .Chip-subtleFilled.Chip--info .Icon svg path {
  fill: var(--color-foreground-info-bold, #332196);
  color: var(--color-foreground-info-bold, #332196);
}

.Chip-subtleFilled .Chip-overlay--info:hover, .Chip-subtleFilled.Chip:hover .Chip-overlay--info, .Chip-subtleFilled .Chip-content-container:hover + .Chip-overlay--info {
  opacity: 1;
  background: #573bdc14;
}

.Chip-subtleFilled.Chip-subtleFilled, .Chip-subtleFilled.Chip-subtleFilled:hover {
  border: 1px solid #0000;
}

.Chip--isDisabled, .Chip--isDisabled .Chip-label, .Chip--isDisabled:hover {
  cursor: not-allowed;
}

.Chip--isDisabled .Chip-overlay, .Chip--isDisabled .Chip-content-container {
  pointer-events: none;
}

.Chip--isDisabled:hover .Chip-content-container + .Chip-overlay {
  background: none;
}

.Chip--isDisabled.Chip-outlined--isDisabled .Chip-label, .Chip--isDisabled.Chip-outlined--isDisabled .Icon svg path {
  fill: var(--color-foreground-neutral-subtle, #717385);
  color: var(--color-foreground-neutral-subtle, #717385);
}

.Chip--isDisabled.Chip-outlined--isDisabled, .Chip--isDisabled.Chip-outlined--isDisabled:hover {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  border: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
}

.Chip--isDisabled.Chip-boldFilled--isDisabled .Chip-label, .Chip--isDisabled.Chip-boldFilled--isDisabled .Icon svg path {
  fill: var(--color-foreground-neutral-subtle, #717385);
  color: var(--color-foreground-neutral-subtle, #717385);
}

.Chip--isDisabled.Chip-boldFilled--isDisabled, .Chip--isDisabled.Chip-boldFilled--isDisabled:hover {
  background: var(--color-background-neutral-disabled-medium, #cecfd9);
  border: none;
}

.Chip--isDisabled.Chip-subtleFilled--isDisabled .Chip-label, .Chip--isDisabled.Chip-subtleFilled--isDisabled .Icon svg path {
  fill: var(--color-foreground-neutral-subtle, #717385);
  color: var(--color-foreground-neutral-subtle, #717385);
}

.Chip--isDisabled.Chip-subtleFilled--isDisabled, .Chip--isDisabled.Chip-subtleFilled--isDisabled:hover {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  border: none;
}

.DatePicker-wrapper.DatePicker-wrapper {
  width: calc(100% - 2.3125rem);
}

.DatePicker-calendar.DatePicker-calendar {
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  border-radius: var(--border-width-xlg);
  font: var(--typography-body-2);
  border: none;
}

.DatePicker-input.DatePicker-input {
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
  color: var(--color-foreground-secondary-bold, #00245b);
  text-overflow: ellipsis;
  width: 100%;
  transition: border .1s ease-in-out;
}

.DatePicker-input--large.DatePicker-input--large {
  font: var(--typography-body-2);
  height: 3rem;
  padding: .875rem 2.25rem .875rem .5rem;
}

.DatePicker-input--secondary.DatePicker-input--secondary {
  background: var(--color-background-secondary-subtlest, #f7f9fc);
}

.DatePicker-input.DatePicker-input.DatePicker-input-noOutline:focus, .DateAndTimeInputField-suffixButton.DateAndTimeInputField-suffixButton:focus {
  outline: none;
}

.DatePicker-input.DatePicker-input:focus {
  outline: 2px solid var(--color-border-focus, #6e7fff);
}

.DatePicker-input.DatePicker-input:hover {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.DatePicker-input--isCritical.DatePicker-input--isCritical, .DatePicker-input--isCritical.DatePicker-input--isCritical:focus, .DatePicker-input--isCritical.DatePicker-input--isCritical:focus:hover, .DatePicker-input--isCritical.DatePicker-input--isCritical:hover {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}

.DatePicker-input--isDisabled.DatePicker-input--isDisabled {
  pointer-events: none;
  color: var(--color-foreground-neutral-subtle, #717385);
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  border: 1px solid #0000;
}

.DateAndTimeInputField-inputContainer--isReadOnly.DateAndTimeInputField-inputContainer--isReadOnly {
  height: 1.5rem;
}

.DateAndTimeInputField-inputContainer--isReadOnly.DateAndTimeInputField-inputContainer--isReadOnly .DateAndTimeInputField-suffixButton {
  display: none;
}

.DatePicker-input--isReadOnly.DatePicker-input--isReadOnly {
  pointer-events: none;
  border: 1px solid #0000;
  height: 1.5rem;
  padding: 0;
}

.DatePicker-input--isReadOnly.DatePicker-input--isReadOnly + .react-datepicker__close-icon.react-datepicker__close-icon, .DatePicker-input--isDisabled.DatePicker-input--isDisabled + .react-datepicker__close-icon.react-datepicker__close-icon {
  display: none;
}

.react-datepicker__navigation.react-datepicker__navigation {
  border-radius: var(--border-radius-circle, 1584px);
  border: none;
  width: 2rem;
  height: 2rem;
  transition: all .1s ease-in-out;
  top: 1.0625rem;
}

.react-datepicker__navigation.react-datepicker__navigation:hover {
  box-shadow: var(--box-shadow-pretoken-button-interaction);
  background-color: #5c5e6e1f;
}

.react-datepicker__navigation--previous.react-datepicker__navigation--previous {
  left: 11.75rem;
}

.react-datepicker__navigation--next.react-datepicker__navigation--next {
  left: 14.75rem;
}

.react-datepicker__navigation-icon.react-datepicker__navigation-icon:before {
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20id%3D%22chevron-down%22%3E%0A%3Cpath%20id%3D%22Vector%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3.14645%207.39669C3.34171%207.20143%203.65829%207.20143%203.85355%207.39669L12%2015.5431L20.1464%207.39669C20.3417%207.20143%2020.6583%207.20143%2020.8536%207.39669C21.0488%207.59195%2021.0488%207.90854%2020.8536%208.1038L12.3536%2016.6038C12.1583%2016.7991%2011.8417%2016.7991%2011.6464%2016.6038L3.14645%208.1038C2.95118%207.90854%202.95118%207.59195%203.14645%207.39669Z%22%20fill%3D%22%23212328%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  width: 1.25rem;
  height: 1.25rem;
  top: .125rem;
  right: -.5rem;
  transform: rotate(90deg);
}

.react-datepicker__navigation--next.react-datepicker__navigation--next .react-datepicker__navigation-icon:before {
  transform: rotate(-90deg);
}

.react-datepicker__header.react-datepicker__header, .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-width-xlg);
  border: none;
  padding: 0;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown {
  height: 3.5rem;
  padding: 1rem 1rem .5rem .5rem;
  display: flex;
}

.react-datepicker__day-names.react-datepicker__day-names {
  white-space: wrap;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.react-datepicker__day-name.react-datepicker__day-name {
  font: var(--typography-body-3);
  color: var(--color-foreground-neutral-subtle, #717385);
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.5rem;
  margin: 0;
  display: flex;
}

.react-datepicker__week.react-datepicker__week {
  border-radius: .25rem;
  display: flex;
  overflow: hidden;
}

.react-datepicker__day.react-datepicker__day {
  font: var(--typography-body-2);
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: 1.125rem;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  margin: 0;
  transition: all .1s ease-in-out;
  display: flex;
}

.react-datepicker__day--today.react-datepicker__day--today {
  font: var(--typography-body-2);
  background: var(--color-background-neutral-subtlest, #fff);
  color: var(--color-foreground-neutral-bold, #212328);
  border: 1px solid var(--color-border-neutral-sublte, #5c5e6e);
  border-radius: 1.125rem;
}

.react-datepicker__day--today.react-datepicker__day--today:hover {
  background: var(--color-background-secondary-bold, #254b86);
  color: var(--color-foreground-neutral-inverse, #fff);
  border: 1px solid #0000;
}

.react-datepicker__day--selected.react-datepicker__day--selected {
  font: var(--typography-body-bold-2);
  color: var(--color-foreground-neutral-inverse, #fff);
  background: var(--color-background-secondary-bold, #254b86);
  border: none;
}

.react-datepicker__day--selected.react-datepicker__day--selected:hover {
  background: var(--color-background-secondary-bold, #254b86);
}

.react-datepicker__day.react-datepicker__day:hover {
  border-radius: 1.125rem;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  margin: 0;
  display: flex;
}

.react-datepicker__day--disabled.react-datepicker__day--disabled {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-subtle, #717385);
  pointer-events: none;
}

.react-datepicker__day--in-selecting-range.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__day.react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-bold, #212328);
  background: none;
  border-radius: 0;
}

.react-datepicker__day--in-selecting-range.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range.react-datepicker__day--in-range {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-bold, #212328);
  background: #254b861a;
  border-radius: 0;
}

.react-datepicker__day--in-selecting-range.react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range.react-datepicker__day--in-range:hover {
  color: var(--color-foreground-neutral-inverse, #fff);
  background: var(--color-background-secondary-bold, #254b86);
  border-radius: 0;
}

.react-datepicker__day--selecting-range-end.react-datepicker__day--selecting-range-end.react-datepicker__day--selecting-range-end, .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end.react-datepicker__day--selecting-range-end:hover {
  font: var(--typography-body-bold-2);
  color: var(--color-foreground-neutral-inverse, #fff);
  background: var(--color-background-secondary-bold, #254b86);
  border-radius: 0;
}

.react-datepicker__month--selecting-range.react-datepicker__month--selecting-range .react-datepicker__day--selected.react-datepicker__day--range-end.react-datepicker__day--in-range.react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
  background: #254b861a;
  border-radius: 0;
}

.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-start {
  font: var(--typography-body-bold-2);
  color: var(--color-foreground-neutral-inverse, #fff);
  background: var(--color-background-secondary-bold, #254b86);
  border-radius: 0;
}

.react-datepicker__day--selected.react-datepicker__day--selected.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-start:hover, .react-datepicker__day--selected.react-datepicker__day--selected.react-datepicker__day--selecting-range-end.react-datepicker__day--selecting-range-end:hover {
  font: var(--typography-body-bold-2);
}

.react-datepicker__month-year-dropdown-container.react-datepicker__month-year-dropdown-container {
  border-radius: var(--border-radius-circle, 99rem);
  align-items: center;
  margin: 0;
  padding: .5rem 2rem .5rem .5rem;
  display: flex;
}

.react-datepicker__month-year-dropdown-container.react-datepicker__month-year-dropdown-container:hover {
  box-shadow: var(--box-shadow-pretoken-button-interaction);
  background-color: #5c5e6e1f;
}

.react-datepicker__month-year-read-view.react-datepicker__month-year-read-view {
  color: var(--color-neutral-1000, #212328);
  font: var(--typography-body-bold-1);
  visibility: visible !important;
}

.react-datepicker__month-year-dropdown.react-datepicker__month-year-dropdown {
  text-align: left;
  font: var(--typography-body-2);
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
  border: none;
  max-height: 15rem;
  margin-top: 1rem;
  padding: .25rem;
  transition: all .1s ease-in-out;
  left: 1rem;
  overflow-y: scroll;
}

.react-datepicker__month-year-dropdown.react-datepicker__month-year-dropdown::-webkit-scrollbar {
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  background: var(--color-background-neutral-subtlest, #fff);
  width: .5rem;
  padding: .25rem;
}

.react-datepicker__month-year-dropdown.react-datepicker__month-year-dropdown::-webkit-scrollbar-thumb {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  border-radius: var(--border-width-xlg, .1875rem);
}

.react-datepicker__month-year-option.react-datepicker__month-year-option {
  border-radius: var(--border-radius-sm);
  padding: .25rem .5rem;
  transition: all .1s ease-in-out;
}

.react-datepicker__month-year-option.react-datepicker__month-year-option:hover {
  background: #3751ff14;
}

.react-datepicker__month-year-option.react-datepicker__month-year-option:active {
  background: #3751ff1a;
}

.react-datepicker__month-year-option--selected_month-year.react-datepicker__month-year-option--selected_month-year {
  font: var(--typography-body-bold-2);
  border-radius: var(--border-radius-sm);
  background: #3751ff1a;
  padding: .25rem .5rem;
}

.react-datepicker__month-year-option--selected_month-year.react-datepicker__month-year-option--selected_month-year:hover {
  background: #3751ff26;
}

.react-datepicker__month-year-option--selected.react-datepicker__month-year-option--selected {
  display: none;
}

.react-datepicker__month-year-read-view--down-arrow.react-datepicker__month-year-read-view--down-arrow {
  content: "";
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20id%3D%22chevron-down%22%3E%0A%3Cpath%20id%3D%22Vector%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3.14645%207.39669C3.34171%207.20143%203.65829%207.20143%203.85355%207.39669L12%2015.5431L20.1464%207.39669C20.3417%207.20143%2020.6583%207.20143%2020.8536%207.39669C21.0488%207.59195%2021.0488%207.90854%2020.8536%208.1038L12.3536%2016.6038C12.1583%2016.7991%2011.8417%2016.7991%2011.6464%2016.6038L3.14645%208.1038C2.95118%207.90854%202.95118%207.59195%203.14645%207.39669Z%22%20fill%3D%22%23212328%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
  background-size: contain;
  border: 0 solid #0000;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: .125rem;
  right: -1.5rem;
  transform: translateY(0);
}

.react-datepicker__current-month.react-datepicker__current-month {
  display: none;
}

.react-datepicker__month.react-datepicker__month {
  flex-direction: column;
  gap: .125rem;
  margin: .75rem 1rem 1rem;
  display: flex;
}

.react-datepicker__close-icon.react-datepicker__close-icon {
  border-radius: var(--border-radius-circle, 1584px);
  opacity: 0;
  height: min-content;
  margin-top: .75rem;
  margin-right: .5rem;
  padding: .25rem;
  transition: all .1s ease-in-out;
}

.react-datepicker__close-icon.react-datepicker__close-icon:hover {
  box-shadow: var(--box-shadow-pretoken-button-interaction);
  opacity: 1;
  background-color: #5c5e6e1f;
}

.react-datepicker__close-icon.react-datepicker__close-icon:after {
  content: "";
  background-color: #0000;
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22%23212328%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20id%3D%22close%22%3E%0A%3Cpath%20id%3D%22Vector%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2.62204%202.62225C2.78476%202.45953%203.04858%202.45953%203.21129%202.62225L10%209.41096L16.7887%202.6223C16.9514%202.45958%2017.2152%202.45958%2017.3779%202.6223C17.5407%202.78502%2017.5407%203.04884%2017.3779%203.21156L10.5893%2010.0002L17.3779%2016.7889C17.5407%2016.9516%2017.5407%2017.2154%2017.3779%2017.3781C17.2152%2017.5408%2016.9514%2017.5408%2016.7887%2017.3781L10%2010.5895L3.21129%2017.3782C3.04858%2017.5409%202.78476%2017.5409%202.62204%2017.3782C2.45932%2017.2155%202.45932%2016.9516%202.62204%2016.7889L9.41076%2010.0002L2.62204%203.21151C2.45932%203.04879%202.45932%202.78497%202.62204%202.62225Z%22%20fill%3D%22%23212328%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
  background-size: contain;
  width: 1rem;
  height: 1rem;
  padding: 0;
}

.DateAndTimeInputField-inputContainer.DateAndTimeInputField-inputContainer:hover .react-datepicker__close-icon.react-datepicker__close-icon, .DateAndTimeInputField-inputContainer.DateAndTimeInputField-inputContainer:focus-within .react-datepicker__close-icon.react-datepicker__close-icon {
  opacity: 1;
}

.react-datepicker__close-icon.react-datepicker__close-icon[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
  outline-offset: .125rem;
  opacity: 1;
}

.DateAndTimeInputField-suffixButton {
  all: unset;
  box-sizing: border-box;
  border: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  cursor: pointer;
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  isolation: isolate;
  background: none;
  border-left: 1px solid #0000;
  align-items: center;
  height: 3rem;
  padding: .75rem .5rem;
  transition: all .1s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.DateAndTimeInputField-suffixButton-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.DateAndTimeInputField-suffixButton--isReadOnly {
  pointer-events: none;
  background: var(--color-background-neutral-subtlest, #fff);
  border: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
}

.DateAndTimeInputField-suffixButton:hover {
  border: 1px solid var(--color-border-secondary-subtle, #cecfd9);
  background: var(--color-background-neutral-subtlest, #fff);
}

.DateAndTimeInputField-suffixButton-overlay {
  z-index: -1;
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  opacity: 0;
  background: none;
  border: 1px solid #0000;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transition: all .1s ease-in-out;
  position: absolute;
  top: -1px;
  right: -1px;
}

.DateAndTimeInputField-suffixButton-icon {
  transition: all .1s ease-in-out;
}

.DateAndTimeInputField-suffixButton-overlay:hover, .DateAndTimeInputField-suffixButton:hover .DateAndTimeInputField-suffixButton-overlay, .DateAndTimeInputField-suffixButton-icon:hover + .DateAndTimeInputField-suffixButton-overlay {
  opacity: 1;
  background: #3751ff14;
}

.DateAndTimeInputField-suffixButton-overlay:active, .DateAndTimeInputField-suffixButton:active .DateAndTimeInputField-suffixButton-overlay, .DateAndTimeInputField-suffixButton-icon:active + .DateAndTimeInputField-suffixButton-overlay {
  opacity: 1;
  background: #3751ff1a;
}

.DateAndTimeInputField-suffixButton[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
}

.DateAndTimeInputField-suffixButton--isCritical {
  border: 1px solid var(--color-foreground-critical-medium);
  border-left: 1px solid #0000;
}

.DateAndTimeInputField-suffixButton--isCritical:hover {
  border: 1px solid var(--color-foreground-critical-medium);
}

.DateAndTimeInputField-suffixButton:disabled, .DateAndTimeInputField-suffixButton-overlay--isDisabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  cursor: not-allowed;
  border: 1px solid #0000;
}

.DateAndTimeInputField-suffixButton-neutral--isDisabled, .DateAndTimeInputField-suffixButton-secondary--isDisabled {
  border: 1px solid var(--color-foreground-neutral-subtle);
  border-left: 1px solid #0000;
}

.DateAndTimeInputField-suffixButton-overlay:hover, .DateAndTimeInputField-suffixButton:disabled:hover .DateAndTimeInputField-suffixButton-overlay--isDisabled, .DateAndTimeInputField-suffixButton-icon:hover + .DateAndTimeInputField-suffixButton-overlay--isDisabled {
  opacity: 0;
}

.react-datepicker--time-only {
  border-radius: var(--border-width-xlg, .1875rem);
  width: 5.75rem;
  overflow: hidden;
}

.react-datepicker__time {
  font: var(--typography-body-2);
  border-radius: var(--border-width-xlg, .1875rem);
  width: max-content;
}

.react-datepicker__header--time--only .react-datepicker__time-container, .react-datepicker__time-container {
  float: left;
  border-radius: var(--border-width-xlg, .1875rem);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  background: var(--color-background-neutral-subtlest, #fff);
  width: 100%;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  flex-direction: column;
  width: max-content;
  height: 100%;
  max-height: 19rem;
  padding-top: .25rem;
  display: flex;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar {
  background: var(--color-background-neutral-subtlest, #fff);
  width: .5rem;
  padding: .25rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  border-radius: var(--border-width-xlg, .1875rem);
}

.react-datepicker__time-container.react-datepicker__header, .react-datepicker__time-container.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  display: none;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  border-radius: var(--border-width-xlg, .1875rem);
  width: calc(100% - .5rem);
  margin: 0 .25rem;
  padding: .25rem .5rem;
  transition: all .1s ease-in-out;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background: #3751ff14;
}

.react-datepicker__time-list-item:active {
  background: #3751ff1a;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  font: var(--typography-body-bold-2);
  color: var(--color-foreground-neutral-bold, #212328);
  background: #3751ff1a;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover, .react-datepicker__time-list-item:active {
  background: #3751ff14;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.react-datepicker__time-container {
  border-left: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  border-radius: 0 var(--border-width-xlg) 0 0;
}

.react-datepicker-time__header {
  background: var(--color-background-neutral-subtlest, #fff);
  border-bottom: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  border-radius: 0 var(--border-width-xlg) 0 0;
  font: var(--typography-body-bold-1);
  color: var(--color-neutral-1000, #212328);
  justify-content: center;
  align-items: center;
  width: calc(100% + .5rem);
  height: 3.5rem;
  display: flex;
}

.react-datepicker__header--time--only .react-datepicker-time__header {
  display: none;
}

.DateAndTimeInputField-container.DateAndTimeInputField-container {
  flex-direction: column;
  gap: .25rem;
  width: 100%;
  height: min-content;
  display: flex;
}

.DateAndTimeInputField-inputContainer.DateAndTimeInputField-inputContainer {
  margin-bottom: 0;
  position: relative;
}

.DateAndTimeInputField-inputContainer--isDisabled.DateAndTimeInputField-inputContainer--isDisabled {
  cursor: not-allowed;
}

.DateAndTimeInputField-labelContainer.DateAndTimeInputField-labelContainer {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-heading-4);
}

.react-datepicker-popper.react-datepicker-popper {
  z-index: var(--z-index-react-date-picker-popper);
}

.DateAndTimeInputField-input--isCritical.DateAndTimeInputField-input--isCritical, .DateAndTimeInputField-input--isCritical.DateAndTimeInputField-input--isCritical:focus, .DateAndTimeInputField-input--isCritical.DateAndTimeInputField-input--isCritical:focus:hover, .DateAndTimeInputField-input--isCritical.DateAndTimeInputField-input--isCritical:hover {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}

.DateAndTimeInputField--isRequired--isCritical.DateAndTimeInputField--isRequired--isCritical, .DateAndTimeInputField--isRequired--isWarning.DateAndTimeInputField--isRequired--isWarning {
  font: var(--typography-heading-3);
  position: absolute;
  top: -.25rem;
  left: -.25rem;
}

.DateAndTimeInputField--isRequired--isWarning.DateAndTimeInputField--isRequired--isWarning {
  color: var(--color-foreground-warning-medium);
}

.DateAndTimeInputField--isRequired--isCritical.DateAndTimeInputField--isRequired--isCritical {
  color: var(--color-foreground-critical-medium);
}

.DateAndTimeInputField--isRequired.DateAndTimeInputField--isRequired {
  position: relative;
  top: 5px;
  right: 5px;
}

.DateAndTimeInputField-errorMessage.DateAndTimeInputField-errorMessage {
  color: var(--color-border-critical-medium, #cc1c10);
}

.react-datepicker--time-only.react-datepicker--time-only {
  border-radius: var(--border-width-xlg, .1875rem);
  width: 5.75rem;
  overflow: hidden;
}

.react-datepicker__time.react-datepicker__time {
  font: var(--typography-body-2);
  border-radius: var(--border-width-xlg, .1875rem);
  width: max-content;
}

.react-datepicker__header--time--only.react-datepicker__header--time--only .react-datepicker__time-container, .react-datepicker__time-container.react-datepicker__time-container {
  float: left;
  border-radius: var(--border-width-xlg, .1875rem);
}

.react-datepicker__time-container.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  background: #fff;
  width: 100%;
}

.react-datepicker__time-container.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  flex-direction: column;
  width: max-content;
  height: 100%;
  max-height: 19rem;
  padding-top: .25rem;
  display: flex;
}

.react-datepicker__time-container.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar {
  background-color: #fff;
  width: .5rem;
  padding: .25rem;
}

.react-datepicker__time-container.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  border-radius: var(--border-width-xlg, .1875rem);
}

.react-datepicker__time-container.react-datepicker__time-container.react-datepicker__header, .react-datepicker__time-container.react-datepicker__time-container.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  display: none;
}

.react-datepicker__time-container.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  border-radius: var(--border-width-xlg, .1875rem);
  width: calc(100% - .5rem);
  margin: 0 .25rem;
  padding: .25rem .5rem;
  transition: all .1s ease-in-out;
}

.react-datepicker__time-container.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background: #3751ff14;
}

.react-datepicker__time-list-item.react-datepicker__time-list-item:active {
  background: #3751ff1a;
}

.react-datepicker__time-container.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  font: var(--typography-body-bold-2);
  color: var(--color-foreground-neutral-bold, #212328);
  background: #3751ff1a;
}

.react-datepicker__time-container.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover, .react-datepicker__time-list-item:active {
  background: #3751ff14;
}

.react-datepicker__time-list-item--disabled.react-datepicker__time-list-item--disabled {
  display: none;
}

.react-datepicker__time-container.react-datepicker__time-container {
  border-left: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  border-radius: 0 var(--border-width-xlg) 0 0;
}

.react-datepicker-time__header.react-datepicker-time__header {
  border-bottom: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  border-radius: 0 var(--border-width-xlg) 0 0;
  font: var(--typography-body-bold-1);
  color: var(--color-neutral-1000, #212328);
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: calc(100% + .5rem);
  height: 3.5rem;
  display: flex;
}

.react-datepicker__header--time--only.react-datepicker__header--time--only .react-datepicker-time__header {
  display: none;
}

.DateAndTimeInputField-inputContainer--isReadOnly.DateAndTimeInputField-inputContainer--isReadOnly input {
  background-color: #0000;
  border: none;
}

.DatePicker-input::placeholder {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-subtle, #717385);
}

.FooterActionBar {
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  background: var(--color-background-neutral-subtlest, #fff);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: .75rem 1.5rem;
  display: flex;
}

.FooterActionBar--alignRight {
  justify-content: flex-end;
}

.FooterActionBar--isScrolled {
  box-shadow: var(--box-shadow-pretoken-overlay-top);
  border-top: 1px solid #0000;
}

.FooterActionBar--large {
  height: 4rem;
}

.FooterActionBar--small {
  height: 3rem;
}

.Grid-item.Grid-item--desktop-12 {
  grid-column-end: span 12;
}

.Grid-item.Grid-item--desktop-11 {
  grid-column-end: span 11;
}

.Grid-item.Grid-item--desktop-10 {
  grid-column-end: span 10;
}

.Grid-item.Grid-item--desktop-9 {
  grid-column-end: span 9;
}

.Grid-item.Grid-item--desktop-8 {
  grid-column-end: span 8;
}

.Grid-item.Grid-item--desktop-7 {
  grid-column-end: span 7;
}

.Grid-item.Grid-item--desktop-6 {
  grid-column-end: span 6;
}

.Grid-item.Grid-item--desktop-5 {
  grid-column-end: span 5;
}

.Grid-item.Grid-item--desktop-4 {
  grid-column-end: span 4;
}

.Grid-item.Grid-item--desktop-3 {
  grid-column-end: span 3;
}

.Grid-item.Grid-item--desktop-2 {
  grid-column-end: span 2;
}

.Grid-item.Grid-item--desktop-1 {
  grid-column-end: span 1;
}

.Grid-item.Grid-item--desktop-0 {
  display: none;
}

@media (width <= 1024px) {
  .Grid-item.Grid-item--tablet-12, .Grid-item.Grid-item--tablet-11, .Grid-item.Grid-item--tablet-10, .Grid-item.Grid-item--tablet-9, .Grid-item.Grid-item--tablet-8, .Grid-item.Grid-item--tablet-7, .Grid-item.Grid-item--tablet-6, .Grid-item.Grid-item--tablet-5, .Grid-item.Grid-item--tablet-4, .Grid-item.Grid-item--tablet-3, .Grid-item.Grid-item--tablet-2, .Grid-item.Grid-item--tablet-1 {
    display: initial;
    grid-column-end: span 1;
  }

  .Grid-item.Grid-item--tablet-12 {
    grid-column-end: span 12;
  }

  .Grid-item.Grid-item--tablet-11 {
    grid-column-end: span 11;
  }

  .Grid-item.Grid-item--tablet-10 {
    grid-column-end: span 10;
  }

  .Grid-item.Grid-item--tablet-9 {
    grid-column-end: span 9;
  }

  .Grid-item.Grid-item--tablet-8 {
    grid-column-end: span 8;
  }

  .Grid-item.Grid-item--tablet-7 {
    grid-column-end: span 7;
  }

  .Grid-item.Grid-item--tablet-6 {
    grid-column-end: span 6;
  }

  .Grid-item.Grid-item--tablet-5 {
    grid-column-end: span 5;
  }

  .Grid-item.Grid-item--tablet-4 {
    grid-column-end: span 4;
  }

  .Grid-item.Grid-item--tablet-3 {
    grid-column-end: span 3;
  }

  .Grid-item.Grid-item--tablet-2 {
    grid-column-end: span 2;
  }

  .Grid-item.Grid-item--tablet-1 {
    grid-column-end: span 1;
  }

  .Grid-item.Grid-item--tablet-0 {
    display: none;
  }
}

@media (width <= 600px) {
  .Grid-item.Grid-item--mobile-12, .Grid-item.Grid-item--mobile-11, .Grid-item.Grid-item--mobile-10, .Grid-item.Grid-item--mobile-9, .Grid-item.Grid-item--mobile-8, .Grid-item.Grid-item--mobile-7, .Grid-item.Grid-item--mobile-6, .Grid-item.Grid-item--mobile-5, .Grid-item.Grid-item--mobile-4, .Grid-item.Grid-item--mobile-3, .Grid-item.Grid-item--mobile-2, .Grid-item.Grid-item--mobile-1 {
    display: initial;
    grid-column-end: span 1;
  }

  .Grid-item.Grid-item--mobile-12 {
    grid-column-end: span 12;
  }

  .Grid-item.Grid-item--mobile-11 {
    grid-column-end: span 11;
  }

  .Grid-item.Grid-item--mobile-10 {
    grid-column-end: span 10;
  }

  .Grid-item.Grid-item--mobile-9 {
    grid-column-end: span 9;
  }

  .Grid-item.Grid-item--mobile-8 {
    grid-column-end: span 8;
  }

  .Grid-item.Grid-item--mobile-7 {
    grid-column-end: span 7;
  }

  .Grid-item.Grid-item--mobile-6 {
    grid-column-end: span 6;
  }

  .Grid-item.Grid-item--mobile-5 {
    grid-column-end: span 5;
  }

  .Grid-item.Grid-item--mobile-4 {
    grid-column-end: span 4;
  }

  .Grid-item.Grid-item--mobile-3 {
    grid-column-end: span 3;
  }

  .Grid-item.Grid-item--mobile-2 {
    grid-column-end: span 2;
  }

  .Grid-item.Grid-item--mobile-1 {
    grid-column-end: span 1;
  }

  .Grid-item.Grid-item--mobile-0 {
    display: none;
  }
}

.Grid {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  min-width: 375px;
  max-width: 1440px;
  display: grid;
}

.Grid-item > .Grid {
  min-width: initial;
}

.Logo {
  display: inline-block;
}

.Logo svg {
  width: 100%;
  height: 100%;
}

.Logo--click {
  cursor: pointer;
}

.Logo--click:focus {
  outline: 1px dotted;
}

.Header {
  border-bottom: .0625rem solid var(--color-border-neutral-decorative, #ebecf2);
  background: var(--color-background-neutral-subtlest, #fff);
  z-index: var(--z-index-page-header);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.5rem;
  padding: .75rem 3rem;
  display: flex;
}

.Header--scrolled {
  box-shadow: var(--box-shadow-pretoken-overlay-bottom);
  background: var(--color-background-neutral-subtlest, #fff);
  border-bottom: .0625rem solid #0000;
}

.Header-extraContent {
  flex-direction: column;
  align-items: flex-start;
  width: 6.448rem;
  height: 1.5rem;
  display: flex;
}

.Header-logo {
  width: 6.448rem;
  height: 1.5rem;
}

.Header-actionItems {
  justify-content: flex-end;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.Header-avatar:hover span {
  opacity: 1;
  background: #5c5e6e14;
  border: none;
}

.InlineMessage {
  justify-content: flex-start;
  align-items: center;
  padding: .25rem;
  display: flex;
}

.InlineMessage.InlineMessage--background {
  min-height: 2rem;
}

.InlineMessage-leftContent {
  width: 100%;
  display: flex;
}

.InlineMessage--small {
  font: var(--typography-body-3);
}

.InlineMessage--medium {
  font: var(--typography-body-2);
}

.InlineMessage-leftIconContainer, .InlineMessage-dismissContainer {
  align-self: flex-start;
}

.InlineMessage-textContainer {
  word-break: break-word;
}

.InlineMessage-leftIconContainer, .InlineMessage-textContainer, .InlineMessage-linkContainer {
  margin-right: .25rem;
}

.InlineMessage-dismissContainer {
  cursor: pointer;
}

.InlineMessage--small .InlineMessage-leftIconContainer, .InlineMessage--small .InlineMessage-leftIconContainer svg {
  width: 1rem;
  height: 1rem;
}

.InlineMessage--medium .InlineMessage-leftIconContainer, .InlineMessage--medium .InlineMessage-leftIconContainer svg {
  width: 1.25rem;
  height: 1.25rem;
}

.InlineMessage--small .InlineMessage-dismissContainer, .InlineMessage--small .InlineMessage-dismissContainer span, .InlineMessage--small .InlineMessage-dismissContainer svg {
  width: 1rem;
  height: 1rem;
}

.InlineMessage--medium .InlineMessage-dismissContainer, .InlineMessage--medium .InlineMessage-dismissContainer span, .InlineMessage--medium .InlineMessage-dismissContainer svg {
  width: 1.25rem;
  height: 1.25rem;
}

.InlineMessage--medium .InlineMessage-dismissContainer {
  transform: translate(0, 1px);
}

.InlineMessage--neutral, .InlineMessage--neutral .InlineMessage-leftIconContainer svg path {
  color: var(--color-foreground-neutral-medium, #4a4c59);
  fill: var(--color-foreground-neutral-medium, #4a4c59);
}

.InlineMessage--critical, .InlineMessage--critical .InlineMessage-leftIconContainer svg path {
  color: var(--color-foreground-critical-medium, #cc1c10);
  fill: var(--color-foreground-critical-medium, #cc1c10);
}

.InlineMessage--critical .Link .Link-wrapper {
  color: var(--color-foreground-critical-bold, #700600);
}

.InlineMessage--warning, .InlineMessage--warning .InlineMessage-leftIconContainer svg path {
  color: var(--color-foreground-warning-medium, #a36a00);
  fill: var(--color-foreground-warning-medium, #a36a00);
}

.InlineMessage--warning .Link .Link-wrapper {
  color: var(--color-foreground-warning-bold, #5c3c00);
}

.InlineMessage--success, .InlineMessage--success .InlineMessage-leftIconContainer svg path {
  color: var(--color-foreground-success-medium, #028329);
  fill: var(--color-foreground-success-medium, #028329);
}

.InlineMessage--success .Link .Link-wrapper {
  color: var(--color-foreground-success-bold, #033a0f);
}

.InlineMessage--info, .InlineMessage--info .InlineMessage-leftIconContainer svg path {
  color: var(--color-foreground-info-medium, #573bdc);
  fill: var(--color-foreground-info-medium, #573bdc);
}

.InlineMessage--info .Link .Link-wrapper {
  color: var(--color-foreground-info-bold, #332196);
}

.InlineMessage--disabled, .InlineMessage--disabled .InlineMessage-leftIconContainer svg path {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
  fill: var(--color-foreground-neutral-disabled, #9ea1b1);
}

.InlineMessage--critical.InlineMessage--background, .InlineMessage--critical .InlineMessage-linkContainer .Icon svg path {
  color: var(--color-foreground-critical-bold, #700600);
  fill: var(--color-foreground-critical-bold, #700600);
}

.InlineMessage--warning.InlineMessage--background, .InlineMessage--warning .InlineMessage-linkContainer .Icon svg path {
  color: var(--color-foreground-warning-bold, #5c3c00);
  fill: var(--color-foreground-warning-bold, #5c3c00);
}

.InlineMessage--success.InlineMessage--background, .InlineMessage--success .InlineMessage-linkContainer .Icon svg path {
  color: var(--color-foreground-success-bold, #033a0f);
  fill: var(--color-foreground-success-bold, #033a0f);
}

.InlineMessage--info.InlineMessage--background, .InlineMessage--info .InlineMessage-linkContainer .Icon svg path {
  color: var(--color-foreground-info-bold, #332196);
  fill: var(--color-foreground-info-bold, #332196);
}

.InlineMessage--background .InlineMessage-leftIconContainer, .InlineMessage--background .InlineMessage-textContainer, .InlineMessage--background .InlineMessage-linkContainer, .InlineMessage--background .InlineMessage-dismissContainer {
  padding: .25rem 0;
}

.InlineMessage--background {
  border-radius: var(--border-radius-sm, 4px);
}

.InlineMessage--background.InlineMessage--critical {
  background: var(--color-background-critical-subtle, #fdeeed);
  border-left: 2px solid var(--color-border-critical-medium, #cc1c10);
}

.InlineMessage--background.InlineMessage--warning {
  background: var(--color-background-warning-subtle, #fff1d9);
  border-left: 2px solid var(--color-border-warning-subtle, #f6a91c);
}

.InlineMessage--background.InlineMessage--success {
  background: var(--color-background-success-subtle, #e5f7e0);
  border-left: 2px solid var(--color-border-success-subtle, #43a34d);
}

.InlineMessage--background.InlineMessage--info {
  background: var(--color-background-info-subtle, #f1ecfd);
  border-left: 2px solid var(--color-border-info-medium, #573bdc);
}

.InputField-Container {
  width: 100%;
  display: flex;
}

.InputField {
  flex-direction: column;
  gap: .25rem;
  width: 100%;
  display: flex;
}

.InputField-inputContainer {
  position: relative;
}

.InputField .Label {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-heading-4);
}

.InputField-search {
  gap: 0;
}

.InputField-input {
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  border-radius: var(--border-radius-sm);
  color: var(--color-foreground-secondary-bold, #00245b);
  text-overflow: ellipsis;
  width: 100%;
  transition: border .1s ease-in-out;
}

.InputField-input.InputField-input {
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.InputField-input--isCritical.InputField-input--isCritical, .InputField-input--isCritical.InputField-input--isCritical:focus, .InputField-input--isCritical.InputField-input--isCritical:focus:hover, .InputField-input--isCritical.InputField-input--isCritical:hover {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}

.InputField--neutral {
  background: var(--background-neutral-subtlest, #fff);
}

.InputField--secondary {
  background: var(--color-background-secondary-subtlest, #f7f9fc);
}

.InputField-input-suffixButton {
  border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
  width: calc(100% - 2.4375rem);
}

.InputField--small {
  font: var(--typography-body-3);
  height: 2rem;
  padding: .5rem 2.25rem .5rem .25rem;
}

.InputField--large {
  font: var(--typography-body-2);
  height: 3rem;
  padding: .875rem 2.25rem .875rem .5rem;
}

.InputField--multiline {
  font: var(--typography-body-2);
  min-width: 7.5rem;
  max-width: 100%;
  min-height: 3rem;
  max-height: 100%;
  padding: .75rem;
  transition: border-color .1s ease-in-out;
}

.InputField--multiline::-webkit-scrollbar {
  width: 1rem;
}

.InputField--multiline::-webkit-scrollbar-track {
  background-color: #0000;
}

.InputField--multiline::-webkit-scrollbar-thumb {
  background-color: var(--color-foreground-neutral-disabled);
  border-radius: 200px;
}

.InputField--neutral::-webkit-scrollbar-thumb {
  border: .25rem solid var(--background-neutral-subtlest, #fff);
}

.InputField--secondary::-webkit-scrollbar-thumb {
  border: .25rem solid var(--color-background-secondary-subtlest);
}

.InputField--multiline:disabled::-webkit-scrollbar-thumb {
  border: .25rem solid var(--color-background-neutral-disabled-subtle, #ebecf2);
}

.InputField--multiline:hover::-webkit-scrollbar-thumb {
  background-color: var(--color-foreground-neutral-subtle, #717385);
}

.InputField--multiline {
  scrollbar-width: thin;
}

.InputField-input.InputField-noOutline:focus, .InputField-input.InputField-noOutline:hover:focus {
  outline: none;
}

.InputField-input:focus, .InputField-input:hover:focus, .InputField-suffixButton[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
}

.InputField-input:active {
  border: 1px solid var(--color-border-primary-medium, #3751ff);
}

.InputField-input:hover {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.InputField-input:disabled {
  cursor: not-allowed;
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  color: var(--color-foreground-neutral-subtle, #717385);
  border: 1px solid #0000;
}

.InputField-input::-webkit-input-placeholder {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-subtle, #717385);
}

.InputField-input:-ms-input-placeholder {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-subtle, #717385);
}

.InputField-input::placeholder {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-subtle, #717385);
}

.InputField-input--isDisabled {
  padding-right: .5rem;
}

.InputField-input--isReadOnly {
  pointer-events: none;
  background: none;
  padding-right: .5rem;
}

.InputField-prefixIcon {
  position: absolute;
  top: 0;
  left: 0;
}

.InputField-input-prefixIcon--small {
  padding-left: 2rem;
}

.InputField-input-prefixIcon--large {
  padding-left: 2.25rem;
}

.InputField-prefixIcon--small span {
  padding: .5rem 0 .5rem .5rem;
}

.InputField-prefixIcon--small svg {
  width: 1rem;
  height: 1rem;
}

.InputField-prefixIcon--large span {
  padding: .875rem 0 .875rem .5rem;
}

.InputField-prefixIcon--large svg {
  width: 1.25rem;
  height: 1.25rem;
}

.InputField-closeButtonContainer {
  opacity: 0;
  transition: all .1s ease-in-out;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.InputField-closeButtonContainer--small {
  align-items: center;
  height: 2rem;
  padding: .25rem .5rem .25rem 0;
}

.InputField-closeButtonContainer--large {
  align-items: center;
  height: 3rem;
  padding: .6875rem .5rem .6875rem 0;
}

.InputField-closeButtonContainer--suffixButton {
  right: 2.3125rem;
}

.InputField-closeButtonContainer--isReadOnly {
  display: none;
}

.InputField-inputContainer:hover .InputField-closeButtonContainer, .InputField-inputContainer:focus-within .InputField-closeButtonContainer {
  opacity: 1;
}

.InputField-closeButtonContainer:hover + .InputField-input {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.InputField-suffixButton {
  all: unset;
  box-sizing: border-box;
  border: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  cursor: pointer;
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  isolation: isolate;
  background: none;
  border-left: 1px solid #0000;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 3rem;
  padding: .875rem .5rem;
  transition: all .1s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.InputField-suffixButton-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.InputField-suffixButton-icon svg path, .InputField-suffixButton-icon svg g {
  fill: var(--color-foreground-secondary-medium, #254b86);
}

.InputField-suffixButton--isReadOnly {
  background: var(--background-neutral-subtlest, #fff);
  border: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
}

.InputField-suffixButton:hover {
  border: 1px solid var(--color-border-secondary-subtle, #cecfd9);
  background: var(--background-neutral-subtlest, #fff);
}

.InputField-suffixButton-overlay {
  z-index: -1;
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
  opacity: 0;
  background: none;
  border: 1px solid #0000;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transition: all .1s ease-in-out;
  position: absolute;
  top: -1px;
  right: -1px;
}

.InputField-suffixButton-icon {
  transition: all .1s ease-in-out;
}

.InputField-suffixButton-overlay:hover, .InputField-suffixButton:hover .InputField-suffixButton-overlay, .InputField-suffixButton-icon:hover + .InputField-suffixButton-overlay {
  opacity: 1;
  background: #3751ff14;
}

.InputField-suffixButton-overlay:active, .InputField-suffixButton:active .InputField-suffixButton-overlay, .InputField-suffixButton-icon:active + .InputField-suffixButton-overlay {
  opacity: 1;
  background: #3751ff1a;
}

.InputField-input--isCritical, .InputField-input--isCritical:focus, .InputField-input--isCritical:focus:hover, .InputField-input--isCritical:hover {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}

.InputField--isRequired {
  position: relative;
  top: 5px;
  right: 5px;
}

.InputField--isRequired--isCritical, .InputField--isRequired--isWarning {
  font: var(--typography-heading-3);
  position: absolute;
  top: -.25rem;
  left: -.25rem;
}

.InputField--isRequired--isWarning, .InputField-suffixButton--isWarning {
  color: var(--color-foreground-warning-medium);
}

.InputField--isRequired--isCritical {
  color: var(--color-foreground-critical-medium);
}

.InputField-suffixButton--isCritical, .InputField-suffixButton--isCritical:hover {
  border: 1px solid var(--color-foreground-critical-medium);
}

.InputField-suffixButton--isCritical .InputField-suffixButton-icon svg path, .InputField-suffixButton--isCritical .InputField-suffixButton-icon svg g {
  fill: var(--color-foreground-neutral-subtle, #717385);
}

.InputField-suffixButton:disabled, .InputField-suffixButton-overlay--isDisabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  cursor: not-allowed;
  border: 1px solid #0000;
}

.InputField-suffixButton-neutral--isDisabled, .InputField-suffixButton-secondary--isDisabled {
  border: 1px solid var(--color-foreground-neutral-subtle);
  border-left: 1px solid #0000;
}

.InputField-suffixButton-overlay:hover, .InputField-suffixButton:disabled:hover .InputField-suffixButton-overlay--isDisabled, .InputField-suffixButton-icon:hover + .InputField-suffixButton-overlay--isDisabled {
  opacity: 0;
}

.InputField-suffixButton--isDisabled .InputField-suffixButton-icon svg path, .InputField-suffixButton--isDisabled .InputField-suffixButton-icon svg g {
  fill: var(--color-foreground-neutral-subtle, #717385);
}

.InputField-multiline--isRequired--isCritical, .InputField-multiline--isRequired--isWarning {
  font: var(--typography-heading-3);
  position: absolute;
  top: -1.25rem;
  left: -1.25rem;
}

.Link {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  display: inline-block;
}

.Link .Link-wrapper {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: .125rem;
  text-decoration: none;
  display: inline-flex;
}

.Link-textWrapper {
  white-space: nowrap;
}

.Link:not(.Link--isDisabled):hover .Link-textWrapper {
  text-decoration: underline;
}

.Link.Link--isDisabled {
  cursor: not-allowed;
}

.Link.Link--isDisabled .Link-wrapper {
  cursor: not-allowed;
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
  pointer-events: none;
  text-decoration: none;
}

.Link.Link--large .Link-icon, .Link.Link--large .Link-icon svg, .Link.Link--medium .Link-icon, .Link.Link--medium .Link-icon svg, .ButtonLink--large .Link-icon, .ButtonLink--large .Link-icon svg, .ButtonLink--medium .Link-icon, .ButtonLink--medium .Link-icon svg {
  width: 1.25rem;
  height: 1.25rem;
}

.Link.Link--small .Link-icon, .Link.Link--small .Link-icon svg, .ButtonLink--small .Link-icon, .ButtonLink--small .Link-icon svg {
  width: 1rem;
  height: 1rem;
}

.Link:not(.Link--isDisabled)[tabindex]:focus, .ButtonLink[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
}

.Link--brand > .Link-wrapper {
  color: var(--color-foreground-primary-medium, #3751ff);
}

.Link--neutral > .Link-wrapper {
  color: var(--color-foreground-neutral-bold, #212328);
}

.Link--brand {
  color: var(--color-foreground-primary-medium, #3751ff);
}

.Link--brand .Link-icon svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}

.Link--neutral {
  color: var(--color-foreground-neutral-bold, #212328);
}

.Link--neutral .Link-icon svg path {
  fill: var(--color-foreground-neutral-bold, #212328);
}

.Link--neutralInverse .Link-textWrapper {
  color: var(--color-foreground-neutral-inverse, #fff);
}

.Link--neutralInverse .Link-icon svg path {
  fill: var(--color-foreground-neutral-inverse, #fff);
}

.Link.Link--large {
  font: var(--typography-body-bold-1);
  height: 1.5rem;
}

.Link.Link--medium {
  font: var(--typography-body-bold-2);
  height: 1.25rem;
}

.Link.Link--small {
  font: var(--typography-body-bold-3);
  height: 1rem;
}

.ButtonLink.ButtonLink--large {
  font: var(--typography-body-1);
  height: 1.5rem;
}

.ButtonLink.ButtonLink--medium {
  font: var(--typography-body-2);
  height: 1.25rem;
}

.ButtonLink.ButtonLink--small {
  font: var(--typography-body-3);
  height: 1rem;
}

.ButtonLink {
  color: var(--color-foreground-primary-medium, #3751ff);
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  gap: .125rem;
  padding: 0;
  display: flex;
}

.ButtonLink--large {
  height: 1.5rem;
}

.ButtonLink--medium {
  height: 1.25rem;
}

.ButtonLink--small {
  height: 1rem;
}

.ButtonLink, .ButtonLink .Link-icon svg path {
  transition: all .1s ease-in-out;
}

.ButtonLink .Link-icon svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}

.ButtonLink:not(.ButtonLink--isDisabled):hover {
  color: var(--color-foreground-primary-bold, #032185);
  text-decoration: none;
}

.ButtonLink:hover {
  color: var(--color-foreground-primary-bold, #032185);
}

.ButtonLink:hover .Link-icon svg path, .ButtonLink:hover .Link-icon svg {
  fill: var(--color-foreground-primary-bold, #032185);
}

.ButtonLink--isDisabled, .ButtonLink--isDisabled:hover {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
  cursor: not-allowed;
}

.Link--isDisabled .Link-icon svg path, .ButtonLink--isDisabled .Link-icon svg path, .ButtonLink--isDisabled:hover .Link-icon svg path, .ButtonLink--isDisabled:hover .Link-icon svg {
  fill: var(--color-foreground-neutral-disabled, #9ea1b1);
}

.Category-content {
  color: var(--color-foreground-neutral-subtle, #717385);
  align-self: stretch;
  align-items: flex-start;
  gap: .2rem;
  display: flex;
}

.Category-text--bold {
  color: var(--color-foreground-neutral-bold, #212328);
}

.AvatarText {
  gap: .25rem;
  display: flex;
}

.AvatarText-text {
  color: var(--color-foreground-neutral-bold, #212328);
}

.IconText {
  gap: .1rem;
  display: flex;
}

.IconText-text {
  color: var(--color-foreground-neutral-bold, #212328);
}

.ListRow-labelValue, .ListRow-description, .ListRow-description-content {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.ListRow-separator {
  margin: 0 .5rem;
}

.ListRow-text {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
}

.ListRow-description-content-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 45rem;
  overflow: hidden;
}

.ListRow-labelValue, .ListRow-title, .ListRow-title-content {
  display: flex;
}

.ListRow-labelValue-label {
  color: var(--color-foreground-neutral-subtle, #717385);
  font: var(--typography-body-3);
  line-height: 1.5rem;
}

.ListRow-dot {
  background-color: var(--color-foreground-neutral-bold, #212328);
  border-radius: .25rem;
  width: .25rem;
  height: .25rem;
  margin: 0 .5rem;
  display: inline-block;
  position: relative;
  top: .5625rem;
}

.ListRow-labelValue-text {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-3);
  line-height: 1.5rem;
}

.ListRowAccount-data {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: .125rem;
  width: 100%;
  min-width: 0;
  padding: .5rem .75rem;
  display: flex;
}

.ListRowAccount-data-category {
  color: var(--color-foreground-secondary-bold, #00245b);
  font-feature-settings: "clig" off, "liga" off;
  font: var(--typography-body-3);
}

.ListRowAccount-data-title {
  color: var(--color-foreground-neutral-bold, #212328);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Rubik;
  font: var(--typography-heading-3);
}

.ListRowAccount-data-description {
  color: var(--color-foreground-neutral-subtle, #717385);
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: var(--typography-body-2);
  max-width: 100%;
  overflow: hidden;
}

.ListRowSession-data {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: .125rem;
  width: 100%;
  min-width: 0;
  padding: .5rem .75rem;
  display: flex;
}

.ListRowSession-data-title {
  color: var(--color-foreground-neutral-bold, #212328);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Rubik;
  font: var(--typography-heading-3);
}

.ListRowSession-data-description {
  color: var(--color-foreground-neutral-subtle, #717385);
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: var(--typography-body-2);
  width: 100%;
  overflow: hidden;
}

.ListRowSession-labelValue, .ListRowSession-title, .ListRowSession-title-content {
  display: flex;
}

.ListRowSession-labelValue-label {
  color: var(--color-foreground-neutral-subtle, #717385);
  font: var(--typography-body-3);
  line-height: 1.5rem;
}

.ListRowSession-dot {
  background-color: var(--color-foreground-neutral-bold, #212328);
  border-radius: .25rem;
  width: .25rem;
  height: .25rem;
  margin: 0 .5rem;
  display: inline-block;
  position: relative;
  top: .5625rem;
}

.ListRowSession-labelValue-text {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-3);
  line-height: 1.5rem;
}

.ListRowTarget-data {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: .125rem;
  width: 100%;
  min-width: 0;
  padding: .5rem .75rem;
  display: flex;
}

.ListRowTarget-data-title {
  color: var(--color-foreground-neutral-bold, #212328);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Rubik;
  font: var(--typography-heading-3);
}

.ListRowTarget-data-description {
  color: var(--color-foreground-neutral-subtle, #717385);
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: var(--typography-body-2);
  max-width: 100%;
  overflow: hidden;
}

.ListRow-container {
  position: relative;
}

.ListRow-row-data {
  border-radius: var(--border-radius-sm);
  background-color: #fff;
  border: 1px solid #0000;
  align-items: center;
  max-width: 100%;
  transition: all .2s ease-in-out;
  display: flex;
  position: relative;
}

.ListRow-row-data:hover {
  box-shadow: none;
  cursor: pointer;
}

.ListRow-hover-span {
  content: "";
  border-radius: .25rem;
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out;
  position: absolute;
}

.ListRow-container:hover .ListRow-hover-span {
  box-shadow: var(--box-shadow-pretoken-container-interaction);
}

.ListRow-row-data:hover .ListRow-bookmark {
  opacity: 1;
}

.ListRow-sectionLeft {
  text-overflow: ellipsis;
  flex: 1;
  min-width: 0;
  display: flex;
}

.ListRow-bookmark-badgeContainer {
  box-sizing: border-box;
  width: 2rem;
  padding: 1.25rem .5rem;
}

.ListRow-bookmark {
  opacity: 0;
  align-self: flex-start;
  transition: all .1s ease-in-out;
  display: flex;
  transform: translate(-.25rem, -1.4375rem);
}

.ListRow-bookmark:hover svg path {
  fill: var(--color-foreground-warning-subtle, #e19701);
}

.ListRow-applicationIcon {
  padding: .75rem .25rem;
}

.ListRow-icon {
  align-items: center;
  margin: 0 .5rem;
  display: flex;
}

.ListRow-icon:hover {
  cursor: pointer;
}

.ListRow-sectionRight {
  min-width: max-content;
  margin-right: .5rem;
  display: flex;
}

.ListRow-label-chip {
  background: var(--color-background-info-subtle, #f1ecfd);
  color: var(--color-foreground-info-bold, #332196);
  text-overflow: ellipsis;
  white-space: nowrap;
  font: var(--typography-body-2);
  border-radius: 7.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2rem;
  margin-top: .3rem;
  padding: .25rem .5rem;
  display: flex;
}

.ListRow-verticalIcon:hover {
  cursor: pointer;
}

.ListRow-verticalIcon-hidden {
  visibility: hidden;
}

.ListRow-status {
  margin-top: .3rem;
}

.MenuItem {
  cursor: pointer;
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
  border-radius: var(--border-radius-sm, 4px);
  justify-content: flex-start;
  align-items: center;
  margin: .25rem .5rem;
  padding: .25rem .5rem;
  display: flex;
}

.MenuItem:not(.MenuItem--isDisabled):hover {
  background: #3751ff14;
}

.MenuItem:not(.MenuItem--isDisabled):active {
  background: #3751ff1a;
}

.MenuItem:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  border-radius: 4px;
}

.MenuItem--isDisabled {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
}

.MenuItem--isDisabled:hover {
  cursor: not-allowed;
}

.MenuItem--isChecked {
  background: var(--light-brand-primary-press-selected, #3751ff1f);
  border-radius: 4px;
}

.Menu {
  position: relative;
}

.Menu-button {
  font: var(--typography-body-2);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Menu--simple .Menu-button {
  color: var(--color-foreground-neutral-medium, #4a4c59);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  width: min-content;
  padding: 0;
}

.Menu-button .Icon--xxxSmall {
  height: unset;
}

.Menu--simple .Menu-button[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
  outline-offset: .125rem;
}

.Menu--default .Menu-button {
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  background: var(--color-background-neutral-subtlest, #cecfd9);
}

.Menu--default .Menu-button .Icon {
  background: var(--color-background-primary-on-subtle-selected, #3751ff1a);
}

.Menu--simple .Menu-button {
  background: none;
  border: 0;
  gap: .125rem;
}

.Menu-dropdown {
  background: var(--color-background-neutral-subtlest, #fff);
  z-index: var(--z-index-dropdown);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  opacity: 0;
  transform-origin: top;
  padding: 0 .25rem .25rem;
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  position: absolute;
  transform: scaleY(0);
}

.Menu-dropdown--isVisible {
  opacity: 1;
  transform: scaleY(1);
}

.Menu--default .Menu-dropdown {
  margin-top: 0;
}

.Menu-items {
  white-space: nowrap;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 4px);
  min-width: min-content;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Menu-items:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
}

.Menu-buttonDefault .Icon {
  height: unset;
}

.Menu button label {
  display: initial;
}

.MenuAction {
  width: max-content;
  position: relative;
}

.MenuAction.MenuAction .Menu-dropdown--isVisible {
  margin-bottom: unset;
  margin-top: unset;
}

.MenuAction .Menu-dropdown {
  position: unset;
  margin-bottom: unset;
  margin-top: unset;
  padding: .25rem;
}

.MenuAction.MenuAction .Menu-dropdownContainer {
  z-index: var(--z-index-dropdown);
  pointer-events: none;
  margin-top: .5rem;
  margin-bottom: .5rem;
  position: absolute;
}

.MenuAction .Menu-dropdownContainer.Menu-dropdown--isVisible {
  pointer-events: auto;
}

.Menu-dropdownContainer.Menu-dropdown--top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.Menu-dropdownContainer.Menu-dropdown--top .Menu-dropdown {
  transform-origin: bottom;
}

.Menu-dropdownContainer.Menu-dropdown--bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.Menu-dropdownContainer.Menu-dropdown--edgeToEdge.Menu-dropdown--topLeft {
  bottom: 100%;
  right: 100%;
}

.Menu-dropdownContainer.Menu-dropdown--edgeToEdge.Menu-dropdown--topRight {
  bottom: 100%;
  left: 100%;
}

.Menu-dropdownContainer.Menu-dropdown--edgeToEdge.Menu-dropdown--bottomLeft {
  top: 100%;
  right: 100%;
}

.Menu-dropdownContainer.Menu-dropdown--edgeToEdge.Menu-dropdown--bottomRight {
  top: 100%;
  left: 100%;
}

.Menu-dropdownContainer.Menu-dropdown--overlap.Menu-dropdown--bottomLeft {
  top: 100%;
  right: 0;
}

.Menu-dropdownContainer.Menu-dropdown--overlap.Menu-dropdown--bottomRight {
  top: 100%;
  left: 0;
}

.Menu-dropdownContainer.Menu-dropdown--overlap.Menu-dropdown--topLeft {
  transform-origin: bottom;
  margin-bottom: .5rem;
  bottom: 100%;
  right: 0;
}

.Menu-dropdownContainer.Menu-dropdown--topLeft .Menu-dropdown {
  transform-origin: bottom;
}

.Menu-dropdownContainer.Menu-dropdown--overlap.Menu-dropdown--topRight {
  margin-bottom: .5rem;
  bottom: 100%;
  left: 0;
}

.Menu-dropdownContainer.Menu-dropdown--topRight .Menu-dropdown {
  transform-origin: bottom;
}

.Menu-dropdown.Menu-dropdown--isVisible {
  margin-top: .5rem;
}

.MenuMultiFooter {
  border-radius: 0 0 var(--border-radius-sm, 4px) var(--border-radius-sm, 4px);
  background: var(--color-background-neutral-subtlest, #fff);
  box-shadow: var(--box-shadow-pretoken-overlay-top);
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  padding: .25rem .5rem;
  display: flex;
}

.MenuItemMulti {
  cursor: pointer;
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
  border-radius: var(--border-radius-sm, 4px);
  justify-content: left;
  align-items: center;
  gap: .25rem;
  margin-bottom: .25rem;
  padding: .25rem .5rem .25rem 0;
  display: flex;
}

.MenuItemMulti-checkbox {
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  display: flex;
}

.MenuItemMulti-checkbox .Checkbox.Checkbox--base {
  height: 1rem;
}

.MenuItemMulti:not(.MenuItemMulti--isDisabled):hover {
  background: #3751ff14;
}

.MenuItemMulti:not(.MenuItemMulti--isDisabled):active {
  background: #3751ff1a;
}

.MenuItemMulti:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  border-radius: 4px;
}

.MenuItemMulti--isDisabled {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
}

.MenuItemMulti--isDisabled:hover {
  cursor: not-allowed;
}

.MenuItemMulti-checkedDivider {
  border-bottom: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
}

.MenuMultiSearchBar {
  margin-top: .25rem;
  padding: .25rem .25rem 0;
}

.MenuMulti {
  position: relative;
}

.MenuMulti-dropdown {
  background: var(--color-background-neutral-subtlest, #fff);
  z-index: var(--z-index-dropdown);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  opacity: 0;
  transform-origin: top;
  margin-top: .5rem;
  padding: 0 .25rem .25rem;
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  position: absolute;
  transform: scaleY(0);
}

.MenuMulti-items {
  white-space: nowrap;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 4px);
  min-width: min-content;
  margin: .5rem 0 0;
  padding: 0;
  list-style: none;
}

.MenuMulti-items:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
}

.MenuMulti--isSearchBarVisible .MenuMulti-items {
  margin: 0;
  padding-top: .25rem;
}

.MenuMulti-dropdown--isVisible {
  opacity: 1;
  transform: scaleY(1);
}

.MenuMulti button label {
  display: initial;
}

.ModalIsland--isOpen--bodyScrollLock {
  padding-right: var(--scrollbar-width-saviynt-ds);
  overflow: hidden;
}

.ModalIsland-background {
  content: "";
  background: var(--light-brand-secondary-overlay, #00084080);
  opacity: 0;
  pointer-events: none;
  z-index: var(--z-index-modal-island);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity .2s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.ModalIsland-background--isOpen {
  opacity: 1;
  pointer-events: all;
  transition: opacity .2s ease-in-out;
}

.ModalIsland {
  border-radius: var(--border-radius-sm);
  opacity: 0;
  pointer-events: none;
  z-index: var(--z-index-modal-island);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 6rem);
  max-width: 42.5rem;
  height: max-content;
  transition: opacity .2s ease-in-out;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.ModalIsland--isOpen {
  left: calc(50% - var(--scrollbar-width-saviynt-ds) / 2);
  opacity: 1;
  pointer-events: all;
  transition: opacity .2s ease-in-out;
}

.ModalIsland-header {
  background: var(--background-neutral-subtlest, #fff);
  border-bottom: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  justify-content: center;
  align-items: flex-start;
  gap: .5rem;
  width: 100%;
  height: 3.5rem;
  padding: .75rem 1.5rem;
  display: flex;
  position: relative;
}

.ModalIsland-header--hasSubtitle {
  height: 4.5rem;
}

.ModalIsland-titleAndHeaderIcon {
  align-items: center;
  gap: .75rem;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.ModalIsland-titleContainer {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.ModalIsland-title, .ModalIsland-subtitle {
  color: var(--color-foreground-neutral-bold, #212328);
  width: 100%;
  margin: 0;
}

.ModalIsland-title {
  font: var(--typography-heading-2);
}

.ModalIsland-subtitle {
  font: var(--typography-body-2);
}

#ModalIsland.ModalIsland .ModalIsland-section .ModalIsland-body {
  background: var(--color-page-subtle, #f2f4f8);
  color: var(--color-foreground-neutral-bold, #212328);
  max-height: calc(100vh - 12rem);
  margin: 0;
  padding: 1.5rem;
  overflow-x: hidden;
}

.ModalIsland-alert {
  border-radius: var(--border-radius-sm);
  background: var(--background-neutral-subtlest, #fff);
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 30rem;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ModalIsland-alertContent-icon {
  min-width: 100px;
  min-height: 100px;
}

.ModalIsland-alertContent {
  z-index: 2;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  min-height: max-content;
  max-height: calc(100vh - 2rem);
  padding: 3rem 3rem 2rem;
  display: flex;
  overflow-x: hidden;
}

.ModalIsland-alertContent-body {
  flex-direction: column;
  gap: .75rem;
  display: flex;
}

.ModalIsland-alertTitle, .ModalIsland-alertSubtitle {
  font: var(--typography-heading-2);
  color: var(--color-foreground-neutral-bold, #212328);
  text-align: center;
  width: 100%;
  margin: 0;
}

.ModalIsland-alertSubtitle {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-medium, #4a4c59);
}

.ModalIsland-alertTitle-critical {
  color: var(--color-foreground-critical-bold, #700600);
}

.ModalIsland-alertContent-controlsContainer {
  white-space: nowrap;
  justify-content: center;
  width: 100%;
  display: flex;
}

.ModalIsland-alertContent-controls {
  white-space: nowrap;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  width: min-content;
  display: flex;
}

.ModalIsland-alertCloseButton {
  z-index: 3;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.ModalIsland-alertBg {
  width: 30rem;
  height: 6.75rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.ModalIsland-alertBg-question svg, .ModalIsland-alertBg-question svg path {
  fill: var(--color-background-info-subtle, #f1ecfd);
}

.ModalIsland-alertBg-success svg, .ModalIsland-alertBg-success svg path {
  fill: var(--color-background-success-subtle, #e5f7e0);
}

.ModalIsland-alertBg-warning svg, .ModalIsland-alertBg-warning svg path {
  fill: var(--color-background-warning-subtle, #fff1d9);
}

.ModalIsland-alertBg-critical svg, .ModalIsland-alertBg-critical svg path {
  fill: var(--color-background-critical-subtle, #fdeeed);
}

.ModalIsland-alertBg-saviynt svg, .ModalIsland-alertBg-saviynt svg path {
  fill: var(--color-background-secondary-subtle, #f2f4f8);
}

.ModalPageHeader-background {
  background: var(--color-background-secondary-inverse, #001a40);
  color: #fff;
  z-index: var(--z-index-page-header);
  width: 100%;
  margin: 0 auto;
}

.ModalPageHeader {
  background: var(--color-background-secondary-inverse, #001a40);
  gap: .5rem;
  width: 100%;
  padding: .5rem 3rem;
  display: flex;
}

.ModalPageHeader-textContainer {
  flex-direction: column;
  align-items: flex-start;
  gap: .125rem;
  width: 100%;
  padding: 0;
  display: flex;
}

.ModalPageHeader-title {
  font: var(--typography-heading-1);
  margin: 0;
}

.ModalPageHeader-subHeadingContent {
  color: var(--color-background-neutral-subtlest, #fff);
  font: var(--typography-body-2);
}

.ModalPageHeader-subHeadingContent p {
  margin: 0;
}

.ModalPageHeader-closeButton {
  min-width: 2.5rem;
}

.PaginationCondensed {
  white-space: nowrap;
  align-items: center;
  gap: .75rem;
  min-width: min-content;
  display: flex;
}

.PaginationCondensed-body {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-medium, #4a4c59);
  margin: 0;
}

.PaginationCondensed-rangePicker {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.PaginationCondensed-rangePicker .Button {
  padding: .125rem .25rem .125rem .125rem;
}

.PaginationCondensed-rangePicker .Button .Icon svg path {
  fill: var(--color-foreground-neutral-bold, #212328);
}

.PaginationCondensed-selector {
  align-items: center;
  gap: .125rem;
  display: flex;
}

.PaginationCondensed-divider {
  content: "";
  background: var(--color-border-neutral-subtlest, #cecfd9);
  width: .0625rem;
  height: 1.5rem;
}

.PaginationCondensed-rangeOutline {
  align-items: center;
  gap: .25rem;
  display: flex;
}

.PaginationItem {
  all: unset;
  box-sizing: border-box;
  font: var(--typography-body-2);
  text-align: center;
  border-radius: var(--border-radius-circle, 99rem);
  color: var(--color-foreground-neutral-bold, #212328);
  cursor: pointer;
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  transition: all .1s ease-in-out;
  display: flex;
}

.PaginationItem:hover {
  background: #3751ff14;
}

.PaginationItem:not(.PaginationItem--isDisabled)[tabindex]:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  outline-offset: .125rem;
}

.PaginationItem--isSelected {
  color: var(--color-foreground-primary-medium, #3751ff);
  border: 1px solid var(--color-border-primary-medium, #3751ff);
  background: var(--color-background-primary-subtle, #ebedff);
}

.PaginationItem--isSelected:hover {
  background: var(--color-background-primary-subtle, #ebedff);
}

.PaginationItem--isDisabled {
  pointer-events: none;
}

.PaginationItem--isDisabled svg path {
  fill: var(--color-foreground-neutral-disabled, #9ea1b1);
}

.PaginationItem--noTransition {
  transition: unset;
}

.PaginationItem--transition {
  transition: all .1s ease-in-out;
}

.PaginationItem--isEllipses {
  pointer-events: none;
  transition: unset;
}

.PaginationGroup {
  gap: .25rem;
  padding: .375rem;
  display: flex;
}

.RadioButton {
  all: unset;
  box-sizing: border-box;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 4px);
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  caret-color: #0000;
  cursor: pointer;
  isolation: isolate;
  padding: 1rem;
  transition: all .1s ease-in-out;
  position: relative;
}

.RadioButton .Label {
  cursor: pointer;
}

.RadioButton:hover {
  border-color: var(--color-border-secondary-subtle, #6888b5);
  box-shadow: var(--box-shadow-pretoken-container-interaction);
}

.RadioButton:not(.RadioButton--isDisabled)[tabindex]:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  outline-offset: .125rem;
}

.RadioButton--isChecked, .RadioButton--isChecked:hover {
  border-color: var(--color-border-secondary-medium, #254b86);
}

.RadioButton-content {
  display: flex;
}

.RadioButton-overlay {
  z-index: -1;
  border-radius: var(--border-radius-sm, 4px);
  opacity: 0;
  background: none;
  border: 1px solid #0000;
  width: 100%;
  height: 100%;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.RadioButton-overlay:active, .RadioButton-content:active + .RadioButton-overlay, .RadioButton:active .RadioButton-overlay {
  opacity: 1;
  background: #254b861f;
}

.RadioButton-input-contatiner {
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  display: flex;
}

.RadioButton input[type="radio"] {
  transition: all .1s ease-in-out;
  display: none;
}

.RadioButton-button {
  border-radius: var(--border-radius-circle, 99rem);
  outline: .125rem solid var(--color-foreground-secondary-subtle, #6888b5);
  outline-offset: .125rem;
  background: none;
  width: 1rem;
  height: 1rem;
  transition: all .1s ease-in-out;
}

.RadioButton:hover .RadioButton-button {
  background: var(--color-background-secondary-medium, #254b86);
}

.RadioButton input[type="radio"]:checked + .RadioButton-button {
  background: var(--color-foreground-secondary-medium, #254b86);
  outline: .125rem solid var(--color-foreground-secondary-medium, #254b86);
}

.RadioButton-text {
  width: 100%;
  padding: 0 1rem 0 .5rem;
}

.RadioButton-title {
  font: var(--typography-heading-3);
  color: var(--color-foreground-secondary-bold, #00245b);
}

.RadioButton-title--isTruncated {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  max-height: 3rem;
  display: -webkit-box;
  overflow: hidden;
}

.RadioButton-description {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-subtle, #717385);
  margin: 0;
}

.RadioButton-description--isTruncated {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  max-height: 2.5rem;
  display: -webkit-box;
  overflow: hidden;
}

.RadioButton--isDisabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  border: 1px solid #0000;
}

.RadioButton--isDisabled, .RadioButton--isDisabled .Label {
  cursor: not-allowed;
}

.RadioButton--isDisabled:hover {
  box-shadow: unset;
  border: 1px solid #0000;
}

.RadioButton--isDisabled .RadioButton-title {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.RadioButton-button--isDisabled {
  outline-color: var(--color-foreground-neutral-disabled, #9ea1b1);
  background: none;
}

.RadioButton input[type="radio"]:checked + .RadioButton-button--isDisabled {
  outline-color: var(--color-foreground-neutral-disabled, #9ea1b1);
  background: var(--color-foreground-neutral-disabled, #9ea1b1);
}

.RadioButton--isDisabled .RadioButton-overlay, .RadioButton--isDisabled:active .RadioButton-overlay {
  pointer-events: none;
  opacity: 0;
}

.RadioButton--isDisabled:hover .RadioButton-button--isDisabled, .RadioButton--isDisabled:active .RadioButton-button--isDisabled {
  outline-color: var(--color-foreground-neutral-disabled, #9ea1b1);
  background: none;
}

.RadioButton--isDisabled .Icon svg, .RadioButton--isDisabled .Icon svg path, .RadioButton--isDisabled .Icon svg g {
  fill: var(--color-foreground-neutral-subtle, #717385);
}

.SidePanel {
  border-left: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
  z-index: 2;
  background: #fff;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  width: 27.1875rem;
  height: 100%;
  display: flex;
}

.SidePanel-header {
  align-self: stretch;
  align-items: flex-start;
  gap: .5rem;
  padding: 1rem;
  display: flex;
}

.SidePanel-layout {
  background: #fff;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
}

.SidePanel-footer {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  height: 4rem;
  display: flex;
}

.SidePanel-header-icon, .SidePanel-header-icon svg {
  width: 3rem;
  height: 3rem;
}

.SidePanel-header-text {
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  padding-left: .75rem;
  display: flex;
}

.SidePanel-header-title {
  color: var(--color-foreground-neutral-bold, #212328);
  align-self: stretch;
}

.SidePanel-header-description {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.toggle {
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  display: flex;
}

.toggle-placement-right {
  flex-direction: row;
}

.toggle-placement-left {
  flex-direction: row-reverse;
}

.toggle-switch {
  cursor: pointer;
  vertical-align: middle;
  background: #ebedff;
  border-radius: 16px;
  width: 40px;
  height: 24px;
  margin: 0 8px;
  transition: background .25s;
  display: inline-block;
  position: relative;
}

.toggle-switch.toogle-unchecked {
  border: 1px solid #6888b5;
}

.toggle-switch.toogle-checked {
  border: 1px solid #0000;
}

.toggle-switch:before, .toggle-switch:after {
  content: "";
}

.toggle-switch:before {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  transition: left .25s;
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
}

.toogle-checked:before {
  background: #fff;
}

.toogle-unchecked:before {
  background: var(--color-foreground-neutral-subtle, #717385);
}

.toggle-switch:focus, .toggle-switch:active, .toggle-switch:focus-within, .toggle-switch:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #6e7fff;
}

.toggle-checkbox:not(:checked) + .toggle-switch:hover:before, .toggle-checkbox:checked + .toggle-switch:hover:before {
  box-shadow: 0 0 0 8px #3751ff14;
}

.toggle-checkbox:not(:checked) + .toggle-switch:active:before, .toggle-checkbox:checked + .toggle-switch:active:before {
  box-shadow: 0 0 0 8px #3751ff1f;
}

.toggle-checkbox:checked + .toggle-switch {
  background: #254b86;
}

.toggle-checkbox:checked + .toggle-switch:before {
  left: 18px;
}

.toggle-checkbox {
  visibility: hidden;
  position: absolute;
}

.toggle-checkbox:disabled + .toggle-switch {
  cursor: not-allowed;
  border: none;
}

.toggle-checkbox:not(:checked):disabled + .toggle-switch, .toggle-checkbox:checked:disabled + .toggle-switch {
  background: var(--color-background-neutral-disabled-medium, #cecfd9);
}

.toggle-checkbox:not(:checked):disabled + .toggle-switch:before {
  background: var(--color-foreground-neutral-disabled, #9ea1b1);
  top: 3px;
}

.toggle-checkbox:checked:disabled + .toggle-switch:before {
  background: #fff;
  top: 3px;
  left: 20px;
}

.toggle-checkbox:not(:checked):disabled + .toggle-switch:hover:before, .toggle-checkbox:checked:disabled + .toggle-switch:hover:before {
  box-shadow: none;
}

.TabContent {
  width: 100%;
}

.TabItem {
  min-height: var(--sizing-action-lg, 2.5rem);
  cursor: pointer;
  color: var(--color-foreground-neutral-bold, #212328);
  border-bottom: 2px solid var(--color-border-secondary-subtlest, #cfd9e9);
  flex-shrink: 0;
  align-items: center;
  gap: .25rem;
  padding: .5rem .75rem;
  display: inline-flex;
}

.TabItem--medium {
  font: var(--typography-body-2);
}

.TabItem--large {
  font: var(--typography-body-1);
}

.TabItem--large.TabItem--isActive {
  font: var(--typography-body-bold-1);
}

.TabItem--medium.TabItem--isActive {
  font: var(--typography-body-bold-2);
}

.TabItem--isActive {
  color: var(--color-foreground-secondary-medium, #254b86);
  border-bottom: 2px solid var(--color-border-secondary-medium, #254b86);
  transition: border-color .2s;
}

.TabItem--isActive svg path {
  fill: var(--color-foreground-secondary-bold, #00245b);
}

.TabItem:focus-visible {
  border-radius: var(--border-radius-sm, 4px);
  outline: 3px solid var(--color-border-focus, #6e7fff);
}

.TabItem:hover {
  border-bottom: 2px solid var(--color-border-secondary-subtle, #6888b5);
}

.TabGroup {
  display: inline-flex;
}











.ToggleButton {
  all: unset;
  background: var(--color-background-neutral-subtlest, #fff);
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  caret-color: #0000;
  cursor: pointer;
  isolation: isolate;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.ToggleButton[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
  outline-offset: .125rem;
  z-index: 3;
}

.ToggleButton--small {
  height: 1.875rem;
  padding: 0 .5rem;
}

.ToggleButton--medium {
  height: 2.375rem;
  padding: 0 .5rem;
}

.ToggleButton--large {
  height: 2.875rem;
  padding: 0 .75rem;
}

.ToggleButton--start {
  border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
  border-right: none;
}

.ToggleButton--centered {
  border-right: none;
}

.ToggleButton--end {
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
}

.ToggleButton .Icon svg path {
  fill: var(--color-foreground-neutral-bold, #212328);
}

.ToggleButton--isSelected .Icon svg path {
  fill: var(--color-foreground-secondary-bold, #00245b);
}

.ToggleButton--isDisabled .Icon svg path {
  fill: var(--color-foreground-neutral-disabled, #9ea1b1);
}

.ToggleButton--small .Icon {
  width: 1.25rem;
  height: 1.25rem;
}

.ToggleButton--medium .Icon, .ToggleButton--large .Icon {
  width: 1.5rem;
  height: 1.5rem;
}

.ToggleButton-overlay {
  z-index: -1;
  opacity: 0;
  background: none;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transition: all .1s ease-in-out;
  position: absolute;
  top: -1px;
  left: -1px;
}

.ToggleButton-overlay--start {
  border-radius: .25rem 0 0 .25rem;
}

.ToggleButton-overlay--centered {
  border-radius: 0;
}

.ToggleButton-overlay--end {
  border-radius: 0 .25rem .25rem 0;
}

.ToggleButton-overlay:hover, .Icon:hover + .ToggleButton-overlay, .ToggleButton:hover .ToggleButton-overlay, .ToggleButton-label:hover + .ToggleButton-overlay {
  opacity: 1;
  background: #254b8614;
  border: none;
}

.ToggleButton--start:hover, .ToggleButton--centered:hover, .ToggleButton--end:hover {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.ToggleButton--isSelected, .ToggleButton--isSelected:hover {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
}

.ToggleButton-overlay--isSelected {
  opacity: 1;
  cursor: default;
  background: #254b861f;
}

.ToggleButton-overlay--isSelected:hover, .Icon:hover + .ToggleButton-overlay--isSelected, .ToggleButton:hover .ToggleButton-overlay--isSelected, .ToggleButton-label:hover + .ToggleButton-overlay--isSelected {
  cursor: default;
  background: #254b861f;
}

.ToggleButton-label {
  color: var(--color-foreground-neutral-bold, #212328);
  margin: 0;
  padding: 0 .25rem;
}

.ToggleButton-label--small, .ToggleButton-label--medium {
  font: var(--typography-body-2);
}

.ToggleButton-label--large {
  font: var(--typography-body-1);
}

.ToggleButton-label--small--isSelected, .ToggleButton-label--medium--isSelected {
  font: var(--typography-body-bold-2);
  color: var(--color-foreground-secondary-bold, #00245b);
  cursor: default;
}

.ToggleButton-label--large--isSelected {
  font: var(--typography-body-bold-1);
  color: var(--color-foreground-secondary-bold, #00245b);
  cursor: default;
}

.ToggleButton--start:hover + .ToggleButton--centered, .ToggleButton--centered:hover + .ToggleButton--centered, .ToggleButton--centered:not(.ToggleButton--isDisabled):hover + .ToggleButton--end, .ToggleButton--start--isSelected + .ToggleButton--centered, .ToggleButton--centered--isSelected + .ToggleButton--centered, .ToggleButton--centered--isSelected + .ToggleButton--end {
  border-left: none;
}

.ToggleButton-label--isDisabled {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
}

.ToggleButton-overlay--isDisabled:hover, .Icon:hover + .ToggleButton-overlay--isDisabled, .ToggleButton:hover .ToggleButton-overlay--isDisabled, .ToggleButton-label:hover + .ToggleButton-overlay--isDisabled {
  cursor: not-allowed;
  background: none;
}

.ToggleButton-overlay--isDisabled.ToggleButton-overlay--isSelected {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.ToggleButton-overlay--isDisabled.ToggleButton-overlay--isSelected:hover, .ToggleButton-label:hover + .ToggleButton-overlay--isSelected.ToggleButton-overlay--isDisabled, .ToggleButton-label--isDisabled.ToggleButton-label--medium--isSelected {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  cursor: not-allowed;
}

.ToggleButton--isDisabled, .ToggleButton--isDisabled:hover {
  border: 1px solid #0000;
}

.ToggleButton--start + .ToggleButton--isDisabled, .ToggleButton--centered + .ToggleButton--isDisabled {
  border-left: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
  cursor: not-allowed;
}

.ToggleButton--isDisabled + .ToggleButton--centered:not(.ToggleButton--isDisabled) {
  border-left: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
}

.ToggleButton--isDisabled, .ToggleButton--isDisabled:disabled:hover {
  cursor: not-allowed;
}

.ToggleButton--isSelected + .ToggleButton--isDisabled, .ToggleButton--isDisabled + .ToggleButton--isDisabled {
  border: none;
}

.ToggleButton--isSelected.ToggleButton--isDisabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
}

.ToggleButton--isDisabled + .ToggleButton--end:not(.ToggleButton--isDisabled) {
  border-left: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
}

.ToggleButton--isDisabled + .ToggleButton--end:not(.ToggleButton--isDisabled):hover, .ToggleButton--isDisabled + .ToggleButton--centered:not(.ToggleButton--isDisabled):hover {
  border-left: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.ToggleButton--isDisabled + .ToggleButton--isSelected:not(.ToggleButton--isDisabled), .ToggleButton--isDisabled + .ToggleButton--isSelected:not(.ToggleButton--isDisabled):hover {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
}

.ToggleButton--isSelected:not(.ToggleButton--isDisabled) + .ToggleButton--isDisabled {
  border: 1px solid #0000;
  border-left: none;
}

.ToggleButtonGroup {
  align-items: center;
  display: flex;
}

.TooltipComponent-container {
  width: min-content;
  position: relative;
}

.TooltipComponent {
  background: var(--color-background-neutral-inverse, #212328);
  border-radius: var(--border-radius-sm, .25rem);
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  z-index: var(--z-index-tooltip);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  border: none;
  width: max-content;
  min-height: 2rem;
  padding: .5rem .75rem;
  transition: unset;
  position: absolute;
}

.TooltipComponent--isVisible {
  visibility: visible;
  opacity: 1;
  height: auto;
  transition: opacity .1s ease-in-out, visibility 0s linear .1s;
}

.TooltipComponent-text {
  font: var(--typography-body-3);
  color: var(--color-foreground-neutral-inverse, #fff);
  margin: 0;
}

.TooltipComponent--topLeft {
  top: -.75rem;
  left: 0;
  transform: translateY(-100%);
}

.TooltipComponent--top {
  top: -.75rem;
  left: 50%;
  transform: translateY(-100%)translateX(-50%);
}

.TooltipComponent--topRight {
  top: -.75rem;
  right: 0;
  transform: translateY(-100%);
}

.TooltipComponent--rightTop, .TooltipComponent--right, .TooltipComponent--rightBottom {
  top: 50%;
  right: -.75rem;
  transform: translateY(-50%)translateX(100%);
}

.TooltipComponent--rightTop {
  top: 0;
  transform: translateX(100%);
}

.TooltipComponent--rightBottom {
  top: unset;
  bottom: 0;
  transform: translateX(100%);
}

.TooltipComponent--bottomLeft {
  bottom: -.75rem;
  left: 0;
  transform: translateY(100%);
}

.TooltipComponent--bottom {
  bottom: -.75rem;
  left: 50%;
  transform: translateY(100%)translateX(-50%);
}

.TooltipComponent--bottomRight {
  bottom: -.75rem;
  right: 0;
  transform: translateY(100%);
}

.TooltipComponent--leftTop, .TooltipComponent--left, .TooltipComponent--leftBottom {
  top: 50%;
  left: -.75rem;
  transform: translateY(-50%)translateX(-100%);
}

.TooltipComponent--leftTop {
  top: 0;
  transform: translateX(-100%);
}

.TooltipComponent--leftBottom {
  top: unset;
  bottom: 0;
  transform: translateX(-100%);
}

.TooltipComponent-arrow {
  position: absolute;
}

.TooltipComponent-arrow svg path {
  fill: var(--color-background-neutral-inverse, #212328);
}

.TooltipComponent-arrow .Icon {
  transition: unset;
}

.TooltipComponent-arrow--onY {
  width: 1rem;
  height: .5rem;
  margin: 0 .4375rem;
}

.TooltipComponent-arrow--onX {
  width: .5rem;
  height: 1rem;
  margin: .4375rem 0;
}

.TooltipComponent-arrow--topLeft, .TooltipComponent-arrow--top, .TooltipComponent-arrow--topRight {
  bottom: .5rem;
  transform: translateY(150%)translateX(calc(-50% - .4375rem));
}

.TooltipComponent-arrow--topLeft {
  left: .9375rem;
}

.TooltipComponent-arrow--top {
  left: 50%;
}

.TooltipComponent-arrow--topRight {
  right: -.9375rem;
}

.TooltipComponent-arrow--rightTop, .TooltipComponent-arrow--right, .TooltipComponent-arrow--rightBottom {
  left: .0625rem;
  transform: translateX(calc(-50% - .5rem));
}

.TooltipComponent-arrow--rightTop {
  top: -.4375rem;
  transform: translateX(calc(-50% - .5rem))translateY(50%);
}

.TooltipComponent-arrow--right {
  top: calc(50% - .9375rem);
}

.TooltipComponent-arrow--rightBottom {
  bottom: 0;
}

.TooltipComponent-arrow--bottomLeft, .TooltipComponent-arrow--bottom, .TooltipComponent-arrow--bottomRight {
  top: 0;
  transform: translateY(-150%)translateX(calc(-50% - .4375rem));
}

.TooltipComponent-arrow--bottomLeft {
  left: .9375rem;
}

.TooltipComponent-arrow--bottom {
  left: 50%;
}

.TooltipComponent-arrow--bottomRight {
  right: -.9375rem;
}

.TooltipComponent-arrow--leftTop, .TooltipComponent-arrow--left, .TooltipComponent-arrow--leftBottom {
  right: .0625rem;
  transform: translateX(50%);
}

.TooltipComponent-arrow--leftTop {
  top: 0;
}

.TooltipComponent-arrow--left {
  top: calc(50% - .9375rem);
}

.TooltipComponent-arrow--leftBottom {
  bottom: 0;
}

.UploadedFile-container {
  flex-flow: column;
  gap: .25rem;
  width: 100%;
  display: inline-flex;
}

.UploadedFile {
  border-radius: var(--border-radius-sm, 4px);
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  background: var(--color-background-neutral-subtlest, #fff);
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  min-height: 4.5rem;
  padding: .5rem 1rem;
  display: flex;
}

.UploadedFile-nameSizeContainer {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-flow: column;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.UploadedFile-fileName {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.UploadedFile-fileSize {
  color: var(--color-foreground-neutral-subtle, #717385);
  font: var(--typography-body-2);
}

.UploadedFile-actionIcons {
  justify-content: center;
  align-items: center;
  gap: .75rem;
  margin-left: 1.5rem;
  display: flex;
}

.UploadedFile-error {
  align-items: center;
  gap: .25rem;
  display: flex;
}

.UploadedFile-errorIcon {
  height: 1rem;
}

.UploadedFile-errorMessage {
  color: var(--color-foreground-critical-medium, #cc1c10);
  font: var(--typography-body-3);
}

.UploadedFile--error {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}



.Upload-container {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.Upload-helperText {
  font: var(--typography-body-3);
  color: var(--color-foreground-neutral-medium, #4a4c59);
  align-self: flex-start;
}

.Upload-dropzone {
  border-radius: var(--border-radius-sm, 4px);
  border: 1px dashed var(--color-border-primary-subtle, #6e7fff);
  background: var(--color-background-neutral-subtlest, #fff);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  padding: 1.5rem;
  transition: border-color .2s;
  display: flex;
}

.Upload-content {
  flex-flow: column;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.Upload-content .Upload-icon {
  height: 2.25rem;
}

.Upload-icon .Icon {
  width: 2.25rem;
  height: 2.25rem;
}

.Upload-instructions {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
}

.Upload-maxNumberUploaded {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
  flex-flow: column;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.Upload-filetypes {
  color: var(--color-foreground-neutral-subtle, #717385);
  font: var(--typography-body-3);
  flex-flow: column;
  align-items: center;
  display: flex;
}

.Upload-allowedFileTypesAndTooltip {
  text-align: center;
  display: flex;
}

.Upload-input {
  display: none;
}

.Upload-buttonTriggerContainer {
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.Upload-buttonTriggerContainer .Upload-filetypes {
  align-items: flex-start;
}

.Upload-buttonTrigger {
  min-width: 6.688rem;
}

.Upload--isDragging {
  cursor: pointer;
  background: #3751ff14;
}

.Upload-dropzone--isMaxNumberUploaded {
  background: var(--color-background-neutral-subtlest, #fff);
  border: 1px dashed var(--color-foreground-neutral-disabled, #9ea1b1);
}

.Upload-dropzone--isMaxNumberUploaded .Upload-instructions {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
  font: var(--typography-body-3);
}

/*# sourceMappingURL=main.css.map */
