.ToggleButton {
  all: unset;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-background-neutral-subtlest, #fff);
  border: 1px solid var(--color-border-secondary-subtlest, #cfd9e9);
  caret-color: transparent;
  cursor: pointer;
  isolation: isolate;
}
.ToggleButton[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
  outline-offset: 0.125rem;
  z-index: 3;
}

/* Sizes */
.ToggleButton--small {
  height: 1.875rem;
  padding: 0 0.5rem;
}
.ToggleButton--medium {
  height: 2.375rem;
  padding: 0 0.5rem;
}
.ToggleButton--large {
  height: 2.875rem;
  padding: 0 0.75rem;
}

/* Kinds */
.ToggleButton--start {
  border-right: none;
  border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
}
.ToggleButton--centered {
  border-right: none;
}
.ToggleButton--end {
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
}

/* Icon colors and size assertions */

/* Icon colors */
.ToggleButton .Icon svg path {
  fill: var(--color-foreground-neutral-bold, #212328);
}
.ToggleButton--isSelected .Icon svg path {
  fill: var(--color-foreground-secondary-bold, #00245b);
}
.ToggleButton--isDisabled .Icon svg path {
  fill: var(--color-foreground-neutral-disabled, #9ea1b1);
}

/* Icon Sizes */
.ToggleButton--small .Icon {
  height: 1.25rem;
  width: 1.25rem;
}
.ToggleButton--medium .Icon,
.ToggleButton--large .Icon {
  height: 1.5rem;
  width: 1.5rem;
}

/* Overlay */
.ToggleButton-overlay {
  position: absolute;
  z-index: -1;
  left: -1px;
  top: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background: transparent;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}
.ToggleButton-overlay--start {
  border-radius: 0.25rem 0 0 0.25rem;
}
.ToggleButton-overlay--centered {
  border-radius: 0;
}
.ToggleButton-overlay--end {
  border-radius: 0 0.25rem 0.25rem 0;
}

/* Hover state */
.ToggleButton-overlay:hover,
.Icon:hover + .ToggleButton-overlay,
.ToggleButton:hover .ToggleButton-overlay,
.ToggleButton-label:hover + .ToggleButton-overlay {
  opacity: 1;
  border: none;
  /* //TODO: this overlay color var is not working */
  background: rgba(37, 75, 134, 0.08);
}

.ToggleButton--start:hover {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}
.ToggleButton--centered:hover {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}
.ToggleButton--end:hover {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

/* isSelected state */
.ToggleButton--isSelected,
.ToggleButton--isSelected:hover {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
}

.ToggleButton-overlay--isSelected {
  opacity: 1;
  background: rgba(37, 75, 134, 0.12);
  cursor: default;
}
.ToggleButton-overlay--isSelected:hover,
.Icon:hover + .ToggleButton-overlay--isSelected,
.ToggleButton:hover .ToggleButton-overlay--isSelected,
.ToggleButton-label:hover + .ToggleButton-overlay--isSelected {
  background: rgba(37, 75, 134, 0.12);
  cursor: default;
}

/* Label */
.ToggleButton-label {
  color: var(--color-foreground-neutral-bold, #212328);
  padding: 0 0.25rem;
  margin: 0;
}
.ToggleButton-label--small,
.ToggleButton-label--medium {
  font: var(--typography-body-2);
}
.ToggleButton-label--large {
  font: var(--typography-body-1);
}
.ToggleButton-label--small--isSelected,
.ToggleButton-label--medium--isSelected {
  font: var(--typography-body-bold-2);
  color: var(--color-foreground-secondary-bold, #00245b);
  cursor: default;
}
.ToggleButton-label--large--isSelected {
  font: var(--typography-body-bold-1);
  color: var(--color-foreground-secondary-bold, #00245b);
  cursor: default;
}

/* Start, centered, or end border removed on hover and isSelected. */
.ToggleButton--start:hover + .ToggleButton--centered,
.ToggleButton--centered:hover + .ToggleButton--centered,
.ToggleButton--centered:not(.ToggleButton--isDisabled):hover
  + .ToggleButton--end,
.ToggleButton--start--isSelected + .ToggleButton--centered,
.ToggleButton--centered--isSelected + .ToggleButton--centered,
.ToggleButton--centered--isSelected + .ToggleButton--end {
  border-left: none;
}

/* Disabled state */
.ToggleButton-label--isDisabled {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
}
.ToggleButton-overlay--isDisabled:hover,
.Icon:hover + .ToggleButton-overlay--isDisabled,
.ToggleButton:hover .ToggleButton-overlay--isDisabled,
.ToggleButton-label:hover + .ToggleButton-overlay--isDisabled {
  background: transparent;
  cursor: not-allowed;
}

.ToggleButton-overlay--isDisabled.ToggleButton-overlay--isSelected {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
}

.ToggleButton-overlay--isDisabled.ToggleButton-overlay--isSelected:hover,
.ToggleButton-label:hover
  + .ToggleButton-overlay--isSelected.ToggleButton-overlay--isDisabled,
.ToggleButton-label--isDisabled.ToggleButton-label--medium--isSelected {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  cursor: not-allowed;
}

/* Border logic if a neighbor is Disabled */
/* Start, centered, or end border removed on hover and Disabled. */
.ToggleButton--isDisabled,
.ToggleButton--isDisabled:hover {
  border: 1px solid transparent;
}

.ToggleButton--start + .ToggleButton--isDisabled,
.ToggleButton--centered + .ToggleButton--isDisabled {
  border-left: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
  cursor: not-allowed;
}

.ToggleButton--isDisabled
  + .ToggleButton--centered:not(.ToggleButton--isDisabled) {
  border-left: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
}

.ToggleButton--isDisabled,
.ToggleButton--isDisabled:disabled:hover {
  cursor: not-allowed;
}

.ToggleButton--isSelected + .ToggleButton--isDisabled,
.ToggleButton--isDisabled + .ToggleButton--isDisabled {
  border: none;
}

.ToggleButton--isSelected.ToggleButton--isDisabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
}

.ToggleButton--isDisabled + .ToggleButton--end:not(.ToggleButton--isDisabled) {
  border-left: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
}

.ToggleButton--isDisabled
  + .ToggleButton--end:not(.ToggleButton--isDisabled):hover {
  border-left: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.ToggleButton--isDisabled
  + .ToggleButton--centered:not(.ToggleButton--isDisabled):hover {
  border-left: 1px solid var(--color-border-secondary-subtle, #6888b5);
}

.ToggleButton--isDisabled
  + .ToggleButton--isSelected:not(.ToggleButton--isDisabled),
.ToggleButton--isDisabled
  + .ToggleButton--isSelected:not(.ToggleButton--isDisabled):hover {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
}

.ToggleButton--isSelected:not(.ToggleButton--isDisabled)
  + .ToggleButton--isDisabled {
  border: 1px solid transparent;
  border-left: none;
}
