.sideBarSpacing {
    margin-left: var(--sidebar-minified-width) !important;
}

.menuOpen {
    margin-left: 240px !important;
}

.menuClosed {
    margin-left: 0px;
}

@media screen and (max-width: 480px) {
    .sideBarSpacing {
        margin-left: 0 !important;
    }

    .menuOpen {
        margin-left: 0 !important;
    }
}