.SpvActiveSession {
  width: 100%;
  height: 100%;
}

.SpvActiveSession-tabGroup {
  width: 100%;
  height: 2.5rem;
  padding: 0 0.5rem 2.4rem 0.5rem;
  border-bottom: 2px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.SpvActiveSession-tabContent {
  display: flex;
  padding: 0 1.5rem;
  max-height: calc(100vh - 17rem);
  flex-direction: column;
  overflow-y: auto;
}

.SpvActiveSession-assetTab {
  display: flex;
  max-height: calc(100vh - 17rem);
  flex-direction: column;
  overflow-y: auto;
}

.SpvActiveSession-tabSection {
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.SpvActiveSession-credentials {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 1rem 1rem 0 1rem;
  gap: 0;
}

.SpvActiveSession-credential {
  display: flex;
  align-items: flex-end;
  gap: 0.75rem;
  align-self: stretch;
  margin-bottom: 1rem;
}

.SpvActiveSession-credential-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-200, 0.25rem);
  flex: 1 0 0;
}

.SpvActiveSession-credential-label {
  color: var(--color-foreground-neutral-bold, #212328);
}

.SpvActiveSession-credential-copy {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
  align-self: stretch;
}
