@import './variables.css';
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500');

:root {
  /* Typography Desktop */
  --typography-display: var(--font-weight-medium) var(--font-size-32) /
      var(--line-height-40) 'Rubik',
    sans-serif;
  --typography-heading-1: var(--font-weight-medium) var(--font-size-24) /
      var(--line-height-32) 'Rubik',
    sans-serif;
  --typography-heading-2: var(--font-weight-medium) var(--font-size-20) /
      var(--line-height-28) 'Rubik',
    sans-serif;
  --typography-heading-3: var(--font-weight-medium) var(--font-size-16) /
      var(--line-height-24) 'Rubik',
    sans-serif;
  --typography-heading-4: var(--font-weight-medium) var(--font-size-14) /
      var(--line-height-20) 'Rubik',
    sans-serif;
  --typography-heading-5: var(--font-weight-regular) var(--font-size-13) /
      var(--line-height-20) 'Rubik',
    sans-serif;
  --typography-body-1: var(--font-weight-regular) var(--font-size-16) /
      var(--line-height-24) 'Rubik',
    sans-serif;
  --typography-body-bold-1: var(--font-weight-medium) var(--font-size-16) /
      var(--line-height-24) 'Rubik',
    sans-serif;
  --typography-body-2: var(--font-weight-regular) var(--font-size-14) /
      var(--line-height-20) 'Rubik',
    sans-serif;
  --typography-body-bold-2: var(--font-weight-medium) var(--font-size-14) /
      var(--line-height-20) 'Rubik',
    sans-serif;
  --typography-body-3: var(--font-weight-regular) var(--font-size-12) /
      var(--line-height-16) 'Rubik',
    sans-serif;
  --typography-body-bold-3: var(--font-weight-medium) var(--font-size-12) /
      var(--line-height-16) 'Rubik',
    sans-serif;

  /* Typography Mobile */
  --typography-display-mobile: var(--font-weight-medium) var(--font-size-24) /
      var(--line-height-32) 'Rubik',
    sans-serif;
  --typography-heading-1-mobile: var(--font-weight-medium) var(--font-size-20) /
      var(--line-height-28) 'Rubik',
    sans-serif;
  --typography-heading-2-mobile: var(--font-weight-medium) var(--font-size-16) /
      var(--line-height-24) 'Rubik',
    sans-serif;
  --typography-heading-3-mobile: var(--font-weight-medium) var(--font-size-14) /
      var(--line-height-20) 'Rubik',
    sans-serif;
  --typography-heading-4-mobile: var(--font-weight-medium) var(--font-size-12) /
      var(--line-height-16) 'Rubik',
    sans-serif;
  --typography-heading-5-mobile: var(--font-weight-regular) var(--font-size-12) /
      var(--line-height-16) 'Rubik',
    sans-serif;
  --typography-body-1-mobile: var(--font-weight-regular) var(--font-size-16) /
      var(--line-height-24) 'Rubik',
    sans-serif;
  --typography-body-bold-1-mobile: var(--font-weight-medium) var(--font-size-16) /
      var(--line-height-24) 'Rubik',
    sans-serif;
  --typography-body-2-mobile: var(--font-weight-regular) var(--font-size-14) /
      var(--line-height-20) 'Rubik',
    sans-serif;
  --typography-body-bold-2-mobile: var(--font-weight-medium) var(--font-size-14) /
      var(--line-height-20) 'Rubik',
    sans-serif;
  --typography-body-3-mobile: var(--font-weight-regular) var(--font-size-12) /
      var(--line-height-16) 'Rubik',
    sans-serif;
  --typography-body-bold-3-mobile: var(--font-weight-medium) var(--font-size-12) /
      var(--line-height-16) 'Rubik',
    sans-serif;

  /* Box Shadow */
  --box-shadow-pretoken-overlay-left: -4px 0px 12px 0px rgba(0, 0, 0, 0.06);
  --box-shadow-pretoken-overlay-right: 4px 0px 12px 0px rgba(0, 0, 0, 0.06);
  --box-shadow-pretoken-overlay-top: 0px -4px 12px 0px rgba(0, 0, 0, 0.06);
  --box-shadow-pretoken-overlay-bottom: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  --box-shadow-pretoken-overlay-interaction: 0px 2px 16px 2px
    rgba(0, 0, 0, 0.08);
  --box-shadow-pretoken-button-interaction: 0px 1px 8px 0px rgba(0, 0, 0, 0.06);
  --box-shadow-pretoken-container-interaction: 0px 4px 16px 0px
    rgba(0, 0, 0, 0.16);
  --box-shadow-pretoken-container-default: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);

  /* ApplicationIcon Sizes */
  --application-icon-size-xxsmall: 1.25rem; /* 20px */
  --application-icon-size-xsmall: 1.5rem; /* 24px */
  --application-icon-size-small: 2rem; /* 32px */
  --application-icon-size-medium: 2.5rem; /* 40px */
  --application-icon-size-large: 3rem; /* 48px */
  --application-icon-size-xlarge: 3.5rem; /* 56px */
  --application-icon-size-xxlarge: 4rem; /* 64px */

  /* z-Indexing */
  /* Design System Library */
  --z-index-floating-banner: 1050;
  --z-index-modal-island: 1010;
  --z-index-react-date-picker-popper: 1010;
  --z-index-tooltip: 1000;
  --z-index-page-header: 999;
  --z-index-accordion-button: 4;
  /* CPAM Landing Page */
  --z-index-landingpage-sidepanel: 2;
  --z-index-landingpage-listrows: 3;
  --z-index-dropdown: 10;
  --z-index-alertbanners: 11;
}

*,
:before,
:after {
  box-sizing: border-box;
}

body {
  font-family: 'Rubik', Helvetica, Arial, serif;
}
