.Avatar {
  border-radius: 4rem;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-border-secondary-inverse, #f2f4f8);
  background: var(--color-background-neutral-medium, #cecfd9);
  color: var(--color-foreground-secondary-medium, #254b86);
}

.Avatar-img {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  border-radius: 100%;
}

.Avatar--xxSmall,
.Avatar--xxSmall img {
  font: var(--typography-body-bold-3);
  width: 1.25rem;
  height: 1.25rem;
}

.Avatar--xSmall,
.Avatar--xSmall img {
  font: var(--typography-body-bold-2);
  width: 1.5rem;
  height: 1.5rem;
}

.Avatar--small,
.Avatar--small img {
  font: var(--typography-body-bold-1);
  width: 2rem;
  height: 2rem;
}

.Avatar--medium,
.Avatar--medium img {
  font: var(--typography-heading-2);
  width: 2.5rem;
  height: 2.5rem;
}
