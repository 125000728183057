:root {
    --icon-size-xx-xxxsmall: 0.5rem; /* 8px */
    --icon-size-x-xxxsmall: 0.625rem; /* 10px */
    --icon-size-xxxsmall: 0.75rem; /* 12px */
    --icon-size-xxsmall: 0.875rem; /* 14px */
    --icon-size-xsmall: 1rem; /* 16px */
    --icon-size-small: 1.125rem; /* 18px */
    --icon-size-small-medium: 1.25rem; /* 20px */
    --icon-size-medium: 1.5rem; /* 24px */
    --icon-size-large: 2rem; /* 32px */
    --icon-size-xlarge: 2.75rem; /* 44px */
    --icon-size-xxlarge: 3rem; /* 48px */
  }
  
  .Icon {
    display: inline-block;
    transition: transform 0.2s ease-in-out;
  }
  
  .Icon svg {
    height: 100%;
    width: 100%;
  }
  
  .Icon--xx-xxxSmall {
    height: var(--icon-size-xx-xxxsmall);
    width: var(--icon-size-xx-xxxsmall);
    min-width: var(--icon-size-xx-xxxsmall);
    min-height: unset;
  }
  
  .Icon--x-xxxSmall {
    height: var(--icon-size-x-xxxsmall);
    width: var(--icon-size-x-xxxsmall);
    min-width: var(--icon-size-x-xxxsmall);
    min-height: unset;
  }
  
  .Icon--xxxSmall {
    height: var(--icon-size-xxxsmall);
    width: var(--icon-size-xxxsmall);
    min-width: var(--icon-size-xxxsmall);
    min-height: unset;
  }
  
  .Icon--xxSmall {
    height: var(--icon-size-xxsmall);
    width: var(--icon-size-xxsmall);
    min-width: var(--icon-size-xxsmall);
    min-height: unset;
  }
  
  .Icon--xSmall {
    height: var(--icon-size-xsmall);
    width: var(--icon-size-xsmall);
    min-width: var(--icon-size-xsmall);
    min-height: unset;
  }
  
  .Icon--small {
    height: var(--icon-size-small);
    width: var(--icon-size-small);
    min-width: var(--icon-size-small);
    min-height: unset;
  }
  
  .Icon--smallMedium {
    height: var(--icon-size-small-medium);
    width: var(--icon-size-small-medium);
    min-width: var(--icon-size-small-medium);
    min-height: unset;
  }
  
  .Icon--medium {
    height: var(--icon-size-medium);
    width: var(--icon-size-medium);
    min-width: var(--icon-size-medium);
    min-height: unset;
  }
  
  .Icon--large {
    height: var(--icon-size-large);
    width: var(--icon-size-large);
    min-width: var(--icon-size-large);
    min-height: unset;
  }
  
  .Icon--xLarge {
    height: var(--icon-size-xlarge);
    width: var(--icon-size-xlarge);
    min-width: var(--icon-size-xlarge);
    min-height: unset;
  }
  .Icon--xxLarge {
    height: var(--icon-size-xxlarge);
    width: var(--icon-size-xxlarge);
    min-width: var(--icon-size-xxlarge);
    min-height: unset;
  }
  
  .Icon--rotate-90 {
    transform: rotate(90deg);
  }
  
  .Icon--rotate-180 {
    transform: rotate(180deg);
  }
  
  .Icon--rotate-270 {
    transform: rotate(270deg);
  }
  