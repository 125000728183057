.MenuItemMulti {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem 0.25rem 0;
  cursor: pointer;
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
  border-radius: var(--border-radius-sm, 4px);
  margin-bottom: 0.25rem;
}

.MenuItemMulti-checkbox {
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
}

.MenuItemMulti-checkbox .Checkbox.Checkbox--base {
  height: 1rem;
}

.MenuItemMulti:not(.MenuItemMulti--isDisabled):hover {
  /* TODO: use css var when working */
  background: rgba(55, 81, 255, 0.08);
}

.MenuItemMulti:not(.MenuItemMulti--isDisabled):active {
  /* TODO: use css var when working */
  background: rgba(55, 81, 255, 0.1);
}

.MenuItemMulti:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  border-radius: 4px;
}

.MenuItemMulti--isDisabled {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
}
.MenuItemMulti--isDisabled:hover {
  cursor: not-allowed;
}

.MenuItemMulti-checkedDivider {
  border-bottom: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
}
