.Badge {
  position: relative;
}

.Badge-number {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-bold-3);
  height: 1.5rem;
  width: 1.5rem;
  /* padding: 0.125rem 0.4375rem; */
}

.Badge--dotNumber {
  color: var(--color-foreground-neutral-inverse, #fff);
  font: var(--typography-body-bold-3);
}
.Badge--dotNumber.Badge--warning .Badge-number {
  color: var(--color-foreground-neutral-bold);
}

.Badge--dotNumber .Badge-number {
  /* TODO: get the number centered in the middle of the dot. Not needed for cpam */
  color: var(--color-foreground-neutral-inverse, #fff);
  font: var(--typography-body-bold-3);

  position: absolute;
  top: 0;
  left: 0;
}

.Badge--dot.Badge--neutral .Icon svg circle,
.Badge--dotNumber.Badge--neutral .Icon svg circle {
  fill: var(--color-background-neutral-bold, #5c5e6e);
  stroke: var(--color-border-secondary-inverse, #f2f4f8);
  stroke-width: 0.125rem;
}
.Badge--dot.Badge--primary .Icon svg circle,
.Badge--dotNumber.Badge--primary .Icon svg circle {
  fill: var(--color-background-primary-bold, #3751ff);
  stroke: var(--color-border-secondary-inverse, #f2f4f8);
  stroke-width: 0.125rem;
}
.Badge--dot.Badge--secondary .Icon svg circle,
.Badge--dotNumber.Badge--secondary .Icon svg circle {
  fill: var(--color-background-secondary-bold, #254b86);
  stroke: var(--color-border-secondary-inverse, #f2f4f8);
  stroke-width: 0.125rem;
}

.Badge--dot.Badge--error .Icon svg circle,
.Badge--dotNumber.Badge--error .Icon svg circle {
  fill: var(--color-background-critical-bold, #cc1c10);
  stroke: var(--color-border-secondary-inverse, #f2f4f8);
  stroke-width: 0.125rem;
}

.Badge--dot.Badge--success .Icon svg circle,
.Badge--dotNumber.Badge--success .Icon svg circle {
  fill: var(--color-background-success-bold, #028329);
  stroke: var(--color-border-secondary-inverse, #f2f4f8);
  stroke-width: 0.125rem;
}

.Badge--dot.Badge--warning .Icon svg {
  border-radius: 50%;
}
.Badge--dotNumber.Badge--warning .Icon svg circle {
  fill: var(--color-background-warning-bold, #f6a91c);
}
.Badge--dot.Badge--warning .Icon svg circle {
  fill: var(--color-background-warning-bold, #f6a91c);
  stroke: var(--color-border-warning-medium, #a36a00);
  stroke-width: 0.0625rem;
}

.Badge--dot.Badge--info .Icon svg circle,
.Badge--dotNumber.Badge--info .Icon svg circle {
  fill: var(--color-background-info-bold, #573bdc);
  stroke: var(--color-border-secondary-inverse, #f2f4f8);
  stroke-width: 0.125rem;
}

.Badge--small,
.Badge--small .Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0.625rem;
  min-width: 0.625rem;
  height: 0.625rem;
  width: 0.625rem;
}

.Badge--medium,
.Badge--medium .Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1rem;
  min-width: 1rem;
  height: 1rem;
  width: 1rem;
}

.Badge--large,
.Badge--large .Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}
