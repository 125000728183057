.TooltipComponent-container {
  position: relative;
  width: min-content;
}

.TooltipComponent {
  position: absolute;
  background: var(--color-background-neutral-inverse, #212328);
  border: none;
  min-height: 2rem;
  width: max-content;
  padding: 0.5rem 0.75rem;
  border-radius: var(--border-radius-sm, 0.25rem);
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);
  z-index: var(--z-index-tooltip);
  visibility: hidden;
  opacity: 0;
  transition: unset;
  pointer-events: none;
}
.TooltipComponent--isVisible {
  visibility: visible;
  height: auto;
  opacity: 1;
  transition:
    opacity 0.1s ease-in-out,
    visibility 0s linear 0.1s;
}
.TooltipComponent-text {
  font: var(--typography-body-3);
  color: var(--color-foreground-neutral-inverse, #fff);
  margin: 0;
}

.TooltipComponent--topLeft {
  top: -0.75rem;
  left: 0;
  transform: translateY(-100%);
}
.TooltipComponent--top {
  top: -0.75rem;
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.TooltipComponent--topRight {
  top: -0.75rem;
  right: 0;
  transform: translateY(-100%);
}

.TooltipComponent--rightTop,
.TooltipComponent--right,
.TooltipComponent--rightBottom {
  top: 50%;
  right: -0.75rem;
  transform: translateY(-50%) translateX(100%);
}
.TooltipComponent--rightTop {
  top: 0;
  transform: translateX(100%);
}
.TooltipComponent--rightBottom {
  top: unset;
  bottom: 0;
  transform: translateX(100%);
}

.TooltipComponent--bottomLeft {
  bottom: -0.75rem;
  left: 0;
  transform: translateY(100%);
}
.TooltipComponent--bottom {
  bottom: -0.75rem;
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.TooltipComponent--bottomRight {
  bottom: -0.75rem;
  right: 0;
  transform: translateY(100%);
}

.TooltipComponent--leftTop,
.TooltipComponent--left,
.TooltipComponent--leftBottom {
  top: 50%;
  left: -0.75rem;
  transform: translateY(-50%) translateX(-100%);
}
.TooltipComponent--leftTop {
  top: 0;
  transform: translateX(-100%);
}
.TooltipComponent--leftBottom {
  top: unset;
  bottom: 0;
  transform: translateX(-100%);
}

/* Everything Arrow */
.TooltipComponent-arrow {
  position: absolute;
}
.TooltipComponent-arrow svg path {
  fill: var(--color-background-neutral-inverse, #212328);
}
.TooltipComponent-arrow .Icon {
  transition: unset;
}

/* Axis */
.TooltipComponent-arrow--onY {
  margin: 0 0.4375rem;
  height: 0.5rem;
  width: 1rem;
}
.TooltipComponent-arrow--onX {
  margin: 0.4375rem 0;
  height: 1rem;
  width: 0.5rem;
}

/* Arrow position */
.TooltipComponent-arrow--topLeft,
.TooltipComponent-arrow--top,
.TooltipComponent-arrow--topRight {
  bottom: 0.5rem;
  transform: translateY(150%) translateX(calc(-50% - 0.4375rem));
}
.TooltipComponent-arrow--topLeft {
  left: 0.9375rem;
}
.TooltipComponent-arrow--top {
  left: 50%;
}
.TooltipComponent-arrow--topRight {
  right: -0.9375rem;
}

.TooltipComponent-arrow--rightTop,
.TooltipComponent-arrow--right,
.TooltipComponent-arrow--rightBottom {
  left: 0.0625rem;
  transform: translateX(calc(-50% - 0.5rem));
}
.TooltipComponent-arrow--rightTop {
  top: -0.4375rem;
  transform: translateX(calc(-50% - 0.5rem)) translateY(50%);
}
.TooltipComponent-arrow--right {
  top: calc(50% - 0.9375rem);
}
.TooltipComponent-arrow--rightBottom {
  bottom: 0;
}

.TooltipComponent-arrow--bottomLeft,
.TooltipComponent-arrow--bottom,
.TooltipComponent-arrow--bottomRight {
  top: 0;
  transform: translateY(-150%) translateX(calc(-50% - 0.4375rem));
}
.TooltipComponent-arrow--bottomLeft {
  left: 0.9375rem;
}
.TooltipComponent-arrow--bottom {
  left: 50%;
}
.TooltipComponent-arrow--bottomRight {
  right: -0.9375rem;
}

.TooltipComponent-arrow--leftTop,
.TooltipComponent-arrow--left,
.TooltipComponent-arrow--leftBottom {
  right: 0.0625rem;
  transform: translateX(50%);
}
.TooltipComponent-arrow--leftTop {
  top: 0;
}
.TooltipComponent-arrow--left {
  top: calc(50% - 0.9375rem);
}
.TooltipComponent-arrow--leftBottom {
  bottom: 0;
}
