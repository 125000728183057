.table-container {
  max-width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.version-container {
  display: flex;
  justify-content: center;
  .table-head-row {
    background-color: var(--dark-text);
  }
  .th-col {
    color: var(--home-black-dark);
  }
  .table-tr:nth-child(even) {
    background-color: var(--grey-border);
  }
  .td-ndf {
    display: flex;
    justify-content: center;
  }
}
