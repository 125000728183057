.ButtonSelect--pill {
  display: inline-flex;
  position: relative;
  height: 2rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 120px;
  border: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
  cursor: pointer;
  font: var(--typography-body-2);
  background: var(--color-background-neutral-subtlest, #fff);
}

.ButtonSelect--pill .ButtonSelect-label {
  flex: 1 1 auto;
  color: var(--color-foreground-secondary-medium, #254b86);
  font: var(--typography-body-2);
  padding: 0.1875rem 0.375rem;
  cursor: pointer;

  max-width: 10.9375rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: initial; /* override Label component */
}

.ButtonSelect--pill.ButtonSelect--hasBadge .ButtonSelect-label {
  color: var(--color-foreground-secondary-medium, #254b86);
  font: var(--typography-body-bold-2);
}

.ButtonSelect--pill:hover {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);

  /* TODO: update when variable is working
  background: var(
    --color-background-secondary-on-subtle-hover,
    rgba(37, 75, 134, 0.08)
  ); */
  background: rgba(37, 75, 134, 0.08);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

.ButtonSelect--pill:active {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);

  /* TODO: update when variable is working
  background: var(
    --color-background-secondary-on-subtle-pressed,
    rgba(37, 75, 134, 0.12)
  ); */
  background: rgba(37, 75, 134, 0.12);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

/* isOpen variations */
.ButtonSelect--pill--isOpen {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: var(--color-background-secondary-subtle, #f2f4f8);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

.ButtonSelect--pill--isOpen:hover {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: var(--color-background-secondary-subtle, #f2f4f8);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

.ButtonSelect--pill--isOpen:active {
  border: 1px solid var(--color-border-secondary-medium, #254b86);

  /* TODO: update when variable is working
  background: var(
    --color-background-secondary-on-subtle-pressed,
    rgba(37, 75, 134, 0.12)
  ); */
  background: rgba(37, 75, 134, 0.12);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.06);
}

/* isDisabled
(out of scope for cpam - also change the colors inside the button) */
.ButtonSelect--pill.ButtonSelect--isDisabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  border: 0;
  pointer-events: none;
}

.u-button--isLabelAppended {
  /* used w/ MenuMulti and ButtonSelect (pill) */
  color: var(--color-foreground-secondary-medium, #254b86);
  font: var(--typography-body-bold-2);
}

.ButtonSelect--pill .Badge {
  position: absolute;
}

/* badge placement variations */
.ButtonSelect--pill .Badge--small {
  right: 0.0625rem;
  top: -0.25rem;
}
/* TODO: add the rest of the badge sizes */

/* icon micro adjustments */
.ButtonSelect--pill-icon,
.ButtonSelect--pill-chevron {
  height: 1rem;
  width: 1rem;
}
