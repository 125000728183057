.ButtonSelect--rectangle {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  padding: 0.25rem 0.75rem;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
  cursor: pointer;
  font: var(--typography-body-2);
  background: var(--color-background-neutral-subtlest, #fff);
}

.ButtonSelect--rectangle .ButtonSelect-label {
  cursor: pointer;
}

/* size variations */
.ButtonSelect--rectangle.ButtonSelect--small {
  height: 2rem;
}
.ButtonSelect--rectangle.ButtonSelect--medium {
  height: 2.5rem;
}
.ButtonSelect--rectangle.ButtonSelect--large {
  height: 3rem;
}

.ButtonSelect--rectangle .ButtonSelect-label {
  color: var(--color-foreground-secondary-medium, #254b86);
  padding: 0.1875rem 0.125rem 0.1875rem 0.25rem;
  font: var(--typography-body-2);
}

.ButtonSelect--rectangle.ButtonSelect--hasBadge .ButtonSelect-label {
  color: var(--color-foreground-secondary-medium, #254b86);
  font: var(--typography-body-bold-2);
}

.ButtonSelect--rectangle:hover {
  /* TODO: update when variable is working.
  background: var(
    --color-background-secondary-on-subtle-hover,
    rgba(37, 75, 134, 0.08)
  ); */
  background: rgba(37, 75, 134, 0.08);
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

.ButtonSelect--rectangle:active {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
  /* TODO: update when variable is working.
  background: var(
    --color-background-secondary-on-subtle-pressed,
    rgba(37, 75, 134, 0.12)
  ); */
  background: rgba(37, 75, 134, 0.12);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

/* isOpen variations */
.ButtonSelect--rectangle--isOpen {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: var(--color-background-secondary-subtle, #f2f4f8);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

.ButtonSelect--rectangle--isOpen:hover {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  background: var(--color-background-secondary-subtle, #f2f4f8);

  /* boxShadow/button/interaction */
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.06);
}

.ButtonSelect--rectangle--isOpen:active {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
  /* TODO: update when variable is working.
  background: var(
    --color-background-secondary-on-subtle-pressed,
    rgba(37, 75, 134, 0.12)
  ); */

  background: rgba(37, 75, 134, 0.12);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}

/* isDisabled
(out of scope for cpam - also change the colors inside the button) */
.ButtonSelect--rectangle.ButtonSelect--isDisabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
  border: 0;
  pointer-events: none;
}

.ButtonSelect--rectangle .Badge {
  position: absolute;
}

/* badge placement variations */
.ButtonSelect--rectangle .Badge--small {
  right: 0.0625rem;
  top: -0.25rem;
}
/* TODO: add the rest of the badge sizes */

/* icon container heights */
.ButtonSelect--rectangle-icon,
.ButtonSelect--rectangle-chevron {
  height: 1.25rem;
  width: 1.25rem;
}
.ButtonSelect--small .ButtonSelect--rectangle-icon,
.ButtonSelect--small .ButtonSelect--rectangle-chevron {
  height: 1rem;
  width: 1rem;
}
