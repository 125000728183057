.MenuMulti {
  position: relative;
}

.MenuMulti-buttonDefault {
  /* TODO: add styles to default trigger if needed */
}

.MenuMulti-dropdown {
  position: absolute;
  background: var(--color-background-neutral-subtlest, #fff);
  z-index: var(--z-index-dropdown);
  border-radius: var(--border-radius-sm);
  padding: 0 0.25rem 0.25rem 0.25rem;
  margin-top: 0.5rem;

  /* TODO: use token when ready */
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);

  opacity: 0;
  transform-origin: top;
  transform: scaleY(0);
  transition:
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;
}

.MenuMulti-items {
  list-style: none;
  margin: 0.5rem 0 0 0;
  padding: 0;
  min-width: min-content;
  white-space: nowrap;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 4px);
}
.MenuMulti-items:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
}

.MenuMulti--isSearchBarVisible .MenuMulti-items {
  margin: 0;
  padding-top: 0.25rem;
}

.MenuMulti-dropdown--isVisible {
  transform: scaleY(1);
  opacity: 1;
}

.MenuMulti button label {
  display: initial; /* fix text spacing issue in button label */
}
