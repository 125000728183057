.SpvPendingSession {
  width: 100%;
  height: 100%;
}

.SpvPendingSession-tabGroup {
  width: 100%;
  height: 2.5rem;
  padding: 0 0.5rem 2.4rem 0.5rem;
  border-bottom: 2px solid var(--color-border-secondary-subtlest, #cfd9e9);
}

.SpvPendingSession-tabContent {
  display: flex;
  padding: 0 1.5rem;
  max-height: calc(100vh - 17rem);
  flex-direction: column;
  overflow-y: auto;
}

.SpvPendingSession-assetTab {
  display: flex;
  max-height: calc(100vh - 17rem);
  flex-direction: column;
  overflow-y: auto;
}

.SpvPendingSession-tabSection {
  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
}

.SpvPendingSession-downloadFile {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
