.MenuMultiFooter {
  display: flex;
  height: 2rem;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  justify-content: space-between;
  border-radius: 0 0 var(--border-radius-sm, 4px) var(--border-radius-sm, 4px);

  background: var(--color-background-neutral-subtlest, #fff);
  box-shadow: var(--box-shadow-pretoken-overlay-top);
}
