.SpvSection {
  width: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.SpvSection-title {
  color: var(--color-foreground-neutral-bold, #212328);
}

.SpvSection-field {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.SpvSection-label {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.SpvSection-value {
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-foreground-secondary-bold, #00245b);
}
