/* This class is for future responsivness on overly large screens. */
.ModalPageHeader-background {
  margin: 0 auto;
  width: 100%;
  background: var(--color-background-secondary-inverse, #001a40);
  color: #fff;
  z-index: var(--z-index-page-header);
}

.ModalPageHeader {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 3rem;
  width: 100%;
  background: var(--color-background-secondary-inverse, #001a40);
}

.ModalPageHeader-textContainer {
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.125rem;
  width: 100%;
}

.ModalPageHeader-title {
  font: var(--typography-heading-1);
  margin: 0;
}

.ModalPageHeader-subHeadingContent {
  color: var(--color-background-neutral-subtlest, #fff);
  font: var(--typography-body-2);
}
.ModalPageHeader-subHeadingContent p {
  margin: 0;
}

.ModalPageHeader-closeButton {
  min-width: 2.5rem;
}
