
.ListRow-labelValue,
.ListRow-title,
.ListRow-title-content {
  display: flex;
}

.ListRow-labelValue-label {
  color: var(--color-foreground-neutral-subtle, #717385);
  font: var(--typography-body-3);
  line-height: 1.5rem;
}

.ListRow-dot {
  height: 0.25rem;
  width: 0.25rem;
  background-color: var(--color-foreground-neutral-bold, #212328);
  border-radius: 0.25rem;
  display: inline-block;
  position: relative;
  top: 0.5625rem;
  margin: 0 0.5rem;
}

.ListRow-labelValue-text {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-3);
  line-height: 1.5rem;
}
