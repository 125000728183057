.container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.appBar {
    box-shadow: none !important;
}

.mobileAppBar {
    box-shadow: none !important;
    background-color: #00245B !important;
}

.iconContainer {
    padding-right: 10px;
}

.navUsername {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-left: 16px;
    max-width: 297px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.headerUsernameTooltip {
    left: -42px;
    max-width: none;
}

.profileContainer {
    display: flex;
    align-items: center;
}

.profileMainContainer {
    display: flex;
    align-items: center;
}