/* TODO: styles are only applied to MenuAction for now.
- Use with other menus moving forward as needed.
- Remove/adjust unsets below holistically when adjusting other menus.
- Make micro-adjustments for positioning as needed per figma.
- MenuAction has a dropdownContainer element used for positioning and measuring.
*/

.MenuAction {
  width: max-content;
  position: relative;
}

/* increase specficity to override */
.MenuAction.MenuAction .Menu-dropdown--isVisible {
  margin-bottom: unset; /* TODO: check other menus to unify the margin */
  margin-top: unset;
}

.MenuAction .Menu-dropdown {
  padding: 0.25rem; /* TODO: check if other menus need this to even out spacing */
  position: unset;
  margin-bottom: unset; /* TODO: check other menus to unify the margin */
  margin-top: unset;
}

.MenuAction.MenuAction .Menu-dropdownContainer {
  position: absolute;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  z-index: var(--z-index-dropdown);
  pointer-events: none; /* allow clicks to pass through */
}
.MenuAction .Menu-dropdownContainer.Menu-dropdown--isVisible {
  pointer-events: auto;
}

/* menu top and bottom positions are commom
to both edge to edge and overlap modes */

/* container is for positioning,
.Menu-dropdown is for the animation */

.Menu-dropdownContainer.Menu-dropdown--top {
  bottom: 100%; /* place menu above the trigger */
  left: 50%;
  transform: translateX(-50%); /* pull menu back to center */
}
.Menu-dropdownContainer.Menu-dropdown--top .Menu-dropdown {
  transform-origin: bottom;
}

.Menu-dropdownContainer.Menu-dropdown--bottom {
  top: 100%; /* place below trigger */
  left: 50%;
  transform: translateX(-50%); /* pull menu back to center */
}

/* start edge to edge positions */
.Menu-dropdownContainer.Menu-dropdown--edgeToEdge.Menu-dropdown--topLeft {
  bottom: 100%; /* align bottom of menu with top of trigger */
  right: 100%; /* align right side of menu with left of trigger */
}
.Menu-dropdownContainer.Menu-dropdown--edgeToEdge.Menu-dropdown--topRight {
  bottom: 100%;
  left: 100%; /* align left side of menu with right side of trigger */
}
.Menu-dropdownContainer.Menu-dropdown--edgeToEdge.Menu-dropdown--bottomLeft {
  top: 100%; /* align top of menu with bottom of trigger */
  right: 100%; /* align right side of menu with left of trigger */
}
.Menu-dropdownContainer.Menu-dropdown--edgeToEdge.Menu-dropdown--bottomRight {
  top: 100%;
  left: 100%; /* align left side of menu with right of trigger */
}
/* end edge to edge positions */

/* start overlap positions */
/* overlap example: when 'bottomLeft', the right edge
of the menu is aligned with the left edge of the trigger */
.Menu-dropdownContainer.Menu-dropdown--overlap.Menu-dropdown--bottomLeft {
  top: 100%; /* place menu below the trigger */
  right: 0; /* align menu right edge with trigger right edge */
}
.Menu-dropdownContainer.Menu-dropdown--overlap.Menu-dropdown--bottomRight {
  top: 100%;
  left: 0; /* align menu left edge with trigger left edge */
}

.Menu-dropdownContainer.Menu-dropdown--overlap.Menu-dropdown--topLeft {
  bottom: 100%; /* place menu above the trigger */
  right: 0; /* align menu right edge with trigger right edge */
  margin-bottom: 0.5rem;
  transform-origin: bottom;
}
.Menu-dropdownContainer.Menu-dropdown--topLeft .Menu-dropdown {
  transform-origin: bottom;
}

.Menu-dropdownContainer.Menu-dropdown--overlap.Menu-dropdown--topRight {
  bottom: 100%;
  left: 0; /* align menu left edge with trigger left edge */
  margin-bottom: 0.5rem;
}
.Menu-dropdownContainer.Menu-dropdown--topRight .Menu-dropdown {
  transform-origin: bottom;
}
/* end overlap positions */
