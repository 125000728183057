.TabGroup {
  display: inline-flex;
  width: 100%;
  border-bottom: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
}

.TabContent {
    margin-top: 0;
}

  
.TabItem {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    gap: 0.25rem;
    padding: 0.5rem 0.75rem;
    min-height: var(--sizing-action-lg, 2.5rem);
  
    cursor: pointer;
    color: var(--color-foreground-neutral-bold, #212328);
    font-feature-settings:
      'clig' off,
      'liga' off;
    border-bottom: 2px solid transparent;
    position: relative;
    bottom: -1px;
  }
  
  /* sizes */
  .TabItem--medium {
    font: var(--typography-body-2);
  }
  
  .TabItem--large {
    font: var(--typography-body-1);
  }
  
  /* size isActive fonts */
  .TabItem--large.TabItem--isActive {
    font: var(--typography-body-bold-1);
    font-weight: 500;
  }
  
  .TabItem--medium.TabItem--isActive {
    font: var(--typography-body-bold-2);
  }
  
  /* states */
  .TabItem--isActive {
    color: var(--color-foreground-secondary-medium, #254b86);
    border-bottom: 2px solid var(--color-border-secondary-medium, #254b86);
    transition: border-color 0.2s ease;
    font-weight: 500;
  }
  
  .TabItem--isActive svg path {
    fill: var(--color-foreground-secondary-bold, #00245b);
  }
  
  .TabItem:focus-visible {
    border-radius: var(--border-radius-sm, 4px);
    outline: 3px solid var(--color-border-focus, #6e7fff);
  }
  
  .TabItem:hover {
    border-bottom: 2px solid var(--color-border-secondary-subtle, #6888b5);
  }
  