/* Overlay */
/* Overlay Base */
.Button-overlay {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-sm, 4px);
  background: transparent;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}
/* Overlay Hover Kinds */
.Button-overlay-filled:hover,
.Button-content-container:hover + .Button-overlay-filled {
  opacity: 1;
  /* //TODO: this overlay color var is not working */
  background: rgba(255, 255, 255, 0.24);
}
.Button-outlined:hover,
.Button-outlined:active {
  border: 1px solid var(--color-border-primary-medium, #3751ff);
}
.Button-overlay-outlined:hover,
.Button:hover .Button-overlay-outlined,
.Button-content-container:hover + .Button-overlay-outlined {
  opacity: 1;
  /* //TODO: this overlay color var is not working */
  background: rgba(55, 81, 255, 0.08);
}
.Button-overlay-subtle:hover,
.Button-content-container:hover + .Button-overlay-subtle {
  opacity: 1;
  /* //TODO: this overlay color var is not working */
  background: rgba(55, 81, 255, 0.08);
}
.Button-overlay-ghost:hover,
.Button-content-container:hover + .Button-overlay-ghost {
  opacity: 1;
  /* //TODO: this overlay color var is not working */
  background: rgba(55, 81, 255, 0.08);
}
.Button-subtle:hover,
.Button-ghost:hover {
  /* These kinds don't have box-shadow */
  box-shadow: unset;
}
/* Overlay Pressed */
.Button-overlay-filled:active,
.Button:active .Button-overlay,
.Button-content-container:active + .Button-overlay-filled {
  opacity: 1;
  /* //TODO: this overlay color var is not working */
  background: rgba(33, 35, 40, 0.32);
}
.Button-overlay-outlined:active,
.Button:active .Button-overlay,
.Button-content-container:active + .Button-overlay-outlined {
  opacity: 1;
  /* //TODO: this overlay color var is not working */
  background: rgba(55, 81, 255, 0.12);
}
.Button-overlay-subtle:active,
.Button:active .Button-overlay,
.Button-content-container:active + .Button-overlay-subtle {
  opacity: 1;
  /* //TODO: this overlay color var is not working */
  background: rgba(55, 81, 255, 0.12);
}
.Button-overlay-ghost:active,
.Button:active .Button-overlay,
.Button-content-container:active + .Button-overlay-ghost {
  opacity: 1;
  /* //TODO: this overlay color var is not working */
  background: rgba(55, 81, 255, 0.12);
}
