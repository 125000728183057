.LandingPageSidePanel {
  width: 100%;
  height: 100%;
}

.LandingPageSidePanel-header {
  border-bottom: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
}

/* Override the SidePanel DS component classname */
.SidePanel-layout.LandingPageSidePanel-layout {
  overflow: unset;
}
