.ListRow-container {
  position: relative;
}

.ListRow-row-data {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-sm);
  border: 1px solid transparent;
  max-width: 100%;
  background-color: #ffffff;
  transition: all 200ms ease-in-out;
}

.ListRow-row-data:hover {
  box-shadow: none;
  cursor: pointer;
}

.ListRow-hover-span {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  transition: all 200ms ease-in-out;
  border-radius: 0.25rem;
}

.ListRow-container:hover .ListRow-hover-span {
  box-shadow: var(--box-shadow-pretoken-container-interaction);
}

.ListRow-row-data:hover .ListRow-bookmark {
  opacity: 1;
}

.ListRow-sectionLeft {
  display: flex;
  flex: 1;
  min-width: 0;
  text-overflow: ellipsis;
}

.ListRow-bookmark-badgeContainer {
  box-sizing: border-box;
  width: 2rem;
  padding: 1.25rem 0.5rem;
}

.ListRow-bookmark {
  display: flex;
  align-self: flex-start;
  transform: translate(-0.25rem, -1.4375rem);
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.ListRow-bookmark:hover svg path {
  fill: var(--color-foreground-warning-subtle, #e19701);
}

.ListRow-applicationIcon {
  padding: 0.75rem 0.25rem;
}

.ListRow-icon {
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
}

.ListRow-icon:hover {
  cursor: pointer;
}

.ListRow-sectionRight {
  display: flex;
  margin-right: 0.5rem;
  min-width: max-content;
}

.ListRow-label-chip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 7.5rem;
  background: var(--color-background-info-subtle, #f1ecfd);
  color: var(--color-foreground-info-bold, #332196);
  text-overflow: ellipsis;
  white-space: nowrap;
  font: var(--typography-body-2);
  height: 2rem;
  margin-top: 0.3rem;
}

.ListRow-verticalIcon:hover {
  cursor: pointer;
}

.ListRow-verticalIcon-hidden {
  visibility: hidden;
}

.ListRow-status {
  margin-top: 0.3rem;
}
