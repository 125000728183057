.ModalIsland.ViewScheduleModalIsland {
  max-width: 42.5rem;
}

.ViewScheduleModalIsland-modalIslandContent {
  width: 100%;
  height: 45rem;
}

.ViewScheduleModalIsland .ModalIsland-section {
  width: 100%;
  max-width: calc(100vw - 3rem);
}
#ModalIsland.ModalIsland.ViewScheduleModalIsland
  .ModalIsland-section
  .ModalIsland-body {
  background: var(--color-background-neutral-subtlest, #ffffff);
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}

.ViewScheduleModalIsland-headerSubtitle {
  display: flex;
  gap: 0.25rem;
}

.ViewScheduleModalIsland-filters {
  width: fit-content;
  padding: 0 0 1rem;
}

.ViewScheduleModalIsland-footerActionBar {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  width: 100%;
}

/* Header Subheading */
.ViewScheduleModalIsland-detailName {
  color: var(--color-foreground-neutral-subtle, #717385);
}
.ViewScheduleModalIsland-detailInfo {
  color: var(--color-foreground-secondary-bold, #00245b);
}
