.SidePanel {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  border-left: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
  background: #fff;
  height: 100%;
  width: 27.1875rem;
  z-index: 2;
}

.SidePanel-header {
  display: flex;
  padding: 1rem;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.SidePanel-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.SidePanel-footer {
  display: flex;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.SidePanel-header-icon,
.SidePanel-header-icon svg {
  width: 3rem;
  height: 3rem;
}

.SidePanel-header-text {
  padding-left: 0.75rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  align-self: center;
}

.SidePanel-header-title {
  align-self: stretch;
  color: var(--color-foreground-neutral-bold, #212328);
}

.SidePanel-header-description {
  color: var(--color-foreground-neutral-subtle, #717385);
}
