/* TODO: Reduce specificity if we ever fix the style build order */
/* Calendar divs */
.react-datepicker__navigation.react-datepicker__navigation {
  top: 1.0625rem;
  width: 2rem;
  height: 2rem;
  border: none;
  transition: all 0.1s ease-in-out;
  border-radius: var(--border-radius-circle, 1584px);
}
.react-datepicker__navigation.react-datepicker__navigation:hover {
  background-color: rgba(92, 94, 110, 0.12);
  box-shadow: var(--box-shadow-pretoken-button-interaction);
}
.react-datepicker__navigation--previous.react-datepicker__navigation--previous {
  left: 11.75rem;
}
.react-datepicker__navigation--next.react-datepicker__navigation--next {
  left: 14.75rem;
}
/* TODO: Replace with ButtonIcon */
.react-datepicker__navigation-icon.react-datepicker__navigation-icon::before {
  top: 0.125rem;
  right: -0.5rem;
  border: none;
  transform: rotate(90deg);
  background-image: url('data:image/svg+xml, %3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cg%20id%3D%22chevron-down%22%3E%0A%3Cpath%20id%3D%22Vector%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3.14645%207.39669C3.34171%207.20143%203.65829%207.20143%203.85355%207.39669L12%2015.5431L20.1464%207.39669C20.3417%207.20143%2020.6583%207.20143%2020.8536%207.39669C21.0488%207.59195%2021.0488%207.90854%2020.8536%208.1038L12.3536%2016.6038C12.1583%2016.7991%2011.8417%2016.7991%2011.6464%2016.6038L3.14645%208.1038C2.95118%207.90854%202.95118%207.59195%203.14645%207.39669Z%22%20fill%3D%22%23212328%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
  background-size: cover;
  background-repeat: no-repeat;
  width: 1.25rem;
  height: 1.25rem;
}
.react-datepicker__navigation--next.react-datepicker__navigation--next
  .react-datepicker__navigation-icon::before {
  transform: rotate(-90deg);
}

.react-datepicker__header.react-datepicker__header,
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border: none;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-width-xlg);
  padding: 0;
}
.react-datepicker__header__dropdown.react-datepicker__header__dropdown {
  display: flex;
  padding: 1rem 1rem 0.5rem 0.5rem;
  height: 3.5rem;
}

.react-datepicker__day-names.react-datepicker__day-names {
  display: flex;
  flex-direction: row;
  justify-content: center;
  white-space: wrap;
}
.react-datepicker__day-name.react-datepicker__day-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.5rem;
  margin: 0;
  font: var(--typography-body-3);
  color: var(--color-foreground-neutral-subtle, #717385);
}

.react-datepicker__week.react-datepicker__week {
  display: flex;
  border-radius: 0.25rem;
  overflow: hidden;
}

.react-datepicker__day.react-datepicker__day {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 1.125rem;
  margin: 0;
  font: var(--typography-body-2);
  transition: all 0.1s ease-in-out;
  background: var(--color-background-neutral-subtlest, #fff);
}
.react-datepicker__day--today.react-datepicker__day--today {
  font: var(--typography-body-2);
  background: var(--color-background-neutral-subtlest, #fff);
  color: var(--color-foreground-neutral-bold, #212328);
  border: 1px solid var(--color-border-neutral-sublte, #5c5e6e);
  border-radius: 1.125rem;
}
.react-datepicker__day--today.react-datepicker__day--today:hover {
  background: var(--color-background-secondary-bold, #254b86);
  color: var(--color-foreground-neutral-inverse, #fff);
  border: 1px solid transparent;
}
.react-datepicker__day--selected.react-datepicker__day--selected {
  font: var(--typography-body-bold-2);
  color: var(--color-foreground-neutral-inverse, #fff);
  border: none;
  background: var(--color-background-secondary-bold, #254b86);
}
.react-datepicker__day--selected.react-datepicker__day--selected:hover {
  background: var(--color-background-secondary-bold, #254b86);
}
.react-datepicker__day.react-datepicker__day:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 1.125rem;
  margin: 0;
}
.react-datepicker__day--disabled.react-datepicker__day--disabled {
  font: var(--typography-body-2);
  color: var(--color-foreground-neutral-subtle, #717385);
  pointer-events: none;
}
