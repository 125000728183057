.recent-requests-section {
  margin: 40px 33px 48px 48px;
  .recent-requests-fetch-error {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid rgba(204, 28, 16, 1);
    border-radius: 24px;
    padding: 10px;
    background: rgba(253, 245, 244, 1);
    .link-button {
      color: var(--color-indigo-700, #3751ff);
      font-family: Rubik;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      padding-left: 0.5rem;
    }
    .recent-requests-alert-icon {
      display: flex;
    }
    .recent-requests-error-text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
    .recent-requests-error-description {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
    span.ModalIsland-alertContent-icon {
      min-height: 40px;
      min-width: 40px;
    }
  }
  .title-section {
    align-items: center;
    margin-bottom: 24px;
    .my-recent-title {
      color: #212328;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 0;
    }
    .view-all {
      margin-left: 16px;
      color: #3852ff;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
    .recent-requests-logo {
      display: none;
    }
  }
  .recent-request-table {
    border-collapse: separate;
    width: 100%;
    border-spacing: 0;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    padding: 18px 21px 18px 42px;
    tr th {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding-bottom: 20px;
      padding-left: 16px;
      word-break: break-all;
    }
    tr td {
      text-align: left;
      padding: 15px;
      border-top: 1px solid #e3e3e3;
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      max-width: 20rem;
      .TooltipComponent-container {
        width: 100%;
      }
    }
    .type-id-column {
      .request-details {
        margin-left: 17px;
        h5 {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
          margin: 0;
        }
        span {
          color: #4a4a4a;
          font-size: 14px;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
    .request-status {
      max-width: max-content;
      border-radius: 120px;
      padding: 4px 10px;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
    .request-status.inprogress {
      color: #332196;
      background-color: #f1ecfd;
    }
    .request-status.complete {
      color: #033a0f;
      background-color: #e5f7e0;
    }
    .request-status.expired {
      color: #5c3c00;
      background-color: #fff1d9;
    }
    .request-status.canceled {
      color: #212328;
      background-color: #ebecf2;
    }
    .requests-navigate-button {
      border: 1px solid #6888b5;
      width: 40px;
      height: 40px;
      padding: 0;
    }
  }
  .requestedfor-section, .requestedfor-section .endpoint-name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 2;
  }
  
  .requestedfor-tooltip-section {
    word-break: break-all;
    max-width: 100%;
  }
  .no-request-section {
    border-radius: 25px;
    background: #fff;
    box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.04);
    padding: 40px 32px;
    display: flex;
    align-items: center;
    .no-request-message {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.4px;
      padding-right: 10px;
    }
    .start-request-btn {
      color: #3852ff;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      &:hover {
        background-color: #fff;
      }
    }
  }
}
.endpoints-list {
  .endpoint {
    padding-bottom: 8px;
  }
  .endpoint-name {
    padding-left: 4px;
    color: #141519;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
  .more-option {
    color: #141519;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    cursor: grab;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .recent-requests-section {
    .recent-request-table {
      padding: 18px 21px;
      tr th:nth-child(3),
      tr th:nth-child(4),
      tr td:nth-child(3),
      tr td:nth-child(4) {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .recent-requests-section {
    .recent-request-table {
      tr th:nth-child(3),
      tr th:nth-child(4),
      tr td:nth-child(3),
      tr td:nth-child(4) {
        display: none;
      }
      .request-icon {
        display: none;
      }
      .type-id-column {
        .request-details {
          margin-left: 0px;
        }
      }
    }
    .no-request-section {
      flex-direction: column;
      padding: 20px 0;
      .no-request-message {
        padding: 0;
      }
      .start-request-btn {
        margin-top: 12px;
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .recent-requests-section {
    .recent-request-table {
      padding: 15px;
      tr th:nth-child(5),
      tr th:nth-child(6),
      tr td:nth-child(3),
      tr td:nth-child(4),
      tr td:nth-child(5),
      tr td:nth-child(6) {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .recent-requests-section {
    margin: 0;
    .title-section {
      margin: 0;
      background: #fff;
      padding: 20px;
      justify-content: center;
      .recent-requests-logo {
        display: block;
      }
      .my-recent-title {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        margin-left: 13px;
      }
    }
    .view-all {
      display: none;
    }
    .recent-request-table {
      box-shadow: none;
      border-radius: 0;
      tr th,
      tr td:nth-child(2),
      tr td:nth-child(3),
      tr td:nth-child(4),
      tr td:nth-child(5),
      tr td:nth-child(6) {
        display: none;
      }
      .request-icon {
        display: block;
      }
      .type-id-column {
        .request-details {
          margin-left: 8px;
        }
      }
    }
    .no-request-section {
      border-radius: 0px;
    }
  }
}
