/* =============Footer CSS============== */
.leftNavigationFooterStyle {
    position: fixed;
    left: 1rem;
    bottom: 1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #9EA1B1;
  }
  