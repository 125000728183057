/* Kinds */
.Button-filled {
  background: var(--color-background-primary-bold, #3751ff);
  /* TODO: needs neutral-000 fix */
  color: #ffffff;
}
.Button-filled svg path {
  /* //TODO: Fix with neutral-000 later with correct token */
  fill: #ffffff;
}
.Button-outlined {
  /* TODO: needs neutral-000 fix */
  background: #ffffff;
  color: var(--color-foreground-primary-medium, #3751ff);
  border: 1px solid var(--color-border-primary-subtle, #6e7fff);
}
.Button-outlined svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}
.Button-subtle {
  background: var(--color-background-primary-subtle, #ebedff);
  color: var(--color-foreground-primary-medium, #3751ff);
}
.Button-subtle svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}
.Button-ghost {
  /* TODO: needs neutral-000 fix */
  background: transparent;
  color: var(--color-foreground-primary-medium, #3751ff);
}
.Button-ghost svg path {
  fill: var(--color-foreground-primary-medium, #3751ff);
}
.button-outlined--isenabled:hover .Button-overlay-outlined--isEnabled {
  background: red;
}
