.Extend-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.Extend-content {
  display: flex;
  width: 37.5rem;
  height: 31.25rem;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
}

.Extend-header {
  display: flex;
  padding: 0.75rem 1.5rem;
  align-items: flex-start;
align-self: stretch;
  border-bottom: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  background: var(--color-background-neutral-subtlest, #ffffff);
}

.Extend-header-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  color: var(--color-foreground-neutral-bold, #212328);
  }

.Extend-body {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
flex: 1 0 0;
  align-self: stretch;
  background: var(--color-page-subtle, #f2f4f8);
}

.Extend-timePicker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
align-self: stretch;
}

.Extend-startTime {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem, 0.25rem;
}

.Extend-startTime-label {
  display: flex;
  align-items: flex-start;
  gap: 0.125rem;
  color: var(--color-foreground-neutral-bold, #212328);
}

.Extend-startTime-value {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-foreground-secondary-bold, #00245b);
  text-overflow: ellipsis;
}

.Extend-endDateContainer {
  display: flex;
  flex-direction: column;
}
.Extend-endDate {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  max-width: fit-content;
}

.Extend-footer {
  display: flex;
  height: 4rem;
  padding: 0.75rem 1.5rem;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
align-self: stretch;

  border-top: 1px solid var(--color-border-neutral-decorative, #ebecf2);
  background: var(--color-background-neutral-subtlest, #ffffff);
}

.Extend-footer-buttons {
  display: flex;
  gap: 0.5rem;
}
