.Menu {
  position: relative;
}

.Menu-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: var(--typography-body-2);
}

/* Start: Made for pagination varient */
.Menu--simple .Menu-button {
  padding: 0;
  color: var(--color-foreground-neutral-medium, #4a4c59);
  width: min-content;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
}
.Menu-button .Icon--xxxSmall {
  height: unset;
}
.Menu--simple .Menu-button[tabindex]:focus-visible {
  outline: 2px solid var(--color-border-focus, #6e7fff);
  outline-offset: 0.125rem;
}
/* End: Added for pagination variant */

.Menu--default .Menu-button {
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-border-neutral-subtlest, #cecfd9);
  background: var(--color-background-neutral-subtlest, #cecfd9);
}
.Menu--default .Menu-button .Icon {
  background: var(
    --color-background-primary-on-subtle-selected,
    rgba(55, 81, 255, 0.1)
  );
}

.Menu--simple .Menu-button {
  background: transparent;
  border: 0;
  gap: 0.125rem;
}

.Menu-dropdown {
  position: absolute;
  background: var(--color-background-neutral-subtlest, #ffffff);
  z-index: var(--z-index-dropdown);
  border-radius: var(--border-radius-sm);
  padding: 0 0.25rem 0.25rem 0.25rem;

  /* TODO: use token when ready */
  box-shadow: var(--box-shadow-pretoken-overlay-interaction);

  opacity: 0;
  transform-origin: top;
  transform: scaleY(0);
  transition:
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;
}
.Menu-dropdown--isVisible {
  transform: scaleY(1);
  opacity: 1;
}

.Menu--default .Menu-dropdown {
  margin-top: 0;
}

.Menu-items {
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: min-content;
  white-space: nowrap;
  background: var(--color-background-neutral-subtlest, #ffffff);
  border-radius: var(--border-radius-sm, 4px);
}
.Menu-items:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
}

.Menu-buttonDefault .Icon {
  height: unset;
}

.Menu button label {
  display: initial; /* fixes text spacing issue in button Label */
}
