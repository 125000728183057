.MpaTimeAccessDuration {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Future: Start Date And Time */
.MpaTimeAccessDuration-dateInputs
  .DateAndTimeInputField-inputContainer
  .react-datepicker-popper {
  /* Needed to overwite react-datepicker postion when using an accordion */
  position: fixed !important;
}
.MpaTimeAccessDuration-dateInputs {
  display: flex;
  width: 23.75rem;
  gap: 0.75rem;
}

/* Chips */
.MpaTimeAccessDuration-durationChips-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.MpaTimeAccessDuration-durationLabel {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-heading-3);
}
.MpaTimeAccessDuration-durationChips {
  display: flex;
  gap: 0.5rem;
}
.MpaTimeAccessDuration-dividerLine {
  content: '';
  min-width: 0.0625rem;
  min-height: 100%;
  background: var(--color-border-neutral-subtlest, #cecfd9);
}

/* Now Custom Duration InputFields */
.MpaTimeAccessDuration-inputFields {
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
}

.MpaTimeAccessDuration-enterNumberInput {
  width: 7.5rem;
}

.MpaTimeAccessDuration-inputFields .InputField .Label {
  white-space: nowrap;
}

.MpaTimeAccessDuration-inputFields .InputField-closeButtonContainer {
  display: none;
}

.MpaTimeAccessDuration-inputFields .InputField--large {
  padding: 0.875rem 0.5rem;
}

.MpaTimeAccessDuration-inputFields .InputField-input--isReadOnly {
  background: var(--color-background-secondary-subtlest, #f7f9fc);
}

.MpaTimeAccessDuration-customDuration {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.MpaTimeAccessDuration-inlineMessageContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.MpaTimeAccessDuration-inlineMessageContainer .InlineMessage {
  max-width: 14.1875rem;
  margin-left: auto;
}

.MpaTimeAccessDuration-continueButton,
.MpaTimeAccessDuration-continueButtonGroup {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0;
  transition: all 200ms ease-in-out;
}
.MpaTimeAccessDuration-continueButtonGroup {
  gap: 0.5rem;
  padding: 0.5rem 0 0;
}

/* Warning message */
.MpaTimeAccessDuration .InlineMessage--warning {
  position: relative;
  top: -8px;
}

/* Custom NOW Inline Crtitical message */
.MpaTimeAccessDuration-enterNumberInput {
  white-space: nowrap;
}
