.tabsPanel {
  .menuContent {
    .brandLogoFrame,
    .faviconFrame {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 0px;
      align-self: stretch;
      border-bottom: 1px solid var(--light-border-neutral-light, #e4e5eb);
      padding-top: 48px;
      .textWithSeeExamplesLink {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 0.5rem;
      }
      .frameHeaderText {
        font-size: 16px;
        line-height: 24px;
      }
      .frameDescriptionText {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .brandLogoFrame {
      padding-bottom: 24px;
    }
    .faviconFrame {
      padding-top: 24px;
      border-bottom: 0px;
      .favIconUploadFrame {
        .favIconContainer {
          display: flex;
          align-items: center;
          gap: 1rem;
          border-radius: 3px;
          padding: 0.5rem;
          border: 1px solid var(--Light-Border-Neutral-Light, #e4e5eb);
          background: var(--color-neutral-000, #fff);
          .favIconImage {
            display: flex;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 3px;
            border: 1px solid var(--Light-Border-Neutral-Light, #e4e5eb);
          }
          .favIconName {
            color: var(--color-neutral-600, #717385);
            font-feature-settings:
              'clig' off,
              'liga' off;
            font-family: Rubik;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
        .favIconDelete {
          background: url(/ECMv6/assets/images/edit-pencil-icon.svg) no-repeat center center;
          width: 30px;
          height: 30px;
          border-radius: 48px;
          border: 1px solid var(--Light-Border-Brand-Medium, rgba(55, 81, 255, 0.5));
          cursor: pointer;
        }
        div[class^='FileUpload_uploadIcon'] {
          background: url(/ECMv6/assets/images/image_filled.svg) no-repeat center center;
        }
        span[class^='FileUpload_fileDownload'] {
          img {
            display: none;
          }
          background: url(/ECMv6/assets/images/delete.svg) no-repeat center center;
          border-radius: 48px;
          // border: 1px solid var(--light-border-brand-medium, rgba(55, 81, 255, 0.5));
          width: 20px;
          height: 20px;
        }
        div[class^='FileUpload_downloadFile'] {
          display: flex;
          align-items: center;
          gap: 24px;
          margin: 0px;
          height: 40px;
          padding: 8px 16px;
        }
        section[class^='FileUpload_dorpZoneArea'] {
          display: flex;
          padding: 12px 16px;
          width: 180px;
          height: 180px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 3px;
          border: 1px dashed var(--light-border-brand-medium, rgba(55, 81, 255, 0.5));
          background: var(--color-neutral-000, #fff);
          .btn {
            visibility: hidden;
          }
        }
      }
    }
    .selectLogoBtn {
      display: flex;
      height: 32px;
      margin-bottom: 24px;
      align-items: center;
      border-radius: var(--border-radius-sm, 4px);
      background: var(--color-background-primary-bold, #3751ff);
      .selectLogoBtnText {
        color: var(--color-foreground-neutral-inverse, #fff);
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
      }
    }
    .loginLogoutUploadFrame {
      .loginLogoutUploadFrameContainer {
        display: flex;
        align-items: center;
        gap: 1rem;
        border-radius: 3px;
        padding: 0.5rem;
        border: 1px solid var(--Light-Border-Neutral-Light, #e4e5eb);
        // background: var(--color-neutral-000, #fff);
        .favIconImage {
          display: flex;
          align-items: center;
          width: 32px;
          height: 32px;
          border-radius: 3px;
          border: 1px solid var(--Light-Border-Neutral-Light, #e4e5eb);
        }
        .loginLogoutLogoName {
          color: var(--color-neutral-600, #717385);
          font-feature-settings:
            'clig' off,
            'liga' off;
          font-family: Rubik;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .favIconDelete {
        background: url(/ECMv6/assets/images/edit-pencil-icon.svg) no-repeat center center;
        width: 30px;
        height: 30px;
        border-radius: 48px;
        border: 1px solid var(--Light-Border-Brand-Medium, rgba(55, 81, 255, 0.5));
        cursor: pointer;
      }
      div[class^='FileUpload_uploadIcon'] {
        background: url(/ECMv6/assets/images/image_filled.svg) no-repeat center center;
      }
      span[class^='FileUpload_fileDownload'] {
        img {
          display: none;
        }
        background: url(/ECMv6/assets/images/delete.svg) no-repeat center center;
        border-radius: 48px;
        width: 20px;
        height: 20px;
      }
      div[class^='FileUpload_downloadFile'] {
        display: flex;
        align-items: center;
        gap: 24px;
        margin: 0px;
        height: 40px;
        padding: 8px 16px;
      }
      section[class^='FileUpload_dorpZoneArea'] {
        display: flex;
        padding: 12px 16px;
        width: 180px;
        height: 180px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 3px;
        border: 1px dashed var(--light-border-brand-medium, rgba(55, 81, 255, 0.5));
        background: var(--color-neutral-000, #fff);
        .btn {
          visibility: hidden;
        }
      }
    }
  }
}
.libraryTab,
.uploadLogoTab {
  display: flex;
  width: 640px;
  height: 310px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  div[class^='BrandingConfig_favIconUploadFrame'] {
    padding: 24px;
  }
  .libraryFullContainer {
    display: flex;
    width: 344px;
    height: 265px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 24px;
    div.libraryFullWarning {
      width: 120px;
      height: 120px;
      background: url(/ECMv6/assets/images/libraryFull.svg) no-repeat center center;
    }
    .libraryTextFull {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .warningDescription {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  div[class^='FileUpload_downloadFile'],
  hr {
    display: none;
  }
  div[class^='FileUpload_uploadIcon'] {
    background: url(/ECMv6/assets/images/image_filled.svg) no-repeat center center;
  }
}

.libraryTab {
  align-items: flex-start;
  .displayedLogos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: scroll;
    gap: 24px;
    align-items: flex-start;
    align-content: flex-start;
    margin: 24px 0 0 0;
    padding: 2px;
    .emptyLogoElementContainer {
      position: absolute;
      left: 40%;
      top: 40%;
    }
    .noLogoUploadedText {
      color: var(--color-neutral-1000, #212328);
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-family: Rubik;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    div.emptyLogoContainer {
      width: 113px;
      height: 112px;
      background: url(/ECMv6/assets/images/emptyLogo.svg) no-repeat center center;
    }
    .logoCardWithName {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      width: 128px;
      height: 106px;
      position: relative;
      .logoSelectName {
        font-size: 12px;
        line-height: 16px;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        direction: ltr;
        text-align: center;
      }
      .logoCard {
        display: flex;
        width: 128px;
        height: 86px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        &.selected {
          border: 1px solid var(--color-indigo-700, #3751ff);
          outline: 1px solid var(--color-indigo-700, #3751ff);
        }
        .logoSelectName {
          width: 120px;
          height: 16px;
          flex-shrink: 0;
          color: #212328;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
        div.logoDelete {
          display: none;
          background: url(/ECMv6/assets/images/delete.svg) no-repeat center center;
          border-radius: var(--border-radius-circle, 1584px);
          border: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
          position: absolute;
          right: 3px;
          top: 3px;
        }
      }
      div.logoCard {
        border: 1px solid var(--color-gray-200, #d9d9d9);
      }
      div.logoCard:hover {
        cursor: pointer;
      }
      div.logoCard:focus {
        border-top: 2px solid var(--color-indigo-700, #3751ff);
        border-right: 2px solid var(--color-indigo-700, #3751ff);
        border-bottom: 2px solid var(--color-indigo-700, #3751ff);
        border-left: 2px solid var(--color-indigo-700, #3751ff);
        cursor: pointer;
      }
      div.logoCard:hover div.logoDelete {
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }
}

:global(.ModalIsland-background) {
  z-index: 10000 !important;
}

:global(#ModalIsland:not(.showSelectLogoModal)) {
  max-width: 800px;
  :global(.ModalIsland-body) {
    background: white;
  }
}

// utils classes
.dFlex {
  display: flex;
}

.dFlexSpaceBetween {
  justify-content: space-between;
}

.dFlexAlignCenter {
  align-items: center;
}

.fGrow {
  flex-grow: 1;
}

.dFlexRow {
  flex-direction: column;
}

.darkText {
  color: var(--black, #000);
}

.noWrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fullWidth {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.typography_body_1 {
  padding: 8px 12px;
  color: var(--color-neutral-1000, #212328);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.text_center {
  text-align: center;
}

/* Hide scrollbar */
.hidderScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidderScroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.linkButton {
  color: var(--color-indigo-700, #3751ff);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.dNone {
  display: none;
}

.m_0 {
  margin: 0;
}
.mb_4 {
  margin-bottom: 4px;
}

.mb_8 {
  margin-bottom: 8px;
}

.mt_8 {
  margin-top: 8px;
}

.mr_8 {
  margin-right: 8px;
}

.mb_24 {
  margin-bottom: 24px;
}

.subSectionHeaderText {
  color: var(--color-neutral-1000, #212328);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  &.darkText {
    color: var(--black, #000);
  }
}

.description {
  color: var(--color-neutral-600, #717385);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.themeName input {
  color: var(--color-foreground-neutral-bold, #212328);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.errorMessage {
  position: absolute;
  color: var(--color-foreground-critical-medium, #cc1c10);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  :global(.Icon) {
    margin-right: 4px;
  }
}

// Branding Config
:global(.sideBarSpacing.Gen2NavMenuOpen) {
  .container {
    font-style: normal;
    .brandingContainer {
      .sectionPanel {
        width: 100%;
        &.sidePanelOpen {
          width: calc(100vw - 598px);
        }
      }
    }
  }
}

.container {
  font-style: normal;
  .brandingContainer {
    .sectionPanel {
      width: 100%;
      background-color: var(--page-bg);
      transition: width 230ms;
      &.sidePanelOpen {
        width: calc(100vw - 423px);
      }
    }
    .pageHeader {
      padding: 41px 48px 24px 48px;
      // position: sticky;
      // top: 62px;
      position: relative;
      background: white;
      background-color: var(--page-bg);
      z-index: 2;
      width: calc(100% - 180px);
      // margin-bottom: 24px;
    }
    .header {
      color: var(--color-neutral-1000, #212328);
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }
    .contentSection {
      padding: 24px 48px;
      max-height: calc(100vh - 234px);
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .subSectionHeader {
      padding: 8px 0;
    }
  }

  // theme cards
  .themeGroup {
    margin-bottom: 32px;
  }
  .themeGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-top: 8px;
  }
  .themeCard {
    min-width: 143px;
    max-width: 143px;
    min-height: 140px;
    border-radius: 6px;
    border: 1px solid var(--light-border-neutral-medium, #cdced9);
    position: relative;
    transition: 230ms ease-in;
    transition-property: border, background-color;

    &:hover {
      border: 1px solid var(--color-neutral-600, #717385);
      background-color: white;
    }

    &.selected,
    &.selected:focus,
    &.selected:active {
      outline: 1px solid var(--color-indigo-700, #3751ff) !important;
      border: 1px solid var(--color-indigo-700, #3751ff) !important;
      outline-offset: -2px;
      .typography_body_1 {
        font-weight: 500;
      }
    }

    .themeColourWrapper {
      padding: 12px;
    }

    .themeColour {
      min-height: 76px;
      border-radius: 6px;
    }
    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 32px;
      transform: translate(13px, -12px);
      z-index: 1;
    }
    .themeNameWrap {
      justify-content: center;
      display: flex;
      :global(.TooltipComponent-container) {
        width: 100%;
      }
    }
    .starIcon {
      width: 32px;
      height: 32px;
      display: block;
    }
  }
  .createThemeButton {
    height: 32px;
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    // margin-right: 16px;
  }
}

// side panel footer
.FooterActionBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > div {
    display: flex;
  }

  button:not(:last-child) {
    margin-right: 8px;
  }
  .activeThemeFooterText {
    color: var(--color-neutral-600, #717385);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-bottom: 0;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    [type='icon'] {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}

.FooterActionBarIsScrolled {
  border-top: 1px solid transparent;
}

// side panel content
.contentLayout {
  display: flex;
  align-self: stretch;
  flex-direction: column;

  .sidePanelSection {
    margin-bottom: 36px;
    position: relative;
  }

  .OOTBVariant {
    margin-right: 24px;
    padding: 8px;
    border-radius: 6px 6px 0px 0px;
    border: 1px solid var(--light-border-neutral-medium, #cdced9);
    cursor: pointer;
    &.selected,
    &.selected:focus,
    &.selected:active {
      outline: 2px solid var(--color-indigo-700, #3751ff) !important;
      .typography_body_1 {
        font-weight: 500;
      }
    }
  }

  .colourSwatchGroup {
    .colourPickerWrap {
      display: flex;
      width: 378px;
      height: 64px;
      padding: 16px;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      background: var(--color-neutral-000, #fff);
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
      margin-bottom: 12px;
      transition: box-shadow 230ms;
      cursor: pointer;
      .bc_prefixIcon {
        transition: opacity 230ms;
        opacity: 0;
        display: flex;
      }
      &:not(:last-child) {
        margin-right: 8px;
      }
      &:hover {
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
        .bc_prefixIcon {
          opacity: 1;
        }
      }
      &.selected {
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
        outline: 2px solid var(--light-border-brand-hover-or-selected, #3751ff);
      }
    }
    .colourSwatch {
      width: 24px;
      height: 24px;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
    .colourSwatchStrip {
      width: 100%;
      .colourSwatchStripInner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .colourSwatchAndLabel {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.themeCreateAlert {
  width: 352px;
  color: var(--color-neutral-1000, #212328);
}

.alertFooter {
  button:last-child {
    margin-left: 8px;
  }
}

.tabsPanelWidthByDesign {
  width: calc(100% - 169px);
  margin-bottom: 24px;
}

.logoStrip {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 3px;
  border: 1px solid var(--light-border-neutral-light, #e4e5eb);
  background: var(--color-neutral-000, #fff);
  .logoStripContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    .imagePreview {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 3px;
      border: 1px solid var(--light-border-neutral-light, E4E5EB);
      img {
        height: 27.4px;
      }
    }
    .fileName {
      flex: 1 0 0;
      color: var(--color-neutral-600, #717385);
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .editIcon {
      display: flex;
      width: 32px;
      height: 32px;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      background: var(--light-background-neutral-lighter, #fff);
      cursor: pointer;
    }
  }
}

.bc_prefixIcon_static {
  width: 32px;
  height: 32px;
  display: inline-block;
  display: flex;
}
