.MenuItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-2);
  border-radius: var(--border-radius-sm, 4px);
  margin: 0.25rem 0.5rem;
}

.MenuItem:not(.MenuItem--isDisabled):hover {
  /* TODO: use css var when working */
  background: rgba(55, 81, 255, 0.08);
}

.MenuItem:not(.MenuItem--isDisabled):active {
  /* TODO: use css var when working */
  background: rgba(55, 81, 255, 0.1);
}

.MenuItem:focus-visible {
  outline: 3px solid var(--color-border-focus, #6e7fff);
  border-radius: 4px;
}

.MenuItem--isDisabled {
  color: var(--color-foreground-neutral-disabled, #9ea1b1);
}
.MenuItem--isDisabled:hover {
  cursor: not-allowed;
}

.MenuItem--isChecked {
  border-radius: 4px;
  background: var(
    --light-brand-primary-press-selected,
    rgba(55, 81, 255, 0.12)
  );
}
