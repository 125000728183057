.Checkbox:not(.Checkbox--base) {
  position: relative;
  box-sizing: border-box;
  background: var(--color-background-neutral-subtlest, #fff);
  border-radius: var(--border-radius-sm, 4px);
  border: 1px solid var(--color-border-secondary-decorative, #cfd9e9);
  transition: all 0.1s ease-in-out;
  caret-color: transparent;
  cursor: pointer;
  isolation: isolate;
}

.Checkbox:not(.Checkbox--base, .Checkbox--error).Checkbox--checked {
  border: 1px solid var(--color-border-secondary-medium, #254b86);
}

.Checkbox.Checkbox--withText.Checkbox--disabled {
  background: var(--color-background-neutral-disabled-subtle, #ebecf2);
}

.Checkbox--withText .Checkbox-iconTextWrapper {
  display: flex;
  align-items: center;
}

.Checkbox--withText .Checkbox-labelText--before {
  padding: 0.125rem 0.75rem 0.125rem 0;
}
.Checkbox--withText .Checkbox-labelText--after {
  padding: 0.125rem 0 0.125rem 0.75rem;
}
.Checkbox--withText .Checkbox-titleText--before {
  padding: 0.125rem 0.75rem 0.125rem 0;
}
.Checkbox--withText .Checkbox-titleText--after {
  padding: 0.125rem 0 0.125rem 0.75rem;
}

.Checkbox-label {
  display: flex;
  align-items: center;
  word-break: break-word;
  cursor: pointer;
  padding: 0.75rem 1rem;
}

.Checkbox:not(.Checkbox--base, .Checkbox--disabled, .Checkbox--error):hover {
  color: var(--color-foreground-secondary-medium, #254b86);
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}
.Checkbox:not(.Checkbox--base, .Checkbox--disabled):active {
  border: 1px solid var(--color-border-secondary-subtle, #6888b5);
}
.Checkbox:not(.Checkbox--base, .Checkbox--disabled):hover {
  box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.16);
}

.Checkbox:not(
    .Checkbox--base,
    .Checkbox--disabled,
    .Checkbox--error
  ).Checkbox--checked:hover {
  color: var(--color-foreground-secondary-medium, #254b86);
  border: 1px solid var(--color-border-secondary-medium, #254b86);
}

/* hide default icon when either hover or active */
.Checkbox:hover .Checkbox-icon--default,
.Checkbox:active .Checkbox-icon--default {
  display: none;
}
/* show hover icon only when hovering and not active */
.Checkbox:hover:not(:active) .Checkbox-icon--hover {
  display: inline-block;
}
/* hide hover icon when active */
.Checkbox:active .Checkbox-icon--hover {
  display: none;
}
/* show active icon only when active */
.Checkbox:active .Checkbox-icon--active {
  display: inline-block;
}

.Checkbox-labelText {
  color: var(--color-foreground-secondary-bold, #00245b);
  font: var(--typography-body-2);
}

.Checkbox--withText .Checkbox-iconWrapper {
  transform: translate(0px, 3px);
}

.Checkbox-titleText {
  color: var(--color-foreground-secondary-bold, #00245b);
  font: var(--typography-heading-3);
}

.Checkbox.Checkbox--disabled .Checkbox-labelText,
.Checkbox.Checkbox--disabled .Checkbox-titleText {
  color: var(--color-foreground-neutral-subtle, #717385);
}

.Checkbox.Checkbox--error {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}

.Checkbox.Checkbox--error:hover {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}

.Checkbox.Checkbox--error:active {
  border: 1px solid var(--color-border-critical-medium, #cc1c10);
}

.Checkbox-container {
  display: inline-flex;
  flex-direction: column;
  gap: 0.25rem;
}

.Checkbox-container .Checkbox-errorWrapper {
  display: flex;
  gap: 0.25rem;
  color: var(--color-foreground-critical-medium, #cc1c10);
  font: var(--typography-body-3);
}

.Checkbox-container .Checkbox-errorWrapper .Icon {
  align-self: center;
}

/* Overlay */
.Checkbox-overlay {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  border-radius: var(--border-radius-sm, 4px);
  background: transparent;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}
.Checkbox-overlay:active,
.Checkbox:active .Checkbox-overlay {
  opacity: 1;
  background: rgba(37, 75, 134, 0.12);
}

.Checkbox--disabled.Checkbox:active .Checkbox-overlay,
.Checkbox--disabled .Checkbox-overlay:active {
  opacity: 0;
}
