.ModalPageListIsland .ModalIsland-header {
  align-items: center;
  height: 4.5rem;
}
.ModalPageListIsland .ModalIsland-titleContainer {
  justify-content: center;
}

#ModalIsland.ModalIsland.ModalPageListIsland
  .ModalIsland-section
  .ModalIsland-body {
  max-width: 75rem;
  height: 35rem;
  max-height: calc(100vh - 10.5rem);
}
.ModalPageListIsland .ModalIsland-section {
  width: 100%;
  max-width: calc(100vw - 3rem);
}
.ModalPageListIsland .ModalIsland-body {
  padding: 1rem 3rem 1.5rem 3rem;
}

.ModalPageListIsland-footerActionBar {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  width: 100%;
}

.ModalPageListIsland-modalIslandContent-search {
  max-width: 33rem;
  width: 100%;
}

.ModalPageListIsland-noMatchingSearchRecords,
.ModalPageListIsland-radioButtons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}
.ModalPageListIsland-noMatchingSearchRecords {
  flex-direction: row;
}

.InlineMessage .ModalPageListIsland-noMatchingSearchRecords {
  padding: 1.5rem;
  align-items: flex-start;
  gap: 0.25rem;
  width: 100%;
  border-radius: var(--border-radius-sm, 0.25rem);
  background: var(--color-background-neutral-subtlest, #fff);
  box-shadow: var(--box-shadow-pretoken-container-default);
}
.ModalPageListIsland-noMatchingSearchRecords-title {
  color: var(--color-foreground-neutral-bold, #212328);
}
