.ListRowSession-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.125rem;
  min-width: 0;
  width: 100%;
  padding: 0.5rem 0.75rem;
}

.ListRowSession-data-title {
  color: var(--color-foreground-neutral-bold, #212328);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Rubik;
  font: var(--typography-heading-3);
}

.ListRowSession-data-description {
  overflow: hidden;
  color: var(--color-foreground-neutral-subtle, #717385);
  font-feature-settings:
    'clig' off,
    'liga' off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: var(--typography-body-2);
  width: 100%;
}

.ListRowSession-labelValue,
.ListRowSession-title,
.ListRowSession-title-content {
  display: flex;
}

.ListRowSession-labelValue-label {
  color: var(--color-foreground-neutral-subtle, #717385);
  font: var(--typography-body-3);
  line-height: 1.5rem;
}

.ListRowSession-dot {
  height: 0.25rem;
  width: 0.25rem;
  background-color: var(--color-foreground-neutral-bold, #212328);
  border-radius: 0.25rem;
  display: inline-block;
  position: relative;
  top: 0.5625rem;
  margin: 0 0.5rem;
}

.ListRowSession-labelValue-text {
  color: var(--color-foreground-neutral-bold, #212328);
  font: var(--typography-body-3);
  line-height: 1.5rem;
}
